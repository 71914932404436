import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataSavedEvent, OrganizationData } from '../../organization.model';
import { NotificationService } from '../../../shared/service/notification.service';
import { OrganizationService } from '../../organization.service';
import { finalize } from 'rxjs/operators';
import { FormHelper } from '../../../shared/mixin/form-helper';

@Component({
  selector: 'app-organization-sales-conditions',
  templateUrl: './organization-sales-conditions.component.html',
  styleUrls: ['./organization-sales-conditions.component.scss']
})
export class OrganizationSalesConditionsComponent extends FormHelper() implements OnInit {
  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public showElubLogin: boolean = false;

  @Input() organizationData!: OrganizationData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private organizationService: OrganizationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.createForm();

    if (this.organizationData.activateElub) {
      this.showElubLogin = true;
    }

    this.form.valueChanges.subscribe((value) => {
      this.showElubLogin = value.activateElub;
    });
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({
      useDefaultSendConditions: this.fb.control(this.organizationData.useDefaultSendConditions),
      sapSalesCond010: this.fb.control(this.organizationData.sapSalesCond010, Validators.required),
      sapSalesCond011: this.fb.control(this.organizationData.sapSalesCond011, Validators.required),
      sapSalesCond012: this.fb.control(this.organizationData.sapSalesCond012, Validators.required),
      sapSalesCond013: this.fb.control(this.organizationData.sapSalesCond013),
      sapSalesCond014: this.fb.control(this.organizationData.sapSalesCond014)
    });
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;

    this.organizationService
      .updateSalesCondition(this.organizationData.id, this.form.value)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('organization.settings.updated');
          this.dataSaved.emit({ organizationId: this.organizationData.id });
        }
      });
  }
}
