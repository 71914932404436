import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../shared/base/api-service';
import { OrderCollectionResponse, OrderData } from './order-history.model';

@Injectable({
  providedIn: 'root'
})

export class OrderHistoryService extends ApiService {

  private restApiUrl = this.apiUrl('admin/order-history');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public find(id: string): Observable<OrderData> {
    return this.httpClient.get<OrderData>(this.restApiUrl + '/' + id)
  }

  public findPaginated(
    page: number = 1,
    pageSize: number = 25,
    sortBy: string = 'name',
    sortDirection: string = 'desc',
    filter: string = '',
    filterType: string = 'number'
  ): Observable<OrderCollectionResponse> {
    const queryParams = new HttpParams()
      .append('page', page.toString())
      .append('page_size', pageSize.toString())
      .append('sort_by', sortBy)
      .append('sort_direction', sortDirection)
      .append('filter', filter.trim())
      .append('filter_type', filterType);

    return this.httpClient
      .get<OrderCollectionResponse>(this.restApiUrl, {params: queryParams})
  }
}
