import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MagazineData } from '../magazine.model';
import { MagazineService } from '../magazine.service';

@Component({
  selector: 'app-magazine-details',
  templateUrl: './magazine-details.component.html',
  styleUrls: ['./magazine-details.component.scss']
})
export class MagazineDetailsComponent implements OnInit {

  public magazineId!: string;
  public magazineData: Observable<MagazineData>;
  public isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    public magazineService: MagazineService,
  ) {
    this.magazineData = new Observable<MagazineData>();
  }

  ngOnInit(): void {
    this.magazineId = this.route.snapshot.paramMap.get('id')!;
    this.magazineData = this.magazineService
      .find(this.magazineId)
      .pipe(
        finalize( () => this.isLoading = false),
      );
  }
}
