export class TinyMceUtil {
  public static getEditorSettings(
    placeholder: string = '',
  ): object {
    return {
      placeholder,
      base_url: '/assets/tinymce',
      height: 300,
      menubar: false,
      plugins: [
        "advlist", "anchor", "autolink", "charmap", "code", "fullscreen",
        "help", "image", "insertdatetime", "link", "lists", "media",
        "preview", "searchreplace", "table", "visualblocks", 'wordcount'
      ],
      toolbar: "undo redo | styles | bold italic underline strikethrough | bullist numlist outdent indent | link ",
      // Define your own styles
      style_formats: [
        {title: 'Heading 2', format: 'h2'},
        {title: 'Heading 3', format: 'h3'},
        {title: 'Heading 4', format: 'h4'},
        {title: 'Heading 5', format: 'h5'},
        {title: 'Heading 6', format: 'h6'},
        {title: 'Normal', block: 'div'}
      ],
      formats: {
        // Override TinyMCE formatting of bold text to <strong> tags instead of <b> tags
        bold: { inline: 'b' },
        italic: { inline: 'i' },
        underline: { inline: 'u' },
      },
    };
  }
}
