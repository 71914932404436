import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataSavedEvent, OrganizationAddress, OrganizationData } from '../../organization.model';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-organization-address',
  templateUrl: './organization-address.component.html',
  styleUrls: ['./organization-address.component.scss']
})
export class OrganizationAddressComponent implements OnInit, OnChanges {
  public addressSelected: string;
  public addressTypeMap!: Record<string, OrganizationAddress|undefined>;
  public addressData?: OrganizationAddress;
  public showForm: boolean;

  @Input() organizationData!: OrganizationData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  constructor() {
    this.addressSelected = 'business';
    this.showForm = false;
  }

  ngOnInit(): void {
    this.addressTypeMap = this.createAddressTypeMap();
    this.addressData = this.addressTypeMap[this.addressSelected];
  }

  ngOnChanges(changes: SimpleChanges) {
    // Because we emit an event to the parent, which reloads organization
    // data (including addresses), we must re-create the address-type map
    // and set the address data. It seems that pop references get lost when
    // assigning them to another object (the address map for the toggle button).
    this.addressTypeMap = this.createAddressTypeMap();
    this.addressData = this.addressTypeMap[this.addressSelected];
  }

  public onAddressChange(event: MatButtonToggleChange) {
    this.showForm = false;
    this.addressData = this.addressTypeMap[this.addressSelected];
  }

  public onAddressSaved(): void {
    this.dataSaved.emit({ organizationId: this.organizationData.id })
    this.showForm = false;
  }

  public onAddressUpdateCancelled(): void {
    this.showForm = false;
  }

  public onEditClick() {
    this.showForm = true;
  }

  public onAddClick() {
    this.showForm = true;
  }

  private createAddressTypeMap(): Record<string, any> {
    return {
      'business': this.organizationData._embedded?.businessAddress,
      'invoicing': this.organizationData._embedded?.invoicingAddress,
      'delivery': this.organizationData._embedded?.deliveryAddress
    };
  }
}
