import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountData, DataSavedEvent } from '../../account.model';
import { AccountService } from '../../account.service';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../../shared/service/notification.service';

@Component({
  selector: 'app-extra-options',
  templateUrl: './extra-options.component.html',
  styleUrls: ['./extra-options.component.scss']
})
export class ExtraOptionsComponent implements OnInit {
  public accountId!: string;
  public isSaving: boolean = false;

  @Input() accountData!: AccountData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.paramMap.get('id')!;
  }

  get canTriggerAccountReadyNotification() {
    const relatedOrganizations = this.accountData.additional['hasRelatedOrganizations'] || false;

    return !this.accountData.roles.includes('shop_admin') && relatedOrganizations;
  }

  public sendAccountReadyNotification() {
    this.isSaving = true;

    this.accountService
      .triggerAccountReadyNotification(this.accountData.id)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('account.ready_notification.triggered');
          this.dataSaved.emit({ id: this.accountData.id });
        },
      });
  }
}
