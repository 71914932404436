import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NewsRoutingModule } from './news-routing.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { ItemsOverviewComponent } from './items-overview/items-overview.component';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SharedModule } from '../shared/shared.module';
import { RemoveItemDialogComponent } from './remove-item-dialog/remove-item-dialog.component';
import { CreateItemFormComponent } from './create-item-form/create-item-form.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ManageItemComponent } from './manage-item/manage-item.component';
import { ItemDetailsComponent } from './manage-item/item-details/item-details.component';
import { UpdateItemFormComponent } from './manage-item/update-item-form/update-item-form.component';
import { ItemImagesComponent } from './manage-item/item-images/item-images.component';
import { MatTabsModule } from '@angular/material/tabs';
import { EditorComponent } from '@tinymce/tinymce-angular';

@NgModule({
  imports: [
    NewsRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatListModule,
    MatDialogModule,
    MatDividerModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatTabsModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    CommonModule,
    EditorComponent,
  ],
    declarations: [
        ItemsOverviewComponent,
        RemoveItemDialogComponent,
        CreateItemFormComponent,
        ManageItemComponent,
        ItemDetailsComponent,
        UpdateItemFormComponent,
        ItemImagesComponent,
    ],
    exports: [
        UpdateItemFormComponent
    ]
})
export class NewsModule { }
