import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, merge, Subject } from 'rxjs';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../shared/service/notification.service';
import { ArticleService } from '../article.services';
import { ArticleDataSource } from '../article.data-source';
import { RemoveArticleDialogComponent } from '../remove-article-dialog/remove-article-dialog.component';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-article-overview',
  templateUrl: './article-overview.component.html',
  styleUrls: ['./article-overview.component.scss']
})
export class ArticleOverviewComponent implements AfterViewInit, OnDestroy, OnInit {

  private searchInputSubject = new Subject<string>();
  public searchInput = this.searchInputSubject.asObservable();
  public searchText = '';
  private searchEmpty = true;
  public searchField: string = 'title';
  public tableColumns: string[] = [
    'title',
    'sapCode',
    'productCode',
    'packaging',
    'quantity',
    'content',
    'updatedAt',
    'actions'
  ];

  public sortActiveDefault = 'title';
  public sortDirectionDefault: SortDirection = 'asc';
  public pageSizeOptions: number[] = [15, 30, 50];
  public totalItems = 0;
  public dataSource: ArticleDataSource;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private articleService: ArticleService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
    this.dataSource = new ArticleDataSource(this.articleService);

    this.dataSource.dataLength.subscribe((total) => {
      this.totalItems = total
    });
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    // Reset pagination after sort change.
    this.sort.sortChange
      .subscribe(() => this.paginator.pageIndex = 0);

    // Load data when a sort or paginator event is emitted.
    merge(this.sort.sortChange, this.paginator.page)
      .subscribe(() => this.loadData());

    // Load data when search input value changes.
    this.searchInput
      .pipe(
        debounceTime(150)
      )
      .subscribe((value) => {
        if (!this.searchEmpty || value.length) {
          this.paginator.pageIndex = 0;
          this.loadData()
        }

        this.searchEmpty = value.length === 0;
      });
  }

  ngOnDestroy() {
    this.searchInputSubject.unsubscribe();
  }

  public onSearch(): void {
    this.searchInputSubject.next(this.searchText);
  }

  public onSearchClear(): void {
    this.searchText = '';
    this.onSearch();
  }

  public onChangeFilterType(event: MatButtonToggleChange): void {
    this.onSearchClear();
  }

  public onRemoveAction(id: string, title: string, code: string): void {
    if (this.dialog.openDialogs.length) {
      return;
    }

    this.dialog
      .open(RemoveArticleDialogComponent, {
        data: {
          id: id,
          title: title,
          code: code
        },
      })
      .afterClosed()
      .subscribe(
        data => {
          if (data?.id) {
            this.deleteArticle(data.id);
          }
        }
      );
  }

  private deleteArticle(articleId: string): void {
    this.articleService
      .delete(articleId)
      .subscribe({
        next: () => {
          this.notificationService.success_ts('article.deleted');
          this.loadData();
        }
      })
  }

  private loadData() {
    this.dataSource.loadArticles(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || this.pageSizeOptions[0],
      this.sort?.active || this.sortActiveDefault,
      this.sort?.direction || this.sortDirectionDefault,
      this.searchText,
      this.searchField
    );
  }
}
