import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from '../../../shared/service/notification.service';
import { CarouselData, DataSavedEvent } from '../../carousel.model';
import { CarouselService } from '../../carousel.service';

@Component({
  selector: 'app-item-image',
  templateUrl: './item-image.component.html',
  styleUrls: ['./item-image.component.scss']
})
export class ItemImageComponent {

  public fileAttr = 'Select file';
  public showDeleteIconImage = false;

  @Input() itemData!: CarouselData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  constructor(
    private carouselService: CarouselService,
    private notificationService: NotificationService
  ) {
  }

  get image() {
    return this.itemData._links?.image;
  }

  onSelect(event: Event): void {
    const files = (event.target as HTMLInputElement).files;

    if (!files) {
      return;
    }

    this.carouselService
      .uploadImage(this.itemData.id, files[0])
      .subscribe((next) => {
        this.dataSaved.emit({ itemId: this.itemData.id });
        this.notificationService.success_ts('news_item.image.uploaded');
      })
  }

  onRemove(): void {
    this.carouselService
      .uploadImage(this.itemData.id, null)
      .subscribe((next) => {
        this.dataSaved.emit({ itemId: this.itemData.id });
        this.notificationService.success_ts('news_item.image.removed');
      })
  }
}
