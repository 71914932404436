import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateOrganizationFormComponent } from './create-organization-form/create-organization-form.component';
import { ManageOrganizationComponent } from './manage-organization/manage-organization.component';
import { OrganizationOverviewComponent } from './organization-overview/organization-overview.component';

const routes: Routes = [
  {
    path: 'organizations',
    component: OrganizationOverviewComponent
  },
  {
    path: 'organizations/create',
    component: CreateOrganizationFormComponent
  },
  {
    path: 'organizations/:id',
    component: ManageOrganizationComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganizationRoutingModule { }
