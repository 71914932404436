<div class="row">
  <div class="col-md-6 form-label">Address</div>
  <div class="col-md-6 form-text">
    <span *ngIf="addressData?.addressLine1">
        {{ addressData?.addressLine1 }}
      </span>
    <span *ngIf="addressData?.addressLine2">
        <br/>
      {{ addressData?.addressLine2 }}
      </span>
    <span *ngIf="addressData?.addressLine3">
        <br/>
      {{ addressData?.addressLine3 }}
      </span>
    <span *ngIf="!addressData">
        -
      </span>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Zip</div>
    <div class="col-md-6 form-text">{{ addressData?.postalCode || '-' }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">City</div>
    <div class="col-md-6 form-text">{{ addressData?.city || '-' }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Country</div>
    <div class="col-md-6 form-text">{{ addressData?.country || '-' }}</div>
  </div>
</div>


