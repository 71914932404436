import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, merge, Subject } from 'rxjs';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SapCommunicationDataSource } from '../sap-communication.data-source';
import { SapCommunicationLogService } from '../sap-communication-log.service';

@Component({
  selector: 'app-sap-communication-overview',
  templateUrl: './sap-communication-overview.component.html',
  styleUrls: ['./sap-communication-overview.component.scss']
})
export class SapCommunicationOverviewComponent implements AfterViewInit, OnDestroy, OnInit {

  private searchInputSubject = new Subject<string>();
  public searchInput = this.searchInputSubject.asObservable();
  public searchText = '';
  private searchEmpty = true;
  public tableColumns: string[] = [
    'createdAt',
    'createdBy',
    'orderNumber',
    'orderType',
    'sentToSap',
    'fullyAccepted'
  ];

  public sortActiveDefault = 'createdAt';
  public sortDirectionDefault: SortDirection = 'desc';
  public pageSizeOptions: number[] = [15, 30, 50];
  public totalItems = 0;
  public dataSource: SapCommunicationDataSource;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private sapCommunicationLogService: SapCommunicationLogService,
  ) {
    this.dataSource = new SapCommunicationDataSource(sapCommunicationLogService);

    this.dataSource.dataLength.subscribe((total) => {
      this.totalItems = total
    });
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    // Reset pagination after sort change.
    this.sort.sortChange
      .subscribe(() => this.paginator.pageIndex = 0);

    // Load data when a sort or paginator event is emitted.
    merge(this.sort.sortChange, this.paginator.page)
      .subscribe(() => this.loadData());

    // Load data when search input value changes.
    this.searchInput
      .pipe(
        debounceTime(150)
      )
      .subscribe((value) => {
        if (!this.searchEmpty || value.length) {
          this.paginator.pageIndex = 0;
          this.loadData()
        }

        this.searchEmpty = value.length === 0;
      });
  }

  ngOnDestroy() {
    this.searchInputSubject.unsubscribe();
  }

  public statusColor(value: boolean): string {
    return value ? 'green' : 'red';
  }

  public onSearch(): void {
    this.searchInputSubject.next(this.searchText);
  }

  public onSearchClear(): void {
    this.searchText = '';
    this.onSearch();
  }

  private loadData() {
    this.dataSource.loadLog(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || this.pageSizeOptions[0],
      this.sort?.active || this.sortActiveDefault,
      this.sort?.direction || this.sortDirectionDefault,
      this.searchText.trim()
    );
  }

}
