import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VideoOverviewComponent } from './video-overview/video-overview.component';
import { CreateVideoFormComponent } from './create-video-form/create-video-form.component';
import { VideoDetailsComponent } from './video-details/video-details.component';
import { UpdateVideoFormComponent } from './update-video-form/update-video-form.component';

const routes: Routes = [
  { path: 'videos', component: VideoOverviewComponent },
  { path: 'videos/create', component: CreateVideoFormComponent },
  { path: 'videos/:id', component: VideoDetailsComponent },
  { path: 'videos/:id/edit', component: UpdateVideoFormComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VideoRoutingModule { }
