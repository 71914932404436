<a routerLink="/">Home</a> / Article Stock import
<br/><br/>
<div class="row page-header">
  <div class="col title">
    <h1>Article Stock Import</h1>
  </div>
</div>
<div class="row" >
  <div class="col card-wrapper">
    <mat-card>
      <mat-card-header>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="form-wrapper">
            <!-- Upload field must be outside of the form to work -->
            <mat-form-field class="w-100">
              <div>
                <mat-toolbar>
                  <!-- Display filename -->
                  <input matInput [(ngModel)]="fileAttr" readonly name="name" />
                  <!-- Browse Button -->
                  <button mat-flat-button color="primary">Browse File</button>
                </mat-toolbar>
                <input
                  #fileInput
                  type="file"
                  id="uploadFile"
                  (change)="onSelectFile($event)"
                  name="uploadFile"
                  multiple="multiple"
                  accept=".csv"
                />
              </div>
            </mat-form-field>
            <br/>
            <span class="file-type-info">Supported file types: xlsx, csv</span>
            <br/><br/>
            <div class="validation-error-box" *ngIf="validationErrors">
              <span class="title">A total of {{ validationErrors.numRowsRejected }} rows were rejected</span>
              <div class="error-list">
                <div class="rows" *ngFor="let rowErrors of validationErrors.errors">
                  Row <span class="row-nr">{{ rowErrors.rowNr }}</span>
                  <br/>
                  <div class="cols" *ngFor="let colError of rowErrors.errors">
                    <span class="col-error">Column <span class="col-name">{{ colError.col }}:</span> {{ colError.msg }}</span>
                  </div>
                </div>
              </div>
            </div>
            <br/><br/>
            Format: sapcode, stock status, gross price (optional)
            <br/><br/>
          </div>
        </div>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button
          mat-flat-button color="primary"
          class="button-save"
          (click)="onSubmit()"
        >
          <div class="d-flex">
            Upload
            <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
          </div>
        </button>
        <button
          mat-flat-button color="secondary"
          class="button-reset"
          (click)="onReset()"
        >
          Reset
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
