import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-account-manager-dialog',
  templateUrl: './remove-account-manager-dialog.component.html',
  styleUrls: ['./remove-account-manager-dialog.component.scss']
})
export class RemoveAccountManagerDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<RemoveAccountManagerDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: {
      id: string,
      name: string
    },
  ) {}

  onConfirm(id: string) {
    this.dialogRef.close({ id: id });
  }
}
