import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BrochureCollectionResponse, BrochureData, CreateBrochureData, UpdateBrochureData } from './brochure.model';
import { ApiService } from '../shared/base/api-service';
import EmptyResponse from '../shared/model/misc';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrochureService extends ApiService {

  private restApiUrl = this.apiUrl('admin/brochures');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public find(id: string): Observable<BrochureData> {
    return this.httpClient.get<BrochureData>(this.restApiUrl + '/' + id)
      .pipe(
        map((brochureData: BrochureData): BrochureData => {
          // TODO this should be done in the back-end
          if (brochureData?._links?.file?.href) {
            brochureData._links.file.href = brochureData._links.file.href.replace(
              environment.apiUrl,
              environment.apiPublicUrl,
            );
          }
          return brochureData;
        }),
      );
  }

  public findPaginated(
    page: number = 1,
    pageSize: number = 25,
    sortBy: string = 'name',
    sortDirection: string = 'desc',
    filter: string = '',
  ): Observable<BrochureCollectionResponse> {
    const queryParams = new HttpParams()
      .append('page', page.toString())
      .append('page_size', pageSize.toString())
      .append('sort_by', sortBy)
      .append('sort_direction', sortDirection)
      .append('filter', filter.trim());

    return this.httpClient
      .get<BrochureCollectionResponse>(this.restApiUrl, { params: queryParams })
  }

  public create(payload: CreateBrochureData): Observable<EmptyResponse> {
    return this.httpClient
      .post<EmptyResponse>(this.restApiUrl, this.prepareFormData(payload))
  }

  public update(brochureId: string, payload: UpdateBrochureData): Observable<EmptyResponse> {
    return this.httpClient
      .put<EmptyResponse>(this.restApiUrl + '/' + brochureId, this.prepareFormData(payload))
  }

  public delete(brochureId: string) {
    return this.httpClient.delete<EmptyResponse>(this.restApiUrl + '/' + brochureId)
  }

  private prepareFormData(payload: CreateBrochureData | UpdateBrochureData): FormData {
    const formData: FormData = new FormData();
    formData.append('title', payload.title);
    formData.append('shortDescription', payload.shortDescription);
    formData.append('description', payload.description);

    payload.segments.forEach((segment) => {
      formData.append('segments[]', segment);
    });

    formData.append('file', payload.file !== null ? payload.file : '');

    return formData;
  }
}
