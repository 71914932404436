<a routerLink="/">Home</a> / <a routerLink="/order-history">Order History</a> / Details
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/order-history"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>Order Details</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class=""></mat-progress-bar>
    <mat-card>
      <div *ngIf="orderData | async; let data">
        <mat-card-header>
          <mat-icon mat-card-avatar>list_alt</mat-icon>
          <mat-card-title>{{ data.orderNumber }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-md-6 form-label">Created</div>
            <div class="col-md-6 form-text">{{ data.createdAt | date : "dd MMM yyyy hh:mm" }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Created by</div>
            <div class="col-md-6 form-text">{{ data.createdBy }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Organization</div>
            <div class="col-md-6 form-text">{{ data.organization }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Invoicing address</div>
            <div class="col-md-6 form-text">
              <span>{{ data.details?.invoicingAddress?.addressLine1 }}</span>
              <span *ngIf="data.details?.invoicingAddress?.addressLine2">
                <br/>
                {{ data.details?.invoicingAddress?.addressLine2 }}
              </span>
              <span *ngIf="data.details?.invoicingAddress?.addressLine3">
                <br/>
                {{ data.details?.invoicingAddress?.addressLine3 }}
              </span>
              <br/>
              <span>{{ data.details?.invoicingAddress?.postalCode }} {{ data.details?.invoicingAddress?.city }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Delivery address</div>
            <div class="col-md-6 form-text">
              <span>{{ data.details?.deliveryAddress?.addressLine1 }}</span>
              <span *ngIf="data.details?.deliveryAddress?.addressLine2">
                <br/>
                {{ data.details?.deliveryAddress?.addressLine2 }}
              </span>
              <span *ngIf="data.details?.deliveryAddress?.addressLine3">
                <br/>
                {{ data.details?.deliveryAddress?.addressLine3 }}
              </span>
              <br/>
              <span>{{ data.details?.deliveryAddress?.postalCode }} {{ data.details?.deliveryAddress?.city }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Type</div>
            <div class="col-md-6 form-text">{{ data.type }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Purchase reference</div>
            <div class="col-md-6 form-text">{{ data.details?.purchaseReference ?? '-' }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Purchase comment</div>
            <div class="col-md-6 form-text">{{ data.details?.purchaseComment ?? '-'}}</div>
          </div>
          <mat-divider class="py-2"></mat-divider>
          <table>
            <tr>
              <th>Brand</th>
              <th>Product</th>
              <th>Article Code</th>
              <th>Packaging</th>
              <th>Qty</th>
              <th>Gross price</th>
              <th>Price per</th>
              <th>Total price</th>
            </tr>
            <tr *ngFor="let item of data.lines">
              <td>{{ item.brand }}</td>
              <td>{{ item.productName }}</td>
              <td>{{ item.articleCode }}</td>
              <td>{{ item.packageType }}</td>
              <td>{{ item.qty}}</td>
              <td>{{ item.grossPrice }}</td>
              <td>{{ item.pricePer }}</td>
              <td>{{ item.linePrice }}</td>
            </tr>
          </table>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>
