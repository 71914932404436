<a routerLink="/">Home</a> / Equivalents <br/><br/>
<div class="row page-header">
  <div class="col title">
    <h1>Equivalents</h1>
  </div>
</div>
<div class="grid-controls">
  <div class="search">
    <mat-form-field class="w-100 pr-5 no-subscript" appearance="outline">
      <mat-label>Search by article/product code</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="searchText"
        (keyup)="onSearch()"
      />
      <button
        *ngIf="searchText"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="onSearchClear()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="buttons">
    <button
      mat-raised-button
      color="primary"
      routerLink="/equivalents/create"
    >
      New equivalent
    </button>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="table-wrapper mat-elevation-z3">
      <div class="table-progress-overlay" *ngIf="dataSource.loading | async">
        <mat-progress-spinner mode="indeterminate" diameter="50">
        </mat-progress-spinner>
      </div>
      <table
        [dataSource]="dataSource"
        mat-table
        matSort
        [matSortActive]="sortActiveDefault"
        [matSortDirection]="sortDirectionDefault"
        matSortDisableClear
      >
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
          <td mat-cell *matCellDef="let element">{{ element.createdAt | date : 'd MMM yyyy, HH:mm' }}</td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let element">{{ element.type }}</td>
        </ng-container>
        <ng-container matColumnDef="oldCode">
          <th mat-header-cell *matHeaderCellDef>Old code</th>
          <td mat-cell *matCellDef="let element">{{ element.oldCode }}</td>
        </ng-container>
        <ng-container matColumnDef="newCode">
          <th mat-header-cell *matHeaderCellDef>New code</th>
          <td mat-cell *matCellDef="let element">{{ element.newCode }}</td>
        </ng-container>
        <ng-container matColumnDef="nameOld">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Old name</th>
          <td mat-cell *matCellDef="let element">{{ element.nameOld }}</td>
        </ng-container>
        <ng-container matColumnDef="nameNew">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>New name</th>
          <td mat-cell *matCellDef="let element">{{ element.nameNew }}</td>
        </ng-container>
        <ng-container matColumnDef="packagingOld">
          <th mat-header-cell *matHeaderCellDef>Old packaging</th>
          <td mat-cell *matCellDef="let element">
            {{ element.packagingOld || "-" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="packagingNew">
          <th mat-header-cell *matHeaderCellDef>New packaging</th>
          <td mat-cell *matCellDef="let element">
            {{ element.packagingNew || "-" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="showNotification">
          <th mat-header-cell *matHeaderCellDef>Notification</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [ngModel]="element.showNotification"
              (change)="onToggleOption(element.id, 'showNotification', $event.checked)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="replaceOrderWithEquivalent">
          <th mat-header-cell *matHeaderCellDef>Replace</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [ngModel]="element.replaceOrderWithEquivalent"
              (change)="onToggleOption(element.id, 'replaceWithEquivalent', $event.checked)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="removeFromSearchResults">
          <th mat-header-cell *matHeaderCellDef>Hidden</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [ngModel]="element.removeFromSearchResults"
              (change)="onToggleOption(element.id, 'removeFromSearchResults', $event.checked)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element" >
            <mat-icon (click)="onRemoveAction(
            element.id,
            element.nameOld + ' (' + element.oldCode + ')',
            element.nameNew + ' (' + element.newCode + ')')"
            >
              delete
            </mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-data" [attr.colspan]="tableColumns.length">
            No data
          </td>
        </tr>
      </table>
      <mat-paginator
        [length]="totalItems"
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>
