import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountOverviewComponent } from './account-overview/account-overview.component';
import { AccountRoutingModule } from './account-routing.module';
import { AccountDetailsComponent } from './manage-account/account-details/account-details.component';
import { UpdateAccountFormComponent } from './manage-account/update-account-form/update-account-form.component';
import { RemoveAccountDialogComponent } from './remove-account-dialog/remove-account-dialog.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ManageAccountComponent } from './manage-account/manage-account.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ExtraOptionsComponent } from './manage-account/extra-options/extra-options.component';

@NgModule({
  imports: [
    AccountRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatListModule,
    MatDialogModule,
    MatDividerModule,
    MatTabsModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    MatSlideToggle,
  ],
  declarations: [
    AccountOverviewComponent,
    AccountDetailsComponent,
    UpdateAccountFormComponent,
    ExtraOptionsComponent,
    RemoveAccountDialogComponent,
    ManageAccountComponent,
  ],
  exports: [

  ]
})
export class AccountModule { }
