import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { NotificationService } from '../shared/service/notification.service';
import { ErrorFormatterService } from './service/error-formatter.service';
import { DefaultLoggerService } from './service/default-logger.service';
import { environment } from '../../environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) { }

  handleError(error: Error | HttpErrorResponse) {
    const notificationService = this.injector.get(NotificationService);
    const defaultLogger = this.injector.get(DefaultLoggerService);
    const formatterService = this.injector.get(ErrorFormatterService);
    const ngZone = this.injector.get(NgZone);

    let formattedMessage = '';

    if (error instanceof HttpErrorResponse) {
      formattedMessage = formatterService.formatServerError(error);

      // Must be run in ng zone because the error handler doesn't
      // perform change detection which can cause render bugs.
      ngZone.run(() => {
        notificationService.error(formattedMessage);
      });
    }

    // Not sure what to do yet with client errors because many
    // of these errors we don't want to directly communicate to
    // the end user by using a notification.

    defaultLogger.setHttpIgnoreKeys(
      environment
        .errorHandling
        .httpIgnoreKeys
    );
    defaultLogger.log(error);

    return throwError(() => error);
  }
}
