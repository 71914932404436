import { Component, Input } from '@angular/core';
import { ArticleData } from '../../article.model';

@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.scss']
})
export class ArticleDetailsComponent {

  @Input() articleData!: ArticleData;

  constructor() { }

  get packShot() {
    return this.articleData._links?.packShot
  }

  get equivalent(): ArticleData | undefined {
    return this.articleData._embedded?.equivalent;
  }
}
