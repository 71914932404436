<a routerLink="/">Home</a> / <a routerLink="/equivalents">Equivalents</a> / Create
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/account-managers"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>New equivalent</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar>label</mat-icon>
        <mat-card-title>New</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col form-wrapper">
            <form [formGroup]="form">
              <mat-form-field appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type">
                  <mat-option value="product">
                    Product
                  </mat-option>
                  <mat-option value="article">
                    Article
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Code</mat-label>
                <input type="text" matInput formControlName="code">
                <mat-error *ngIf="hasError('code', 'required')">Code is required</mat-error>
                <mat-error *ngIf="hasError('code', 'pattern')">Code can only contain digits</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>New code</mat-label>
                <input type="text" matInput formControlName="newCode">
                <mat-error *ngIf="hasError('newCode', 'required')">New code is required</mat-error>
                <mat-error *ngIf="hasError('newCode', 'pattern')">Code can only contain digits</mat-error>
              </mat-form-field>
            </form>
          </div>
        </div>
        <br/>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button
          mat-flat-button color="primary"
          class="button-save"
          (click)="onSubmit()"
        >
          <div class="d-flex">
            Save
            <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
          </div>
        </button>
        <button
          mat-flat-button color="secondary"
          routerLink="/equivalents"
        >
          Cancel
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
