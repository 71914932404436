import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, merge, Subject } from 'rxjs';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../shared/service/notification.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ProductDataSource } from '../product.data-source';
import { ProductService } from '../product.service';
import { RemoveProductDialogComponent } from '../remove-product-dialog/remove-product-dialog.component';

@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.scss']
})
export class ProductOverviewComponent implements AfterViewInit, OnDestroy, OnInit {

  private searchInputSubject = new Subject<string>();
  public searchInput = this.searchInputSubject.asObservable();
  public searchText = '';
  private searchEmpty = true;
  public searchField: string = 'title';
  public tableColumns: string[] = [
    'title',
    'productCode',
    'ripCode',
    'brand',
    'updatedAt',
    'actions'
  ];

  public sortActiveDefault = 'title';
  public sortDirectionDefault: SortDirection = 'asc';
  public pageSizeOptions: number[] = [15, 30, 50];
  public totalItems = 0;
  public dataSource: ProductDataSource;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private productService: ProductService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
    this.dataSource = new ProductDataSource(this.productService);

    this.dataSource.dataLength.subscribe((total) => {
      this.totalItems = total
    });
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    // Reset pagination after sort change.
    this.sort.sortChange
      .subscribe(() => this.paginator.pageIndex = 0);

    // Load data when a sort or paginator event is emitted.
    merge(this.sort.sortChange, this.paginator.page)
      .subscribe(() => this.loadData());

    // Load data when search input value changes.
    this.searchInput
      .pipe(
        debounceTime(150)
      )
      .subscribe((value) => {
        if (!this.searchEmpty || value.length) {
          this.paginator.pageIndex = 0;
          this.loadData()
        }

        this.searchEmpty = value.length === 0;
      });
  }

  ngOnDestroy() {
    this.searchInputSubject.unsubscribe();
  }

  public onSearch(): void {
    this.searchInputSubject.next(this.searchText);
  }

  public onSearchClear(): void {
    this.searchText = '';
    this.onSearch();
  }

  public onChangeFilterType(event: MatButtonToggleChange): void {
    this.onSearchClear();
  }

  public onRemoveAction(id: string, title: string, code: string): void {
    if (this.dialog.openDialogs.length) {
      return;
    }

    this.dialog
      .open(RemoveProductDialogComponent, {
        data: {
          id: id,
          title: title,
          code: code
        },
      })
      .afterClosed()
      .subscribe(
        data => {
          if (data?.id) {
            this.deleteProduct(data.id);
          }
        }
      );
  }

  private deleteProduct(productId: string): void {
    this.productService
      .delete(productId)
      .subscribe({
        next: () => {
          this.notificationService.success_ts('product.deleted');
          this.loadData();
        }
      })
  }

  private loadData() {
    this.dataSource.loadProducts(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || this.pageSizeOptions[0],
      this.sort?.active || this.sortActiveDefault,
      this.sort?.direction || this.sortDirectionDefault,
      this.searchText,
      this.searchField
    );
  }
}
