<h1 mat-dialog-title>Remove article</h1>
<div mat-dialog-content>
  Are you sure you want to remove the following article ?
  <br/><br/>
  <strong>{{data.title}} ({{data.code}})</strong>
</div>
<div mat-dialog-actions >
  <button (click)="onConfirm(data.id)" mat-button cdkFocusInitial>Ok</button>
  <button mat-button mat-dialog-close>Cancel</button>
</div>

