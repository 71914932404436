import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private static readonly ACTION_CLOSE = 'global.close'

  constructor(
    public snackBar: MatSnackBar,
    public translateService: TranslateService
  ) { }

  success(message: string): void {
    this.snackBar.open(
      message,
      '',
      {
        panelClass: ['notification-success']
      }
    );
  }

  success_ts(message: string): void {
    this.success(this.translateService.instant(message));
  }

  error(message: string): void {
    this.snackBar.open(
      message,
      this.translateService.instant(NotificationService.ACTION_CLOSE),
      {
        panelClass: ['notification-error'],
        duration: 0
      }
    );
  }

  error_ts(message: string): void {
    this.error(this.translateService.instant(message));
  }
}
