import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

interface ErrorPayload {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class DefaultLoggerService {
  private httpIgnoreKeys: string[] = [];

  public setHttpIgnoreKeys(keys: string[]) {
    this.httpIgnoreKeys = keys;
  }

  public log(error: Error | HttpErrorResponse): void {
    let message;

    // If we got an HTTP error response with an additional error
    // (which is an API specific key) key we will format this error,
    // so we can display it properly in the console.
    if (error instanceof HttpErrorResponse && error.error instanceof Object) {
      message = this.convertErrorResponse(error.error);
    } else {
      message = error;
    }

    console.warn(message);
  }

  private convertErrorResponse(payload: ErrorPayload): string {
    const keys = Object.keys(payload);
    let message = '';

    keys.forEach((key) => {
      let value = payload[key];

      if (this.httpIgnoreKeys.includes(key)) {
        return;
      }

      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }

      message += `${key}: ${value}\n`;
    });

    return message;
  }
}
