<div class="tab-content-wrapper">
  <div class="row">
    <div class="col form-wrapper">
      <form [formGroup]="form">
        <mat-form-field appearance="fill">
          <mat-label>SAP condition 010</mat-label>
          <input type="text" matInput formControlName="sapSalesCond010" appEmptyToNull>
          <mat-error *ngIf="hasError('sapSalesCond010', 'required')">Condition is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>SAP condition 011</mat-label>
          <input type="text" matInput formControlName="sapSalesCond011" appEmptyToNull>
          <mat-error *ngIf="hasError('sapSalesCond011', 'required')">Condition is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>SAP condition 012</mat-label>
          <input type="text" matInput formControlName="sapSalesCond012" appEmptyToNull>
          <mat-error *ngIf="hasError('sapSalesCond012', 'required')">Condition is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>SAP condition 013</mat-label>
          <input type="text" matInput formControlName="sapSalesCond013" appEmptyToNull>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>SAP condition 014</mat-label>
          <input type="text" matInput formControlName="sapSalesCond014" appEmptyToNull>
        </mat-form-field>
        <div class="form-checkbox">
          <mat-checkbox formControlName="useDefaultSendConditions" >
            Use default shipping conditions
          </mat-checkbox>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-divider class="pb-2"></mat-divider>
      <button
        mat-flat-button color="primary"
        class="button-save"
        (click)="onSubmit()"
      >
        <div class="d-flex">
          Save
          <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
        </div>
      </button>
    </div>
  </div>
</div>
