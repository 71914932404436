import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ImportHistoryService } from '../import-history.service';
import { HistoryData } from '../import-history-model';
import { statusColor } from '../import-history.utils';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  public entryId!: string;
  public historyData: Observable<HistoryData>;
  public isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    public importHistoryService: ImportHistoryService,
  ) {
    this.historyData = new Observable<HistoryData>();
  }

  ngOnInit(): void {
    this.entryId = this.route.snapshot.paramMap.get('id')!;
    this.historyData = this.importHistoryService
      .find(this.entryId)
      .pipe(
        finalize( () => this.isLoading = false),
      );
  }

  formatExtraInfo(obj: Record<string, any>): string {
    return JSON.stringify(obj, null, 2);
  }

  public statusClass(status: string): string {
    return statusColor(status);
  }
}
