import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EquivalentOverviewComponent } from './equivalent-overview/equivalent-overview.component';
import { CreateEquivalentFormComponent } from './create-equivalent-form/create-equivalent-form.component';
import { ImportEquivalentFormComponent } from './import-equivalent-form/import-equivalent-form.component';

const routes: Routes = [
  {
    path: 'equivalents',
    component: EquivalentOverviewComponent
  },
  {
    path: 'equivalents/create',
    component: CreateEquivalentFormComponent
  },
  {
    path: 'equivalents/import',
    component: ImportEquivalentFormComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EquivalentRoutingModule { }
