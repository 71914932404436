<div class="tab-content-wrapper">
  <div class="row">
    <div class="col form-wrapper">
      <form [formGroup]="form">
        <span class="form-section-title">Features</span>
        <br><br>
        <div class="form-checkbox">
          <mat-checkbox formControlName="activateSap">
            Activate SAP
          </mat-checkbox>
        </div>
        <div class="form-checkbox">
          <mat-checkbox formControlName="activateExternalSoftware" >
            Activate external software
          </mat-checkbox>
        </div>
        <div class="form-checkbox">
          <mat-checkbox formControlName="activateTotal24">
            Activate Total 24
          </mat-checkbox>
        </div>
        <div class="form-checkbox">
          <mat-checkbox formControlName="activateTrackAndTrace">
            Activate track and trace
          </mat-checkbox>
        </div>
        <div class="form-checkbox">
          <mat-checkbox formControlName="activateElub">
            Activate elub
          </mat-checkbox>
        </div>
        <mat-form-field appearance="fill" *ngIf="showElubLogin">
          <mat-label>Elub login</mat-label>
          <input type="text" matInput formControlName="elubLogin" appEmptyToNull>
        </mat-form-field>
        <div class="form-checkbox">
          <mat-checkbox formControlName="activateTelemetry">
            Activate telemetry
          </mat-checkbox>
        </div>
        <div class="form-checkbox">
          <mat-checkbox formControlName="activateTelemetryTabs">
            Activate telemetry tabs
          </mat-checkbox>
        </div>
        <div class="form-checkbox">
          <mat-checkbox formControlName="activateAnac">
            Ativate ANAC
          </mat-checkbox>
        </div>
        <div class="form-checkbox">
          <mat-checkbox formControlName="activateBrekupaProducts">
            Activate Brekupa products
          </mat-checkbox>
        </div>
        <div class="form-checkbox">
          <mat-checkbox formControlName="activateSumisConnection">
            Activate Sumis connection
          </mat-checkbox>
        </div>
        <br>
        <span class="form-section-title">Backoffice</span>
        <br><br>
        <mat-form-field appearance="fill">
          <mat-label>Alternative email</mat-label>
          <input type="text" matInput formControlName="alternativeBackOfficeEmail" appEmptyToNull>
          <mat-error *ngIf="hasError('alternativeBackOfficeEmail', 'email')">Invalid email address</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Alternative SAP code</mat-label>
          <input type="text" matInput formControlName="sapCodeAlternativeBackOffice" appEmptyToNull>
          <mat-error *ngIf="hasError('sapCodeAlternativeBackOffice', 'pattern')">Code has an invalid format (only digits allowed)</mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-divider class="pb-2"></mat-divider>
      <button
        mat-flat-button color="primary"
        class="button-save"
        (click)="onSubmit()"
      >
        <div class="d-flex">
          Save
          <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
        </div>
      </button>
    </div>
  </div>
</div>
