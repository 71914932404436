import { Component, Input } from '@angular/core';
import { OrganizationAddress } from '../../../organization.model';

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss']
})
export class AddressDetailsComponent {

  @Input() addressData!: OrganizationAddress | undefined;

  constructor() { }
}
