import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountOption, DataSavedEvent, MarketProfileOption, OrganizationData } from '../../organization.model';
import { NotificationService } from '../../../shared/service/notification.service';
import { OrganizationService } from '../../organization.service';
import { filter, finalize } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, switchMap } from 'rxjs';
import { PRODUCT_CODES } from '../../../shared/misc/regex';
import { FormHelper } from '../../../shared/mixin/form-helper';

@Component({
  selector: 'app-organization-access',
  templateUrl: './organization-access.component.html',
  styleUrls: ['./organization-access.component.scss']
})
export class OrganizationAccessComponent extends FormHelper() implements OnInit {
  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public separatorKeysCodes: number[] = [ENTER, COMMA];

  public accountOptions: Observable<AccountOption[]>;
  public accountOptionsLoading: boolean = false;
  public accountsSelected: Map<string, string>;

  public marketProfileOptions: Observable<MarketProfileOption[]>;
  public marketProfileOptionsLoading: boolean = false;
  public marketProfilesSelected: Map<string, string>;

  @Input() organizationData!: OrganizationData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  @ViewChild('accountsInput') accountsInput!: ElementRef<HTMLInputElement>;
  @ViewChild('marketProfilesInput') marketProfilesInput!: ElementRef<HTMLInputElement>;

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private organizationService: OrganizationService
  ) {
    super();

    this.accountOptions = new Observable();
    this.marketProfileOptions = new Observable();
    this.accountsSelected = new Map();
    this.marketProfilesSelected = new Map();
  }

  ngOnInit(): void {
    this.form = this.createForm();

    this.organizationData.accounts?.forEach((value) => {
      this.accountsSelected.set(value.id, value.fullName);
    })

    this.organizationData.marketProfiles?.forEach((value) => {
      this.marketProfilesSelected.set(value.id, value.name);
    })

    this.setupAccountInputListener();
    this.setupMarketProfileInputListener();
  }

  public onSelectAccount(event: MatAutocompleteSelectedEvent): void {
    this.addAccount(event.option.value);
    this.accountsInput.nativeElement.value = '';
  }

  public onSelectMarketProfile(event: MatAutocompleteSelectedEvent): void {
    this.addMarketProfile(event.option.value);
    this.marketProfilesInput.nativeElement.value = '';
  }

  public onRemoveAccount(key: string): void {
    if (!this.accountsSelected.get(key)) {
      return;
    }

    this.accountsSelected.delete(key);
  }

  public onRemoveMarketProfile(key: string): void {
    if (!this.marketProfilesSelected.get(key)) {
      return;
    }

    this.marketProfilesSelected.delete(key);
  }

  public addAccount(option: AccountOption): void {
    if (this.accountsSelected.get(option.id)) {
      return;
    }

    this.accountsSelected.set(option.id, option.name);
  }

  public addMarketProfile(option: MarketProfileOption): void {
    if (this.marketProfilesSelected.get(option.id)) {
      return;
    }

    this.marketProfilesSelected.set(option.id, option.name);
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }
    this.isSaving = true;

    const productCodes = this.form
      .value
      .productCodes ?? [];

    const payload = {
      accounts: [...this.accountsSelected.keys()],
      marketProfiles: [...this.marketProfilesSelected.keys()],
      productCodes: productCodes.length ? productCodes.split(',') : []
    }

    this.organizationService
      .updateAccess(this.organizationData.id, payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('organization.settings.updated');
          this.dataSaved.emit({ organizationId: this.organizationData.id });
        }
      });
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({
      accounts: this.fb.control([]),
      marketProfiles: this.fb.control([]),
      productCodes: this.fb.control(
        this.organizationData.productCodes?.join(','),
        Validators.pattern(PRODUCT_CODES)
      )
    });
  }

  private setupAccountInputListener() {
    const ctrl = this.form.get('accounts');
    this.accountOptions = ctrl!.valueChanges
      .pipe(
        filter(() => this.accountsInput.nativeElement.value !== ''),
        switchMap((value: string | AccountOption) => {
          // Because we use [value] for the mat-autocomplete option, it will set the input
          // value to the mat-option [value] on selection which is not desirable in our case
          // hence the type check below (if we don't receive a string, reset the options).
          const filter = typeof (value) === 'string' ? value : null;
          this.accountOptionsLoading = filter !== null;

          return this.organizationService
            .getAccountOptions(filter)
            .pipe(
              finalize(() => this.accountOptionsLoading = false)
            )
        }),
      );
  }

  private setupMarketProfileInputListener() {
    const ctrl = this.form.get('marketProfiles');
    this.marketProfileOptions = ctrl!.valueChanges
      .pipe(
        filter(() => this.marketProfilesInput.nativeElement.value !== ''),
        switchMap((value: string | MarketProfileOption) => {
          const filter = typeof (value) === 'string' ? value : null;
          this.marketProfileOptionsLoading = filter !== null;

          return this.organizationService
            .getMarketProfileOptions(filter)
            .pipe(
              finalize(() => this.marketProfileOptionsLoading = false)
            )
        }),
      );
  }
}
