import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ItemOverviewComponent } from './item-overview/item-overview.component';
import { CreateItemFormComponent } from './create-item-form/create-item-form.component';
import { ManageItemComponent } from './manage-item/manage-item.component';

const routes: Routes = [
  { path: 'carousel', component: ItemOverviewComponent },
  { path: 'carousel/create', component: CreateItemFormComponent },
  { path: 'carousel/:id', component: ManageItemComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CarouselRoutingModule { }
