import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MarketProfileService } from '../market-profile.service';
import { MarketProfile } from '../market-profile.model';

@Component({
  selector: 'app-market-profile-details',
  templateUrl: './market-profile-details.component.html',
  styleUrls: ['./market-profile-details.component.scss']
})
export class MarketProfileDetailsComponent implements OnInit {
  public brochureId!: string;
  public brochureData: Observable<MarketProfile>;
  public isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    public marketProfileService: MarketProfileService,
  ) {
    this.brochureData = new Observable<MarketProfile>();
  }

  ngOnInit(): void {
    this.brochureId = this.route.snapshot.paramMap.get('id')!;
    this.brochureData = this.marketProfileService
      .find(this.brochureId)
      .pipe(
        finalize( () => this.isLoading = false),
      );
  }
}
