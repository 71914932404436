import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from "../base/api-service";

@Injectable({
  providedIn: 'root',
})
export class TemplateService extends ApiService {

  private restApiUrl = this.apiUrl('template');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public retrieve(id: string): Observable<any> {
    const options = {
      responseType: 'blob' as 'json'
    };

    return this.httpClient.get(this.restApiUrl + '/' + id, options);
  }
}
