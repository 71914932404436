import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BrandOption,
  CategoryOption,
  CreateProductData,
  DataSheetType,
  ProductCollectionResponse,
  ProductData,
  UpdateProductData,
} from './product.model';
import { ApiService } from '../shared/base/api-service';
import EmptyResponse from '../shared/model/misc';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends ApiService {
  private restApiUrl =this.apiUrl('admin/products');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public find(id: string): Observable<ProductData> {
    return this.httpClient.get<ProductData>(this.restApiUrl + '/' + id)
      .pipe(
        map((productData: ProductData): ProductData => {
          // TODO this should be done in the back-end
          if (productData?._links?.tds?.href) {
            productData._links.tds.href = productData._links.tds.href.replace(
              environment.apiUrl,
              environment.apiPublicUrl,
            );
          }
          // TODO this should be done in the back-end
          if (productData?._links?.sds?.href) {
            productData._links.sds.href = productData._links.sds.href.replace(
              environment.apiUrl,
              environment.apiPublicUrl,
            );
          }

          return productData;
        }),
      );
  }

  public findPaginated(
    page: number = 1,
    pageSize: number = 25,
    sortBy: string = 'title',
    sortDirection: string = 'asc',
    filter: string = '',
    filterType: string = 'title'
  ): Observable<ProductCollectionResponse> {
    const queryParams = new HttpParams()
      .append('page', page.toString())
      .append('page_size', pageSize.toString())
      .append('sort_by', sortBy)
      .append('sort_direction', sortDirection)
      .append('filter', filter.trim())
      .append('filter_type', filterType);

    return this.httpClient
      .get<ProductCollectionResponse>(this.restApiUrl, {params: queryParams})
  }

  public getBrandOptions(): Observable<BrandOption[]> {
    return this.httpClient
      .get<BrandOption[]>(this.apiUrl('admin/get-brand-options'));
  }

  public getCategoryOptions(): Observable<CategoryOption[]> {
    return this.httpClient
      .get<CategoryOption[]>(this.apiUrl('admin/get-category-options'));
  }

  public create(payload: CreateProductData): Observable<EmptyResponse> {
    return this.httpClient
      .post<EmptyResponse>(this.restApiUrl, payload)
  }

  public update(articleId: string, payload: UpdateProductData): Observable<EmptyResponse> {
    return this.httpClient
      .put<EmptyResponse>(this.restApiUrl + '/' + articleId, payload)
  }

  public delete(accountId: string): Observable<EmptyResponse> {
    return this.httpClient.delete<EmptyResponse>(this.restApiUrl + '/' + accountId)
  }

  public uploadDataSheet(productId: string, file: File | null, type: string) {
    if (![DataSheetType.TDS, DataSheetType.SDS].includes(type)) {
      throw new Error(`Invalid sheet type: ${type}`)
    }

    const payload: FormData = new FormData();
    payload.append('file', file ?? '');
    payload.append('type', type);

    return this.httpClient
      .put<EmptyResponse>(this.restApiUrl + '/' + productId + '/file', payload);
  }
}
