import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../shared/base/api-service';
import { Observable } from 'rxjs';
import { AddBlockedDateData, BlockedDateCollectionResponse } from './blocked-delivery-dates.model';
import EmptyResponse from '../shared/model/misc';

@Injectable({
  providedIn: 'root'
})
export class BlockedDatesService extends ApiService {

  private restApiUrl = this.apiUrl('admin/blocked-delivery-date');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public findAll(): Observable<BlockedDateCollectionResponse> {
    return this.httpClient
      .get<BlockedDateCollectionResponse>(this.restApiUrl)
  }

  public create(payload: AddBlockedDateData): Observable<EmptyResponse> {
    return this.httpClient
      .post<EmptyResponse>(this.restApiUrl, payload)
  }

  public delete(id: string) {
    return this.httpClient.delete<any>(this.restApiUrl + '/' + id)
  }
}
