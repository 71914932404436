<a routerLink="/">Home</a> / <a routerLink="/news">News Item</a> / Manage
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/news">
      <mat-icon>arrow_back</mat-icon>
    </a>
  </div>
  <div class="col title">
    <h1>Manage News Item</h1>
  </div>
</div>
<div class="row">
  <div class="col card-wrapper">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class=""></mat-progress-bar>
    <mat-card>
      <div *ngIf="itemData">
        <mat-card-header>
          <mat-icon mat-card-avatar>newspaper</mat-icon>
          <mat-card-title>{{ itemData.title }}</mat-card-title>
        </mat-card-header>
        <mat-card-content id="card1" #cardContent>
          <mat-tab-group
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            animationDuration="0"
            (selectedIndexChange)="onBeforeTabChange($event)"
            (selectedTabChange)="onTabChange($event)"
          >
            <mat-tab label="Details">
              <app-item-details
                *ngIf="currentTab === 'details'"
                [itemData]="itemData"
              >
              </app-item-details>
            </mat-tab>

            <mat-tab label="Edit">
              <app-update-item-form
                *ngIf="currentTab === 'edit'"
                (dataSaved)="onDataSaved($event)"
                [itemData]="itemData"
              >
              </app-update-item-form>
            </mat-tab>

            <mat-tab label="Images">
              <app-item-images
                *ngIf="currentTab === 'images'"
                (dataSaved)="onDataSaved($event)"
                [itemData]="itemData"
              >
              </app-item-images>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>
