import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTreeModule } from '@angular/cdk/tree';
import { PortalModule } from '@angular/cdk/portal';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrganizationModule } from './organization/organization.module';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NewsModule } from './news/news.module';
import { CarouselModule } from './carousel/carousel.module';
import { BlockedDeliveryDateModule } from './blocked-delivery-dates/blocked-delivery-dates.module';
import { AccountModule } from './account/account.module';
import { AccountManagerModule } from './account-manager/account-manager.module';
import { ArticleModule } from './article/article.module';
import { ProductModule } from './product/product.module';
import { EquivalentModule } from './equivalent/equivalent.module';
import { VideoModule } from './video/video.module';
import { MagazineModule } from './magazine/magazine.module';
import { BrochureModule } from './brochure/brochure.module';
import { DeliveryNotificationModule } from './delivery-notification/delivery-notification.module';
import { MarketProfileModule } from './market-profile/market-profile.module';
import { CoreModule } from './core';
import { GlobalErrorHandler } from './core/error-handler';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OrderHistoryModule } from './order-history/order-history.module';
import { OrderImportModule } from './order-import/order-import.module';
import { ImportHistoryModule } from './import-history/import-history.module';
import { SapCommunicationModule } from './sap-communication/sap-communication.module';
import { registerLocaleData } from '@angular/common';
import { DashboardModule } from './dashboard/dashboard.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import localeNL from '@angular/common/locales/nl';
import { RouterModule } from '@angular/router';
import { ArticleStockImportModule } from "./article-stock-import/article-stock-import.module";
import { TelemetrySettingsImportModule } from "./telemetry-settings-import/telemetry-settings-import.module";
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

registerLocaleData(localeNL, 'nl');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
  ],
  imports: [
    RouterModule,
    AccountManagerModule,
    AccountModule,
    AppRoutingModule,
    ArticleModule,
    ArticleStockImportModule,
    BlockedDeliveryDateModule,
    BrochureModule,
    BrowserAnimationsModule,
    BrowserModule,
    CarouselModule,
    CdkTreeModule,
    CoreModule,
    DashboardModule,
    DeliveryNotificationModule,
    EquivalentModule,
    FormsModule,
    HttpClientModule,
    ImportHistoryModule,
    LayoutModule,
    MagazineModule,
    MarketProfileModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatTooltipModule,
    MatProgressBarModule,
    NewsModule,
    OrganizationModule,
    OrderHistoryModule,
    OrderImportModule,
    OverlayModule,
    PortalModule,
    ProductModule,
    ReactiveFormsModule,
    SapCommunicationModule,
    SharedModule,
    TelemetrySettingsImportModule,
    VideoModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: environment.oauth.resourceServer.allowedUrls,
        sendAccessToken: environment.oauth.resourceServer.sendAccessToken,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    MatProgressSpinnerModule,
  ],
  providers: [
    // TinyMCE
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: 'assets/tinymce/tinymce.min.js',
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2500,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        disableClose: false,
        panelClass: 'basic-dialog',
        autoFocus: true,
      },
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
