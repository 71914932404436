<div class="tab-content-wrapper">
  <div class="row">
    <div class="col form-wrapper">
      <div class="row">
        <div class="col-md-6 form-label">
          Send account ready notification
        </div>
        <div class="col-md-6" *ngIf="!accountData.readyNotificationSent; else readyNotificationAlreadySent">
          <button
            *ngIf="canTriggerAccountReadyNotification; else triggerAccountNotificationNotAvailable"
            mat-stroked-button
            color="primary"
            class="button-save"
            (click)="sendAccountReadyNotification()"
          >
            <div class="d-flex">
              Send
              <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
            </div>
          </button>
          <ng-template #triggerAccountNotificationNotAvailable>
            <div class="form-text not-available">Not available</div>
          </ng-template>
        </div>
        <ng-template #readyNotificationAlreadySent>
          <div class="col-md-6"><div class="form-text">Sent at: {{ accountData.readyNotificationSentAt | date : "dd MMM Y hh:mm" }}</div></div>
        </ng-template>
        <br/>
        <p class="explanation">Only available for non-admin users and users that<br/>are connected to at least 1 organization.</p>
      </div>
    </div>
  </div>
</div>
