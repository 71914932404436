<a routerLink="/">Home</a> / <a routerLink="/organizations">Organizations</a> / Manage
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/organizations"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>Manage Organization</h1>
  </div>
</div>
<div class="row">
  <div class="col card-wrapper">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class=""></mat-progress-bar>
    <mat-card>
      <div *ngIf="organizationData">
        <mat-card-header>
          <mat-icon mat-card-avatar>business</mat-icon>
          <mat-card-title>{{ organizationData.name }}</mat-card-title>
        </mat-card-header>
        <mat-card-content id="card1" #cardContent>
          <mat-tab-group
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            animationDuration="0"
            (selectedIndexChange)="onBeforeTabChange($event)"
            (selectedTabChange)="onTabChange($event)"
            #matTabGroup
          >
            <mat-tab label="Details">
              <app-organization-details
                *ngIf="currentTab === 'details'"
                [organizationData]="organizationData"
              >
              </app-organization-details>
            </mat-tab>
            <mat-tab label="General">
              <app-organization-general
                *ngIf="currentTab === 'general'"
                (dataSaved)="onDataSaved($event)"
                [organizationData]="organizationData"
              >
              </app-organization-general>
            </mat-tab>
            <mat-tab label="Settings">
              <app-organization-settings
                *ngIf="currentTab === 'settings'"
                (dataSaved)="onDataSaved($event)"
                [organizationData]="organizationData"
              >
              </app-organization-settings>
            </mat-tab>

            <mat-tab label="Access" bodyClass="test">
              <app-organization-access
                *ngIf="currentTab === 'access'"
                (dataSaved)="onDataSaved($event)"
                [organizationData]="organizationData"
              >
              </app-organization-access>
            </mat-tab>

            <mat-tab label="Sales conditions">
              <app-organization-sales-conditions
                *ngIf="currentTab === 'sales_conditions'"
                (dataSaved)="onDataSaved($event)"
                [organizationData]="organizationData"
              >
              </app-organization-sales-conditions>
            </mat-tab>
            <mat-tab label="Telemetry">
              <app-organization-telemetry
                *ngIf="currentTab === 'telemetry'"
                [organizationData]="organizationData"
              >
              </app-organization-telemetry>
            </mat-tab>
            <mat-tab label="Address">
              <app-organization-address
                *ngIf="currentTab === 'address'"
                (dataSaved)="onDataSaved($event)"
                [organizationData]="organizationData"
              >
              </app-organization-address>
            </mat-tab>
            <mat-tab label="Logo">
              <app-organization-logo
                *ngIf="currentTab === 'logo'"
                (dataSaved)="onDataSaved($event)"
                [organizationData]="organizationData"
              >
              </app-organization-logo>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>
