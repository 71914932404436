<div class="tab-content-wrapper">
  <div class="row">
    <div class="col form-wrapper">
      <form [formGroup]="form">
        <span class="form-section-title">Members</span>
        <br /><br />
        <mat-label>Accounts</mat-label>
        <mat-form-field appearance="fill">
          <mat-chip-grid #accountsChipList>
            <mat-chip
              *ngFor="let item of accountsSelected | keyvalue"
              (removed)="onRemoveAccount(item.key)"
            >
              {{ item.value }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input
              #accountsInput
              placeholder="Type to search by name or email address"
              matInput
              formControlName="accounts"
              [matAutocomplete]="auto1"
              [matChipInputFor]="accountsChipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          </mat-chip-grid>
          <mat-error>At least 1 role is required.</mat-error>
          <mat-autocomplete
            #auto1="matAutocomplete"
            (optionSelected)="onSelectAccount($event)"
          >
            <mat-option
              *ngFor="let option of accountOptions | async"
              [value]="option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-icon *ngIf="accountOptionsLoading" class="input-loading-spinner">
            <mat-spinner diameter="20"> </mat-spinner>
          </mat-icon>
        </mat-form-field>
        <br /><br />
        <span class="form-section-title">Products</span>
        <br /><br />
        <mat-label>Market profiles</mat-label>
        <mat-form-field appearance="fill">
          <mat-chip-grid #marketProfilesChipList>
            <mat-chip
              *ngFor="let item of marketProfilesSelected | keyvalue"
              (removed)="onRemoveMarketProfile(item.key)"
            >
              {{ item.value }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input
              #marketProfilesInput
              placeholder="Type to search"
              matInput
              formControlName="marketProfiles"
              [matAutocomplete]="auto2"
              [matChipInputFor]="marketProfilesChipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          </mat-chip-grid>
          <mat-error>At least 1 role is required.</mat-error>
          <mat-autocomplete
            #auto2="matAutocomplete"
            (optionSelected)="onSelectMarketProfile($event)"
          >
            <mat-option
              *ngFor="let option of marketProfileOptions | async"
              [value]="option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-icon
            *ngIf="marketProfileOptionsLoading"
            class="input-loading-spinner"
          >
            <mat-spinner diameter="20"> </mat-spinner>
          </mat-icon>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Product codes</mat-label>
          <textarea
            formControlName="productCodes"
            name="productCodes"
            placeholder="Insert product codes here .."
            rows="7"
            matInput
          >
          </textarea>
          <mat-error *ngIf="hasError('productCodes', 'pattern')"
            >Invalid format. Allowed: alpha-numeric characters [A-Z0-9]
            separated by a comma</mat-error
          >
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-divider class="pb-2"></mat-divider>
      <button
        mat-flat-button
        color="primary"
        class="button-save"
        (click)="onSubmit()"
      >
        <div class="d-flex">
          Save
          <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
        </div>
      </button>
    </div>
  </div>
</div>
