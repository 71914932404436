<a routerLink="/">Home</a> / Order History <br/><br/>
<div class="row page-header">
  <div class="col title">
    <h1>Order History</h1>
  </div>
</div>
<div class="col pb-3 grid-controls">
  <div class="search">
    <mat-form-field class="w-100 pr-5 no-subscript" appearance="outline">
      <mat-label>Search</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="searchText"
        (keyup)="onSearch()"
      />
      <button
        *ngIf="searchText"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="onSearchClear()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="buttons">
    <mat-button-toggle-group
      name="fontStyle"
      aria-label="Font Style"
      (change)="onChangeFilterType($event)"
      [(ngModel)]="searchField"
    >
      <mat-button-toggle
        [class]="{ 'toggle-active': searchField === 'number' }"
        value="number"
      >
        Number
      </mat-button-toggle>
      <mat-button-toggle
        [class]="{ 'toggle-active': searchField === 'organization' }"
        value="organization"
      >
        Organization
      </mat-button-toggle>
      <mat-button-toggle
        [class]="{ 'toggle-active': searchField === 'user' }"
        value="user"
      >
        User
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="table-wrapper mat-elevation-z3">
      <div class="table-progress-overlay" *ngIf="dataSource.loading | async">
        <mat-progress-spinner mode="indeterminate" diameter="50">
        </mat-progress-spinner>
      </div>
      <table
        [dataSource]="dataSource"
        mat-table
        matSort
        [matSortActive]="sortActiveDefault"
        [matSortDirection]="sortDirectionDefault"
        matSortDisableClear
      >
        <ng-container matColumnDef="orderSequence">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sequence</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/order-history/', element.id]">{{
                element.orderSequence
              }}</a>
          </td>
        </ng-container>
        <ng-container matColumnDef="orderNumber">
          <th mat-header-cell *matHeaderCellDef>Number</th>
          <td mat-cell *matCellDef="let element">{{ element.orderNumber }}</td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>Created at</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date : "dd MMM yyyy hh:mm" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef>Created by user</th>
          <td mat-cell *matCellDef="let element">{{ element.createdBy }}</td>
        </ng-container>
        <ng-container matColumnDef="organization">
          <th mat-header-cell *matHeaderCellDef>Organization</th>
          <td mat-cell *matCellDef="let element">{{ element.organization }}</td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">{{ element.type }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-data" [attr.colspan]="tableColumns.length">
            No data
          </td>
        </tr>
      </table>
      <mat-paginator
        [length]="totalItems"
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>
