import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/service/notification.service';
import { filter, finalize, tap } from 'rxjs/operators';
import { FormHelper } from '../../shared/mixin/form-helper';
import { OrganizationService } from '../organization.service';
import { CreateOrganizationData } from '../organization.model';
import { Observable, switchMap } from 'rxjs';
import { OrganizationOption } from '../../order-import/order-import.model';

@Component({
  selector: 'app-create-organization-form',
  templateUrl: './create-organization-form.component.html',
  styleUrls: ['./create-organization-form.component.scss']
})
export class CreateOrganizationFormComponent extends FormHelper() implements OnInit {

  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public organizationOptions: Observable<OrganizationOption[]>;
  public organizationOptionsMap: { [key: string]: string };
  public organizationOptionsLoading: boolean = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private organizationService: OrganizationService,
    private notificationService: NotificationService
  ) {
    super();
    this.organizationOptions = new Observable();
    this.organizationOptionsMap = {};
  }

  ngOnInit() {
    this.form = this.createForm();
    this.setupOrganizationInputListener();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    const payload: CreateOrganizationData = {
      name: this.form.value.name,
      customerCode: this.form.value.customerCode.padStart(10, '0'),
      parentOrganizationId: this.form.value.parentOrganizationId,
      active: this.form.value.active
    }

    this.isSaving = true;

    this.organizationService
      .create(payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('organization.created');
          this.router.navigateByUrl('/organizations');
        }
      });
  }

  public onSelectOrganizationDisplayValue(organizationId: string | null): string {
    return organizationId && this.organizationOptionsMap[organizationId] || '';
  }

  private createForm() {
    return this.fb.group({
      name: this.fb.control('', Validators.required),
      customerCode: this.fb.control('', Validators.required),
      parentOrganizationId: this.fb.control(null),
      active: this.fb.control(true)
    });
  }

  private setupOrganizationInputListener() {
    const ctrl = this.form.get('parentOrganizationId');

    this.organizationOptions = ctrl!.valueChanges
      .pipe(
        // Because we use [value] for the mat-autocomplete option, it will set the
        // input value to the mat-option [value] on selection which fires a value change.
        // This is a change we don't want to trigger a lookup for, hence the workaround
        // below which checks if the input value is found in the lookup map (which means
        // an ID was set instead of capturing user input), if yes, we cancel the operation.
        filter((value) => this.organizationOptionsMap[value] === undefined),
        switchMap((value: string) => {
          this.organizationOptionsLoading = true;

          return this.organizationService
            .getOrganizationOptions(value, true)
            .pipe(
              finalize(() => this.organizationOptionsLoading = false)
            )}),
        // Create lookup map for organization options (to make [displayWith] work properly)
        tap((values) => {
          this.organizationOptionsMap = {};

          values.forEach((option) => {
            this.organizationOptionsMap[option.id] = option.name + ' (' + option.code + ')';
          });
        })
      );
  }
}
