<a routerLink="/">Home</a> / <a routerLink="/articles">Articles</a> / New Article
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/articles"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>New Article</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar>shopping_basket</mat-icon>
        <mat-card-title>New</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col form-wrapper">
            <form [formGroup]="form">
              <mat-form-field appearance="fill">
                <mat-label>Title</mat-label>
                <input formControlName="title" name="title" type="text" matInput>
                <mat-error *ngIf="hasError('title', 'required')">Title is required</mat-error>
                <mat-error *ngIf="hasError('title', 'minlength')">Title must contain a minimum of 2 characters</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Description</mat-label>
                <input
                  formControlName="description"
                  name="description"
                  type="text"
                  matInput
                  appEmptyToNull
                >
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Product</mat-label>
                <input
                  type="text"
                  placeholder="Type name or product code .."
                  aria-label="Number"
                  matInput
                  formControlName="productId"
                  [matAutocomplete]="auto">
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="onSelectProductDisplayValue.bind(this)"
                >
                  <mat-option *ngFor="let option of productOptions | async" [value]="option.id">
                    {{ option.name }} ({{ option.code }})
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="hasError('productId', 'required')">Product is required</mat-error>
                <mat-icon *ngIf="productOptionsLoading" class="input-loading-spinner">
                  <mat-spinner diameter="20">
                  </mat-spinner>
                </mat-icon>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>SAP code</mat-label>
                <input formControlName="sapCode" name="sapCode" type="number" matInput>
                <mat-error *ngIf="hasError('sapCode', 'required')">SAP code is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Packaging</mat-label>
                <input formControlName="packaging" name="packaging" type="text" matInput>
                <mat-error *ngIf="hasError('packaging', 'required')">Packaging is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Sold per</mat-label>
                <input formControlName="per" name="per" type="number" matInput>
                <mat-error *ngIf="hasError('per', 'required')">Sold per is required</mat-error>
              </mat-form-field>
              <mat-button-toggle-group class="lkg-toggle" formControlName="lkg">
                <mat-button-toggle value="L">L</mat-button-toggle>
                <mat-button-toggle value="KG">KG</mat-button-toggle>
              </mat-button-toggle-group>
              <mat-error *ngIf="hasError('lkg', 'required')" class="lkg-error">L/KG is required</mat-error>
              <mat-form-field appearance="fill">
                <mat-label>Gross price</mat-label>
                <input
                  formControlName="grossPrice"
                  name="grossPrice"
                  type="number"
                  placeholder="0.00"
                  min="0"
                  [minFractionDigits]="2"
                  matInput
                  appFormatDecimal
                >
                <mat-error *ngIf="hasError('grossPrice', 'required')">Gross price is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Sugg. retail price</mat-label>
                <input
                  formControlName="suggestedRetailPrice"
                  name="suggestedRetailPrice"
                  type="number"
                  placeholder="0.00"
                  min="0"
                  [minFractionDigits]="2"
                  matInput
                  appFormatDecimal
                >
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Quantity</mat-label>
                <input formControlName="quantity" name="quantity" type="number" matInput>
                <mat-error *ngIf="hasError('quantity', 'required')">Quantity is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Content</mat-label>
                <input
                  formControlName="content"
                  name="content"
                  type="number"
                  placeholder="0.000"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="3"
                  matInput
                  appFormatDecimal
                >
                <mat-error *ngIf="hasError('content', 'required')">Content is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Min. order quantity</mat-label>
                <input
                  formControlName="minimumOrderQuantity"
                  name="minimumOrderQuantity"
                  type="number"
                  matInput
                >
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Total 24 code</mat-label>
                <input formControlName="total24Code" name="total24Code" matInput appEmptyToNull>
              </mat-form-field>
              <br/><br/>
              <mat-slide-toggle formControlName="activateForSap" name="activateForSap">
                SAP activated
              </mat-slide-toggle>
              <br/><br/>
              <mat-slide-toggle formControlName="activateForTotal24" name="activateForTotal24">
                Total24 activated
              </mat-slide-toggle>
              <br/><br/>
              <mat-slide-toggle formControlName="activateForSumis" name="activateForSumis">
                Sumis activated
              </mat-slide-toggle>
              <br/><br/>
              <mat-slide-toggle formControlName="activeProduct" name="activeProduct">
                Active
              </mat-slide-toggle>
            </form>
          </div>
        </div>
        <br/>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button
          mat-flat-button color="primary"
          class="button-save"
          (click)="onSubmit()"
        >
          <div class="d-flex">
            Save
            <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
          </div>
        </button>
        <button
          mat-flat-button color="secondary"
          routerLink="/articles"
        >
          Cancel
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
