import { Injectable, isDevMode } from '@angular/core';
import { AuthConfig, OAuthService, UrlHelperService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { CodeFlowParams, IdentityClaims } from '../core.model';
import { UserService } from './user.service';
import { finalize } from 'rxjs/operators';

const config: AuthConfig = {
  issuer: environment.oauth.issuer,
  clientId: environment.oauth.clientId,
  responseType: 'code',
  redirectUri: environment.oauth.redirectUri,
  postLogoutRedirectUri: environment.oauth.redirectUri,
  strictDiscoveryDocumentValidation: environment.oauth.strictDiscoveryDocumentValidation,
  waitForTokenInMsec: environment.oauth.waitForTokenMsec,
  showDebugInformation: environment.oauth.showDebugInformation,
  requireHttps: environment.oauth.requireHttps,
  scope: environment.oauth.scope,
  oidc: true,
  customQueryParams: { 'identity_provider': environment.oauth.idp }
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private isAuthenticatedSubject = new BehaviorSubject(false);
  public readonly isAuthenticated: Observable<boolean> = this.isAuthenticatedSubject.asObservable();

  private isAuthorizedSubject = new BehaviorSubject(false);
  public readonly isAuthorized: Observable<boolean> = this.isAuthorizedSubject.asObservable();

  private authenticatedUserNameSubject = new BehaviorSubject<string | null>(null);
  public readonly authenticatedUserName = this.authenticatedUserNameSubject.asObservable();

  private codeFlowInProgressSubject = new BehaviorSubject(false);
  public readonly codeFlowInProgress = this.codeFlowInProgressSubject.asObservable();

  private rolesVerifiedSubject = new BehaviorSubject(false);
  public readonly rolesVerified = this.rolesVerifiedSubject.asObservable();

  constructor(
    private oauthService: OAuthService,
    private userService: UserService,
  ) {
    this.oauthService.configure(config);
  }

  public init(): void {
    this.attachEventHandlers();

    if (this.checkCodeFlowInProgress()) {
      this.codeFlowInProgressSubject.next(true);
    }

    // If current user is not authenticated it will look up the
    // SSO config and automatically initiate the auth code flow.
    this.oauthService
      .loadDiscoveryDocumentAndLogin()
      .then((success) => {
        this.codeFlowInProgressSubject.next(false);
        this.isAuthenticatedSubject.next(success);

        if (!success) {
          return;
        }

        this.setAccountName();
        this.verifyRoles();
      });

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public logout(): void {
    this.oauthService.logOut({
      client_id: this.oauthService.clientId,
      logout_uri: this.oauthService.postLogoutRedirectUri,
    });
  }

  public revokeAuthorization() {
    this.isAuthorizedSubject.next(false);
  }

  public hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  private verifyRoles(): void {
    const requiredRoles = environment.authorization.requiredRoles ?? [];

    this.userService
      .fetchRoles()
      .pipe(
        finalize(() => this.rolesVerifiedSubject.next(true)),
      )
      .subscribe((roles: string[]) => {
        const hasRequiredRoles = requiredRoles.filter(role => roles.includes(role));
        this.isAuthorizedSubject.next(hasRequiredRoles.length === requiredRoles.length);
      });
  }

  private checkCodeFlowInProgress(): boolean {
    const helper = new UrlHelperService();
    const isQuery = window.location.search.charAt(0) === '?';

    if (!isQuery) {
      return false;
    }
    const queryString = window.location.search.substring(1);
    const { state, code } = helper.parseQueryString(queryString) as CodeFlowParams;

    return state !== undefined && code !== undefined;
  }

  private setAccountName() {
    const claims = this.oauthService.getIdentityClaims() as IdentityClaims;
    this.authenticatedUserNameSubject.next(claims.name);
  }

  private attachEventHandlers(): void {
    this.oauthService.events.subscribe(event => {
      if (event.type === 'token_refresh_error' || event.type === 'invalid_nonce_in_state') {
        this.logout();
      }

      if (isDevMode()) {
        console.log(event);
      }
    });
  }
}

