<div class="tab-content-wrapper">
  <div class="row">
    <div class="col-md-6 article-details">
      <div class="row">
        <div class="col-md-6 form-label">Title</div>
        <div class="col-md-6 form-text">{{ productData.title }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Description</div>
        <div class="col-md-6 form-text">
          {{ productData.description || "-" }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Product code</div>
        <div class="col-md-6 form-text">{{ productData.productCode }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">RIP code</div>
        <div class="col-md-6 form-text">{{ productData.ripCode || "-" }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Brand</div>
        <div class="col-md-6 form-text">{{ productData.brandName }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Primary category</div>
        <div class="col-md-6 form-text">
          {{ productData.primaryCategoryLabel }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Secondary Category</div>
        <div class="col-md-6 form-text">
          {{ productData.secondaryCategoryLabel }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">V40 enabled</div>
        <div class="col-md-6 form-text">
          {{ productData.v40Enabled ? "Yes" : "No" }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">V100 enabled</div>
        <div class="col-md-6 form-text">
          {{ productData.v100Enabled ? "Yes" : "No" }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">HTHS enabled</div>
        <div class="col-md-6 form-text">
          {{ productData.hthsEnabled ? "Yes" : "No" }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">CCS enabled</div>
        <div class="col-md-6 form-text">
          {{ productData.ccsEnabled ? "Yes" : "No" }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Technical data sheet</div>
        <div class="col-md-6 form-text">
          <span *ngIf="tds; else noTds">
            <div class="url">
              <a href="{{ tds.href }}" target="_blank">Download</a>
            </div>
          </span>
          <ng-template #noTds> Not present </ng-template>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Safety data sheet</div>
        <div class="col-md-6 form-text">
          <span *ngIf="sds; else noSds">
            <div class="url">
              <a href="{{ sds.href }}" target="_blank">Download</a>
            </div>
          </span>
          <ng-template #noSds> Not present </ng-template>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Created</div>
        <div class="col-md-6 form-text">
          {{ productData.createdAt | date : "dd MMM yyyy hh:mm" }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Updated</div>
        <div class="col-md-6 form-text">
          {{ productData.updatedAt | date : "dd MMM yyyy hh:mm" }}
        </div>
      </div>
    </div>
  </div>
</div>
