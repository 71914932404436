import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataSavedEvent, OrganizationData } from '../../organization.model';
import { NotificationService } from '../../../shared/service/notification.service';
import { OrganizationService } from '../../organization.service';
import { finalize } from 'rxjs/operators';
import { FormHelper } from '../../../shared/mixin/form-helper';

@Component({
  selector: 'app-organization-settings',
  templateUrl: './organization-settings.component.html',
  styleUrls: ['./organization-settings.component.scss']
})
export class OrganizationSettingsComponent extends FormHelper() implements OnInit {
  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public showElubLogin: boolean = false;

  @Input() organizationData!: OrganizationData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private organizationService: OrganizationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.createForm();

    if (this.organizationData.activateElub) {
      this.showElubLogin = true;
    }

    this.form.valueChanges.subscribe((value) => {
        this.showElubLogin = value.activateElub;
    });
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    if (this.form.value.activateElub && this.form.value.elubLogin === null) {
      this.notificationService.error_ts('organization.settings.elub_no_credentials_provided');

      return;
    }

    this.isSaving = true;

    this.organizationService
      .updateSettings(this.organizationData.id, this.form.value)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('organization.settings.updated');
          this.dataSaved.emit({ organizationId: this.organizationData.id });
        }
      });
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({
      activateSap: this.fb.control(this.organizationData.activateSap),
      activateExternalSoftware: this.fb.control(this.organizationData.activateExternalSoftware),
      activateTotal24: this.fb.control(this.organizationData.activateTotal24),
      activateTrackAndTrace: this.fb.control(this.organizationData.activateTrackAndTrace),
      activateElub: this.fb.control(this.organizationData.activateElub),
      elubLogin: this.fb.control(this.organizationData.elubLogin),
      activateTelemetry: this.fb.control(this.organizationData.activateTelemetry),
      activateTelemetryTabs: this.fb.control(this.organizationData.activateTelemetryTabs),
      activateAnac: this.fb.control(this.organizationData.activateAnac),
      activateBrekupaProducts: this.fb.control(this.organizationData.activateBrekupaProducts),
      activateSumisConnection: this.fb.control(this.organizationData.activateSumisConnection),
      alternativeBackOfficeEmail: this.fb.control(
        this.organizationData.alternativeBackOfficeEmail,
        Validators.email
      ),
      sapCodeAlternativeBackOffice: this.fb.control(
        this.organizationData.sapCodeAlternativeBackOffice?.toString(),
        Validators.pattern(/^\d+$/)
      ),
    });
  }
}
