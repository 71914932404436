import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BrochureService } from '../brochure.service';
import { BrochureData } from '../brochure.model';

@Component({
  selector: 'app-brochure-details',
  templateUrl: './brochure-details.component.html',
  styleUrls: ['./brochure-details.component.scss']
})
export class BrochureDetailsComponent implements OnInit {

  public brochureId!: string;
  public brochureData: Observable<BrochureData>;
  public isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    public brochureService: BrochureService,
  ) {
    this.brochureData = new Observable<BrochureData>();
  }

  ngOnInit(): void {
    this.brochureId = this.route.snapshot.paramMap.get('id')!;
    this.brochureData = this.brochureService
      .find(this.brochureId)
      .pipe(
        finalize( () => this.isLoading = false),
      );
  }
}
