import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateNewsData, ImageType, NewsCollectionResponse, NewsData, UpdateNewsData } from './news.model';
import { ApiService } from '../shared/base/api-service';
import EmptyResponse from '../shared/model/misc';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewsService extends ApiService {

  private restApiUrl = this.apiUrl('admin/news');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public find(id: string): Observable<NewsData> {
    return this.httpClient.get<NewsData>(this.restApiUrl + '/' + id)
      .pipe(
        map((newsData: NewsData): NewsData => {
          // TODO this should be done in the back-end
          if (newsData._links && newsData._links.headerImg) {
            newsData._links.headerImg.href = newsData._links.headerImg.href.replace(
              environment.apiUrl,
              environment.apiPublicUrl,
            );
          }
          // TODO this should be done in the back-end
          if (newsData._links && newsData._links.summaryImg) {
            newsData._links.summaryImg.href = newsData._links.summaryImg.href.replace(
              environment.apiUrl,
              environment.apiPublicUrl,
            );
          }

          // Return fixed object
          return newsData;
        }),
      );
  }

  public findPaginated(
    page: number = 1,
    pageSize: number = 25,
    sortBy: string = 'name',
    sortDirection: string = 'desc',
    filter: string = '',
  ): Observable<NewsCollectionResponse> {
    const queryParams = new HttpParams()
      .append('page', page.toString())
      .append('page_size', pageSize.toString())
      .append('sort_by', sortBy)
      .append('sort_direction', sortDirection)
      .append('filter', filter.trim());

    return this.httpClient
      .get<NewsCollectionResponse>(this.restApiUrl, { params: queryParams })
  }

  public create(payload: CreateNewsData): Observable<EmptyResponse> {
    return this.httpClient
      .post<EmptyResponse>(this.restApiUrl, payload)
  }

  public update(itemId: string, payload: UpdateNewsData): Observable<EmptyResponse> {
    return this.httpClient
      .put<EmptyResponse>(this.restApiUrl + '/' + itemId, payload)
  }

  public delete(itemId: string) {
    return this.httpClient.delete<EmptyResponse>(this.restApiUrl + '/' + itemId)
  }

  public uploadImage(itemId: string, file: File | null, type: string) {
    if (![ImageType.HEADER, ImageType.SUMMARY].includes(type)) {
      throw new Error(`Invalid sheet type: ${type}`)
    }

    const payload: FormData = new FormData();
    payload.append('image', file ?? '');
    payload.append('type', type);

    return this.httpClient
      .put<EmptyResponse>(this.restApiUrl + '/' + itemId + '/image', payload);
  }
}
