import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountCollectionResponse, AccountData, UpdateAccountData } from './account.model';
import { ApiService } from '../shared/base/api-service';
import EmptyResponse from '../shared/model/misc';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends ApiService {

  private restApiUrl = this.apiUrl('admin/accounts');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public find(id: string): Observable<AccountData> {
    return this.httpClient.get<AccountData>(this.restApiUrl + '/' + id)
  }

  public findPaginated(
    page: number = 1,
    pageSize: number = 25,
    sortBy: string = 'name',
    sortDirection: string = 'desc',
    filter: string = '',
  ): Observable<AccountCollectionResponse> {
    const queryParams = new HttpParams()
      .append('page', page.toString())
      .append('page_size', pageSize.toString())
      .append('sort_by', sortBy)
      .append('sort_direction', sortDirection)
      .append('filter', filter.trim());

    return this.httpClient
      .get<AccountCollectionResponse>(this.restApiUrl, {params: queryParams})
  }

  public update(accountId: string, payload: UpdateAccountData): Observable<EmptyResponse> {
    return this.httpClient
      .put<EmptyResponse>(this.restApiUrl + '/' + accountId, payload)
  }

  public delete(accountId: string) {
    return this.httpClient.delete<EmptyResponse>(this.restApiUrl + '/' + accountId)
  }

  public triggerAccountReadyNotification(accountId: string) {
    return this.httpClient
      .post<EmptyResponse>(this.restApiUrl + '/' + accountId + '/send-account-ready-notification', {})
  }
}
