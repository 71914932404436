import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NotificationService } from '../../shared/service/notification.service';
import { ValidationErrors } from '../telemetry-settings-import.model';
import { FormHelper } from '../../shared/mixin/form-helper';
import { finalize } from 'rxjs/operators';
import { HttpStatusCode } from '@angular/common/http';
import { TelemetrySettingsImportService } from "../telemetry-settings-import.service";
import { TemplateService } from "../../shared/service/template.service";
import { downloadBlob } from "../../shared/utils/blob-download";
import { FILE_TYPE_XLSX } from "../../shared/misc/file-type";

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent extends FormHelper() {
  private static readonly fileAttrDefault = 'Select file';

  public form!: UntypedFormGroup;
  public fileAttr = UploadFormComponent.fileAttrDefault;
  public fileSelected!: File | null;

  public isLoading: boolean = true;
  public isSaving: boolean = false;
  public validationErrors!: ValidationErrors | null;

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(
    private articleStockImportService: TelemetrySettingsImportService,
    private notificationService: NotificationService,
    private templateService: TemplateService
  ) {
    super();
    this.validationErrors = null;
  }
  public onSubmit() {
    if (!this.fileSelected) {
      this.notificationService.error_ts('order_import.no_file_selected');

      return;
    }

    this.isSaving = true;
    this.articleStockImportService
      .uploadFile(this.fileSelected)
      .pipe(
        finalize(() => this.isSaving = false)
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('telemetry-settings-import.upload_success');
          this.onReset();
        },
        error: (response: { status: HttpStatusCode; error: ValidationErrors | null; }) => {
          // The 422 error is used to show import validation errors.
          // Any other error will be and handled by the global error handler.
          if (response.status !== HttpStatusCode.UnprocessableEntity) {
            throw response;
          }

          this.validationErrors = response.error
        }
      });
  }

  public onSelectFile(event: Event): void {
    const files = (event.target as HTMLInputElement).files;

    if (!files) {
      return;
    }

    this.fileAttr = files[0].name;
    this.fileSelected = files[0];
  }

  public onReset(): void {
    this.fileInput.nativeElement.value = '';
    this.fileSelected = null;
    this.fileAttr = UploadFormComponent.fileAttrDefault
    this.validationErrors = null;
  }

  public downloadTemplate(): void {
    this.templateService
      .retrieve('telemetry_settings_import')
      .subscribe((data) => downloadBlob(data, 'telemetry-settings.xlsx', FILE_TYPE_XLSX));
  }
}
