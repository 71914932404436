import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../shared/base/api-service';
import EmptyResponse from '../shared/model/misc';
import { DeliveryNotification, UpsertDeliveryNotificationData } from './delivery-notification.model';

@Injectable({
  providedIn: 'root'
})
export class DeliveryNotificationService extends ApiService {
  private restApiUrl = this.apiUrl('admin/delivery-notification');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public find(): Observable<DeliveryNotification> {
    return this.httpClient
      .get<DeliveryNotification>(this.restApiUrl)
  }

  public upsert(payload: UpsertDeliveryNotificationData): Observable<EmptyResponse> {
    return this.httpClient
      .post<EmptyResponse>(this.restApiUrl, payload)
  }
}
