export const environment = {
  production: true,
  apiUrl: 'https://u2m.api.tlspreprod.alzp.tgscloud.net',
  apiPublicUrl: 'https://public.tlspreprod.alzp.tgscloud.net',
  oauth: {
    idp: 'AadLiftPP',
    clientId: '73slmm4ij6kv6ngclcp4p7do8d',
    issuer: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_OMvTre75X',
    redirectUri: 'https://smeermiddelen-shopmanagertest123.totalenergies.nl',
    postLogoutRedirectUri: 'https://smeermiddelen-shopmanagertest123.totalenergies.nl',
    waitForTokenMsec: 2000,
    scope: 'openid profile email',
    requireHttps: false,
    showDebugInformation: true,
    strictDiscoveryDocumentValidation: false,
    // Setting below will automatically add token to headers
    // (make sure to set the proper URL for the API backend)
    resourceServer: {
      allowedUrls: [
        'https://u2m.api.tlspreprod.alzp.tgscloud.net',
        'https://public.tlspreprod.alzp.tgscloud.net',
      ],
      sendAccessToken: true,
    },
  },
  authorization: {
    requiredRoles: ['shop_admin'],
  },
  errorHandling: {
    httpIgnoreKeys: ['trace', 'details', 'exception_stack'],
  },
  translation: {
    defaultLanguage: 'en',
    fallbackLanguage: 'en',
  },
  tls: {
    roleOptions: ['shop_user', 'shop_admin', 'shop_reseller'],
    organization: {
      countryOptions: [
        { id: 'NL', name: 'Netherlands' },
        { id: 'BE', name: 'Belgium' },
        { id: 'CZ', name: 'Czech' },
        { id: 'FR', name: 'France' },
        { id: 'GB', name: 'United Kingdom' },
        { id: 'DE', name: 'Germany' },
        { id: 'LV', name: 'Latvia' },
      ],
    },
    telemetry: {
      oilTypes: [
        { id: 0, name: 'Fresh oil' },
        { id: 1, name: 'Waste oil' },
        { id: 2, name: 'Diesel' },
      ],
      tankTypes: [
        { id: 0, name: 'Drum' },
        { id: 1, name: 'Bulk' },
      ],
    },
  },
};
