<a routerLink="/">Home</a> / Import History <br /><br />
<div class="row page-header">
  <div class="col title">
    <h1>Import History</h1>
  </div>
</div>
<div class="grid-controls">
  <div class="search">
    <mat-form-field class="w-100 no-subscript" appearance="outline">
      <mat-label>Search by user of filename</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="searchText"
        (keyup)="onSearch()"
      />
      <button
        *ngIf="searchText"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="onSearchClear()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="table-wrapper mat-elevation-z3">
      <div class="table-progress-overlay" *ngIf="dataSource.loading | async">
        <mat-progress-spinner mode="indeterminate" diameter="50">
        </mat-progress-spinner>
      </div>
      <table
        [dataSource]="dataSource"
        mat-table
        matSort
        [matSortActive]="sortActiveDefault"
        [matSortDirection]="sortDirectionDefault"
        matSortDisableClear
      >
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created at</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/import-history/', element.id]"
              >{{ element.createdAt | date : "dd MMM yyyy hh:mm" }}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Created by user
          </th>
          <td mat-cell *matCellDef="let element">{{ element.createdBy }}</td>
        </ng-container>
        <ng-container matColumnDef="context">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let element">{{ element.context }}</td>
        </ng-container>
        <ng-container matColumnDef="filename">
          <th mat-header-cell *matHeaderCellDef>File</th>
          <td mat-cell *matCellDef="let element">{{ element.filename }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td
            mat-cell
            *matCellDef="let element"
            [class]="statusClass(element.status)"
          >
            {{ element.status }}
          </td>
        </ng-container>
        <ng-container matColumnDef="rejected">
          <th
            mat-header-cell
            class="mat-header-rejected"
            *matHeaderCellDef
            mat-sort-header
          >
            Rejected
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [class]="{ 'has-errors': element.numErrors > 0 }"
          >
            {{ element.numErrors }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-data" [attr.colspan]="tableColumns.length">
            No data
          </td>
        </tr>
      </table>
      <mat-paginator
        [length]="totalItems"
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>
