import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from '../../../shared/service/notification.service';
import { ArticleData, DataSavedEvent } from '../../article.model';
import { ArticleService } from '../../article.services';

@Component({
  selector: 'app-article-packshot',
  templateUrl: './article-packshot.component.html',
  styleUrls: ['./article-packshot.component.scss']
})
export class ArticlePackshotComponent {

  public fileAttr = 'Select file';
  public showDeleteIcon = false;

  @Input() articleData!: ArticleData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  constructor(
    private articleService: ArticleService,
    private notificationService: NotificationService
  ) {
  }

  get packShot() {
    return this.articleData._links?.packShot
  }

  onSelect(event: Event): void {
    const files = (event.target as HTMLInputElement).files;

    if (!files) {
      return;
    }

    this.fileAttr = files[0].name;

    this.articleService
      .uploadPackShot(this.articleData.id, files[0])
      .subscribe((next) => {
        this.dataSaved.emit({ articleId: this.articleData.id });
        this.notificationService.success_ts('article.logo.uploaded');
      })
  }

  onRemove(): void {
    this.articleService
      .uploadPackShot(this.articleData.id, null)
      .subscribe((next) => {
        this.dataSaved.emit({ articleId: this.articleData.id });
        this.notificationService.success_ts('article.logo.removed');
      })
  }
}
