import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountData } from '../../account.model';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {
  public accountId!: string;
  public isLoading: boolean = true;

  @Input() accountData!: AccountData;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.paramMap.get('id')!;
  }
}
