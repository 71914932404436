import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/service/notification.service';
import { finalize } from 'rxjs/operators';
import { FormHelper } from '../../shared/mixin/form-helper';
import { PRODUCT_CODE } from '../../shared/misc/regex';
import { EquivalentService } from '../equivalent.service';

@Component({
  selector: 'app-create-equivalent-form',
  templateUrl: './create-equivalent-form.component.html',
  styleUrls: ['./create-equivalent-form.component.scss']
})
export class CreateEquivalentFormComponent extends FormHelper() implements OnInit {

  public form!: UntypedFormGroup;
  public isSaving: boolean = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private equivalentService: EquivalentService,
    private notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.createForm();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;

    this.equivalentService
      .create(this.form.value)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('equivalent.created');
          this.router.navigateByUrl('/equivalents');
        }
      });
  }

  private createForm() {
    return this.fb.group({
      type: this.fb.control('', Validators.required),
      code: this.fb.control('', [
        Validators.required,
        Validators.pattern(PRODUCT_CODE)
      ]),
      newCode: this.fb.control('', [
        Validators.required,
        Validators.pattern(PRODUCT_CODE)
      ]),
    });
  }
}
