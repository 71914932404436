import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArticleData, DataSavedEvent } from '../../article.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, switchMap } from 'rxjs';
import { OrganizationOption } from '../../../order-import/order-import.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleService } from '../../article.services';
import { NotificationService } from '../../../shared/service/notification.service';
import { filter, finalize, tap } from 'rxjs/operators';
import { FormHelper } from '../../../shared/mixin/form-helper';

@Component({
  selector: 'app-update-article-form',
  templateUrl: './update-article-form.component.html',
  styleUrls: ['./update-article-form.component.scss']
})
export class UpdateArticleFormComponent extends FormHelper() implements OnInit {

  @Input() articleData!: ArticleData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public productOptions: Observable<OrganizationOption[]>;
  public productOptionsMap: { [key: string]: string };
  public productOptionsLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private articleService: ArticleService,
    private notificationService: NotificationService
  ) {
    super();
    this.productOptions = new Observable();
    this.productOptionsMap = {};
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.setupProductInputListener();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;

    this.articleService
      .update(this.articleData.id, this.form.value)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('article.updated');
          this.dataSaved.emit({ articleId: this.articleData.id });
        }
      });
  }

  public onSelectProductDisplayValue(productId: string | null): string {
    return productId && this.productOptionsMap[productId] || '';
  }


  private createForm() {
    // Necessary for the display value of the product input.
    this.productOptionsMap[this.articleData.productId] =
      `${this.articleData.productTitle}`;

    return this.fb.group({
      title: this.fb.control(this.articleData.title, [
        Validators.required,
        Validators.minLength(2)
      ]),
      description: this.fb.control(this.articleData.description),
      productId: this.fb.control(this.articleData.productId, Validators.required),
      sapCode: this.fb.control(this.articleData.sapCode, Validators.required),
      packaging: this.fb.control(this.articleData.packaging, Validators.required),
      per: this.fb.control(this.articleData.per, Validators.required),
      lkg: this.fb.control(this.articleData.lkg, Validators.required),
      grossPrice: this.fb.control(this.articleData.grossPrice, Validators.required),
      suggestedRetailPrice: this.fb.control(this.articleData.suggestedRetailPrice),
      quantity: this.fb.control(this.articleData.quantity, Validators.required),
      content: this.fb.control(this.articleData.content, Validators.required),
      minimumOrderQuantity: new UntypedFormControl(this.articleData.minimumOrderQuantity),
      total24Code: this.fb.control(this.articleData.total24Code),
      activeProduct: this.fb.control(this.articleData.activeProduct),
      activateForSap: this.fb.control(this.articleData.activateForSap),
      activateForTotal24: this.fb.control(this.articleData.activateForTotal24),
      activateForSumis: this.fb.control(this.articleData.activateForSumis),
    });
  }

  private setupProductInputListener() {
    const ctrl = this.form.get('productId');

    this.productOptions = ctrl!.valueChanges
      .pipe(
        // Because we use [value] for the mat-autocomplete option, it will set the
        // input value to the mat-option [value] on selection which fires a value change.
        // This is a change we don't want to trigger a lookup for, hence the workaround
        // below which checks if the input value is found in the lookup map (which means
        // an ID was set instead of capturing user input), if yes, we cancel the operation.
        filter((value) => this.productOptionsMap[value] === undefined),
        switchMap((value: string) => {
          this.productOptionsLoading = true;

          return this.articleService
            .getProductOptions(value)
            .pipe(
              finalize(() => this.productOptionsLoading = false)
            )}),
        // Create lookup map for organization options (to make [displayWith] work properly)
        tap((values) => {
          this.productOptionsMap = {};

          values.forEach((option) => {
            this.productOptionsMap[option.id] = option.name;
          });
        })
      );
  }
}
