<a routerLink="/">Home</a> / <a routerLink="/account-managers">Account Managers</a> / Create
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/account-managers"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>New account manager</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar>person</mat-icon>
        <mat-card-title>New</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col form-wrapper">
            <form [formGroup]="form">
              <mat-form-field appearance="fill">
                <mat-label>Name</mat-label>
                <input type="text" matInput formControlName="name">
                <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
              </mat-form-field>
            </form>
          </div>
        </div>
        <br/>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button
          mat-flat-button color="primary"
          class="button-save"
          (click)="onSubmit()"
        >
          <div class="d-flex">
            Save
            <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
          </div>
        </button>
        <button
          mat-flat-button color="secondary"
          routerLink="/account-managers"
        >
          Cancel
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
