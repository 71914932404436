import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { ProductRoutingModule } from './product-routing.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ProductOverviewComponent } from './product-overview/product-overview.component';
import { SharedModule } from '../shared/shared.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RemoveProductDialogComponent } from './remove-product-dialog/remove-product-dialog.component';
import { CreateProductFormComponent } from './create-product-form/create-product-form.component';
import { ManageProductComponent } from './manage-product/manage-product.component';
import { ProductDetailsComponent } from './manage-product/product-details/product-details.component';
import { UpdateProductFormComponent } from './manage-product/update-product-form/update-product-form.component';
import { ProductDataSheetsComponent } from './manage-product/product-data-sheets/product-data-sheets.component';
import { RemoveDialogComponent } from './manage-product/product-data-sheets/remove-dialog/remove-dialog.component';

@NgModule({
  imports: [
    ProductRoutingModule,
    MatTableModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatListModule,
    MatDialogModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatTabsModule,
    MatToolbarModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    SharedModule
  ],
  declarations: [
    ProductOverviewComponent,
    RemoveProductDialogComponent,
    CreateProductFormComponent,
    ManageProductComponent,
    ProductDetailsComponent,
    UpdateProductFormComponent,
    ProductDataSheetsComponent,
    RemoveDialogComponent
  ],
})
export class ProductModule { }
