import { Component, Input } from '@angular/core';
import { ProductData } from '../../product.model';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent {

  @Input() productData!: ProductData;

  constructor() { }

  get tds() {
    return this.productData._links?.tds
  }

  get sds() {
    return this.productData._links?.sds
  }
}
