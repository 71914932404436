import { Component, OnInit } from '@angular/core';
import { catchError, finalize } from 'rxjs/operators';
import { BlockedDatesService } from '../blocked-delivery-dates.services';
import { BlockedDate } from '../blocked-delivery-dates.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RemoveDateDialogComponent } from '../remove-date-dialog/remove-date-dialog.component';
import { NotificationService } from '../../shared/service/notification.service';
import { dateUtils } from '../../shared/utils/date-time';
import { FormHelper } from '../../shared/mixin/form-helper';

@Component({
  selector: 'app-blocked-dates-overview',
  templateUrl: './blocked-dates-overview.component.html',
  styleUrls: ['./blocked-dates-overview.component.scss']
})
export class BlockedDatesOverviewComponent extends FormHelper() implements OnInit {

  public form!: UntypedFormGroup;
  public blockedDates: BlockedDate[];
  public isLoading: boolean = true;
  public isSaving: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private blockedDatesService: BlockedDatesService,
    private notificationService: NotificationService
  ) {
    super();
    this.blockedDates = [];
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      date: this.fb.control('', Validators.required),
    });

    this.loadData();
  }

  public onRemove(id: string, name: string): void {
    if (this.dialog.openDialogs.length) {
      return;
    }

    this.dialog
      .open(RemoveDateDialogComponent, {
        data: {
          id: id,
          date: name,
        },
      })
      .afterClosed()
      .subscribe(
        data => {
          if (data?.id) {
            this.deleteDate(data.id);
          }
        }
      );
  }

  public onSubmit() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    const payload = {
      date: dateUtils.toCalendar.fromDate(this.form.value.date)
    }

    this.blockedDatesService
      .create(payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        }),
        catchError(error => {
          this.resetInput();

          throw error;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('blocked_date.added');
          this.resetInput();
          this.loadData();
        }
      });
  }

  private resetInput(): void {
    const input = this.form.get('date');

    if (!input) {
      return;
    }

    input.reset();
    input.setErrors(null);
  }

  private loadData() {
    this.blockedDatesService
      .findAll()
      .pipe(
        finalize( () => this.isLoading = false),
      )
      .subscribe((response) => {
        this.blockedDates = response._embedded.blocked_delivery_date;
      });
  }

  private deleteDate(id: string) {
    this.blockedDatesService
      .delete(id)
      .subscribe({
        next: () => {
          this.notificationService.success_ts('blocked_date.removed');
          this.loadData();
        }
      })
  }
}
