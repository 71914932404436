import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganizationService } from '../organization.service';
import { DataSavedEvent, OrganizationData } from '../organization.model';
import { finalize } from 'rxjs/operators';
import { CardWithTabs } from '../../shared/mixin/card-with-tabs';

@Component({
  selector: 'app-manage-organization',
  templateUrl: '/manage-organization.component.html',
  styleUrls: ['/manage-organization.component.scss']
})
export class ManageOrganizationComponent extends CardWithTabs() implements OnInit {

  public organizationId!: string;
  public organizationData!: OrganizationData;
  public currentTab: string = 'details';
  public isLoading: boolean = true;

  @ViewChild('cardContent') cardContent!: ElementRef<HTMLInputElement>;

  constructor(
    public organizationService: OrganizationService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.organizationId = this.route.snapshot.paramMap.get('id')!;

    // Used for reloading organization data when changing the
    // ID url segment. Also loads initial organization data.
    this.route.params.subscribe(params => {
      this.loadOrganizationData(params.id);
    });
  }

  public onDataSaved(event: DataSavedEvent) {
    this.loadOrganizationData(event.organizationId);
  }

  private loadOrganizationData(organizationId: string) {
    this.isLoading = true;

    this.organizationService
      .find(organizationId)
      .pipe(
        finalize(() => this.isLoading = false)
      ).
      subscribe(data => {
        this.organizationData = data;
      });
  }
}
