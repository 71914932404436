import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AuthenticationService } from './core/service/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public static SIDE_NAV_OPENED_BREAKPOINT: number = 768;
  public sideNavOpened: boolean = true;
  public authUserName: string | null = null;

  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;

  constructor(
    private translateService: TranslateService,
    public authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.toggleNavBar(window.innerWidth);
    this.translateService.setDefaultLang(environment.translation.defaultLanguage);
    this.translateService.use(environment.translation.defaultLanguage);

    this.authenticationService.init();

    this.authenticationService
      .authenticatedUserName
      .subscribe((value) => {
        this.authUserName = value;
    })
  }

  logout() {
    this.authenticationService.logout();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent): void {
    this.toggleNavBar((event.target! as Window).innerWidth);
  }

  private toggleNavBar(screenWidth: number) {
    this.sideNavOpened = screenWidth >= AppComponent.SIDE_NAV_OPENED_BREAKPOINT;
  }
}
