<a routerLink="/">Home</a> / <a routerLink="/market-profiles">Market Profiles</a> / Edit
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/market-profiles/{{ marketProfileId }}"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>Edit Market Profile</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class=""></mat-progress-bar>
    <mat-card>
      <div *ngIf="marketProfileData | async; let data">
        <mat-card-header>
          <mat-icon mat-card-avatar>assignment</mat-icon>
          <mat-card-title>{{ data.name }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col form-wrapper">
              <form [formGroup]="form">
                <mat-form-field appearance="fill">
                  <mat-label>Name</mat-label>
                  <input type="text" matInput formControlName="name">
                  <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
                  <mat-error *ngIf="hasError('name', 'maxlength')">Name can have maximum length 50 characters</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Product codes</mat-label>
                  <textarea
                    formControlName="productCodes"
                    name="productCodes"
                    placeholder="Insert product codes here .."
                    rows="7"
                    matInput
                  >
                  </textarea>
                  <mat-error *ngIf="hasError('productCodes', 'required')">Product code is required</mat-error>
                  <mat-error *ngIf="hasError('productCodes', 'pattern')">Invalid format. Allowed: alpha-numeric characters [A-Z0-9] separated by a comma</mat-error>
                </mat-form-field>
                <div class="form-checkbox">
                  <mat-checkbox formControlName="isAvailableForGuest">
                    Available for guest
                  </mat-checkbox>
                </div>
                <div class="form-checkbox">
                  <mat-checkbox formControlName="isAvailableForRetail">
                    Available for retail
                  </mat-checkbox>
                </div>
              </form>
            </div>
          </div>
          <br/>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions>
          <button
            mat-flat-button color="primary"
            class="button-save"
            (click)="onSubmit()"
          >
            <div class="d-flex">
              Save
              <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
            </div>
          </button>
          <button
            mat-flat-button color="secondary"
            routerLink="/market-profiles"
          >
            Cancel
          </button>
        </mat-card-actions>
      </div>
    </mat-card>
  </div>
</div>
