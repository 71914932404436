import { Component, Input } from '@angular/core';
import { OrganizationAddress, OrganizationData } from '../../organization.model';

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss']
})
export class OrganizationDetailsComponent {

  @Input() organizationData!: OrganizationData;

  constructor() { }

  get businessAddress(): OrganizationAddress | undefined {
    return this.organizationData._embedded?.businessAddress;
  }
}
