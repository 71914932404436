import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { BrochureRoutingModule } from './brochure-routing.module';
import { BrochureOverviewComponent } from './brochure-overview/brochure-overview.component';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from '../shared/shared.module';
import { RemoveBrochureDialogComponent } from './remove-brochure-dialog/remove-brochure-dialog.component';
import { CreateBrochureFormComponent } from './create-brochure-form/create-brochure-form.component';
import { BrochureDetailsComponent } from './brochure-details/brochure-details.component';
import { UpdateBrochureFormComponent } from './update-brochure-form/update-brochure-form.component';

@NgModule({
  imports: [
    BrochureRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatListModule,
    MatDialogModule,
    MatDividerModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatTabsModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    SharedModule
  ],
  declarations: [
    BrochureOverviewComponent,
    RemoveBrochureDialogComponent,
    CreateBrochureFormComponent,
    BrochureDetailsComponent,
    UpdateBrochureFormComponent
  ],
})
export class BrochureModule { }
