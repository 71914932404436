import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { debounceTime, merge, Subject } from 'rxjs';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MarketProfileDataSource } from '../market-profile.data-source';
import { MatPaginator } from '@angular/material/paginator';
import { MarketProfileService } from '../market-profile.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../shared/service/notification.service';
import {
  RemoveMarketProfileDialogComponent,
} from '../remove-market-profile-dialog/remove-market-profile-dialog.component';

@Component({
  selector: 'app-marketprofile-overview',
  templateUrl: './market-profile-overview.component.html',
  styleUrls: ['./market-profile-overview.component.scss']
})
export class MarketProfileOverviewComponent implements AfterViewInit, OnInit {

  private searchInputSubject = new Subject<string>();
  public searchInput = this.searchInputSubject.asObservable();
  public searchText = '';
  private searchEmpty = true;
  public tableColumns: string[] = [
    'name',
    'createdAt',
    'isAvailableForRetail',
    'isAvailableForGuest',
    'actions'
  ];

  public sortActiveDefault = 'createdAt';
  public sortDirectionDefault: SortDirection = 'asc';
  public pageSizeOptions: number[] = [15, 30, 50];
  public totalItems = 0;
  public dataSource: MarketProfileDataSource;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private marketProfileService: MarketProfileService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
    this.dataSource = new MarketProfileDataSource(marketProfileService);

    this.dataSource.dataLength.subscribe((total) => {
      this.totalItems = total
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit() {
    // Reset pagination after sort change.
    this.sort.sortChange
      .subscribe(() => this.paginator.pageIndex = 0);

    // Load data when a sort or paginator event is emitted.
    merge(this.sort.sortChange, this.paginator.page)
      .subscribe(() => this.loadData());

    // Load data when search input value changes.
    this.searchInput
      .pipe(
        debounceTime(150)
      )
      .subscribe((value) => {
        if (!this.searchEmpty || value.length) {
          this.paginator.pageIndex = 0;
          this.loadData()
        }

        this.searchEmpty = value.length === 0;
      });
  }

  public onSearch(): void {
    this.searchInputSubject.next(this.searchText);
  }

  public onSearchClear(): void {
    this.searchText = '';
    this.onSearch();
  }

  public onRemoveAction(id: string, name: string): void {
    if (this.dialog.openDialogs.length) {
      return;
    }

    this.dialog
      .open(RemoveMarketProfileDialogComponent, {
        data: {
          id: id,
          name: name,
        },
      })
      .afterClosed()
      .subscribe(
        data => {
          if (data?.id) {
            this.deleteProfile(data.id);
          }
        }
      );
  }

  private deleteProfile(profileId: string): void {
    this.marketProfileService
      .delete(profileId)
      .subscribe({
        next: () => {
          this.notificationService.success_ts('market_profile.deleted');
          this.loadData();
        }
      })
  }

  private loadData() {
    this.dataSource.loadMarketProfiles(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || this.pageSizeOptions[0],
      this.sort?.active || this.sortActiveDefault,
      this.sort?.direction || this.sortDirectionDefault,
      this.searchText
    );
  }
}
