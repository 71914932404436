import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleOverviewComponent } from './article-overview/article-overview.component';
import { CreateArticleFormComponent } from './create-article-form/create-article-form.component';
import { ManageArticleComponent } from './manage-article/manage-article.component';

const routes: Routes = [
  {
    path: 'articles',
    component: ArticleOverviewComponent
  },
  {
    path: 'articles/create',
    component: CreateArticleFormComponent
  },
  {
    path: 'articles/:id',
    component: ManageArticleComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArticleRoutingModule { }
