import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateEquivalentData, EquivalentsCollectionResponse, UpdateEquivalentData } from './equivalent.model';
import { ApiService } from '../shared/base/api-service';
import EmptyResponse from '../shared/model/misc';

@Injectable({
  providedIn: 'root'
})
export class EquivalentService extends ApiService {

  private restApiUrl = this.apiUrl('admin/equivalent');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public findPaginated(
    page: number = 1,
    pageSize: number = 25,
    sortBy: string = 'name',
    sortDirection: string = 'desc',
    filter: string = '',
  ): Observable<EquivalentsCollectionResponse> {
    const queryParams = new HttpParams()
      .append('page', page.toString())
      .append('page_size', pageSize.toString())
      .append('sort_by', sortBy)
      .append('sort_direction', sortDirection)
      .append('filter', filter.trim());

    return this.httpClient
      .get<EquivalentsCollectionResponse>(this.restApiUrl, {params: queryParams})
  }

  public create(payload: CreateEquivalentData): Observable<EmptyResponse> {
    return this.httpClient
      .post<EmptyResponse>(this.restApiUrl, payload)
  }

  public updateOption(equivalentId: string, payload: UpdateEquivalentData): Observable<EmptyResponse> {
    return this.httpClient
      .patch<EmptyResponse>(this.restApiUrl + '/' + equivalentId, payload)
  }

  public delete(accountId: string) {
    return this.httpClient.delete<any>(this.restApiUrl + '/' + accountId)
  }
}

@Injectable({
  providedIn: 'root'
})
export class EquivalentUploadService extends ApiService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public uploadFile(type: string, file: File | null) {
    const payload: FormData = new FormData();
    payload.append('uploadType', type);
    payload.append('file', file ?? '');

    return this.httpClient
      .post<EmptyResponse>(this.apiUrl('admin/import-equivalent'), payload);
  }
}
