<div class="tab-content-wrapper">
  <div class="row mb-2">
    <div class="col form-wrapper">
      <div
        class="image-preview d-flex justify-content-center align-items-center"
        (mouseenter)="showDeleteIcon = true"
        (mouseleave)="showDeleteIcon = false"
      >
        <img [src]="packShot.href" *ngIf="packShot"/>
        <mat-icon
          *ngIf="packShot && showDeleteIcon"
          mat-list-icon
          class="button-delete float-end"
          (click)="onRemove()"
        >
          delete
        </mat-icon>
        <span *ngIf="!packShot" class="no-packshot">No packshot uploaded</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col form-wrapper">
      <mat-form-field class="image-input">
        <div>
          <mat-toolbar>
            <!-- Display filename -->
            <input matInput [(ngModel)]="fileAttr" readonly name="name" />
            <!-- Browse Button -->
            <button mat-flat-button color="primary">Browse File</button>
          </mat-toolbar>
          <input
            type="file"
            #fileInput
            id="uploadFile"
            (change)="onSelect($event)"
            name="uploadFile"
            multiple="multiple"
            accept="image/*"
          />
        </div>
      </mat-form-field>
      <span class="file-type-info">Supported file types: jpeg, png</span>
    </div>
  </div>
</div>
