import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AccountOption,
  CreateOrganizationAddressData,
  CreateOrganizationData,
  CreateTankSettingsData,
  MarketProfileOption,
  OrganizationCollectionResponse,
  OrganizationData,
  OrganizationTankOption,
  SegmentOption,
  TankModemOption,
  TankSettings,
  TelemetryArticleData,
  UpdateOrganizationAccessData,
  UpdateOrganizationAddressData,
  UpdateOrganizationGeneralData,
  UpdateOrganizationSalesConditionsData,
  UpdateOrganizationSettingsData,
  UpdateTankSettingsData,
} from './organization.model';
import { AccountManagerOptions } from '../account-manager/account-manager.model';
import { ApiService } from '../shared/base/api-service';
import EmptyResponse from '../shared/model/misc';
import { OrganizationOption } from '../order-import/order-import.model';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends ApiService {

  private restApiUrl = this.apiUrl('admin/organization');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public find(id: string): Observable<OrganizationData> {
    return this.httpClient.get<OrganizationData>(this.restApiUrl + '/' + id)
      .pipe(
        map((organisationData: OrganizationData): OrganizationData => {
          // TODO this should be done in the back-end
          if (organisationData?._links?.logo?.href) {
            organisationData._links.logo.href = organisationData._links.logo.href.replace(
              environment.apiUrl,
              environment.apiPublicUrl,
            );
          }
          return organisationData;
        }),
      );
  }

  public findPaginated(
    page: number = 1,
    pageSize: number = 25,
    sortBy: string = 'name',
    sortDirection: string = 'desc',
    filter: string = '',
  ): Observable<OrganizationCollectionResponse> {
    const queryParams = new HttpParams()
      .append('page', page.toString())
      .append('page_size', pageSize.toString())
      .append('sort_by', sortBy)
      .append('sort_direction', sortDirection)
      .append('filter', filter.trim());

    return this.httpClient
      .get<OrganizationCollectionResponse>(
        this.restApiUrl,
        { params: queryParams },
      )
  }

  public getOrganizationOptions(filter: string | null, parentOnly: boolean = false): Observable<OrganizationOption[]> {
    filter = filter ? filter
      .replace(/  +/g, ' ')
      .trim() : '';

    let queryParams = new HttpParams()
      .append('filter', filter);

    if (parentOnly) {
      queryParams = queryParams.append('parentOnly', true);
    }

    return this.httpClient
      .get<OrganizationOption[]>(
        this.apiUrl('admin/get-organization-options'),
        { params: queryParams },
      )
  }

  public getAccountOptions(filter: string | null): Observable<AccountOption[]> {
    filter = filter ? filter
      .replace(/  +/g, ' ')
      .trim() : '';

    const queryParams = new HttpParams()
      .append('filter', filter);

    return this.httpClient
      .get<AccountOption[]>(
        this.apiUrl('admin/get-account-options'),
        { params: queryParams },
      )
  }

  public getAccountManagerOptions(): Observable<AccountManagerOptions[]> {
    return this.httpClient
      .get<AccountManagerOptions[]>(this.apiUrl('admin/get-account-manager-options'));
  }

  public getMarketProfileOptions(filter: string | null): Observable<AccountOption[]> {
    filter = filter ? filter
      .replace(/  +/g, ' ')
      .trim() : '';

    const queryParams = new HttpParams()
      .append('filter', filter);

    return this.httpClient
      .get<MarketProfileOption[]>(
        this.apiUrl('admin/get-market-profile-options'),
        { params: queryParams },
      )
  }

  public getTankModemOptions(filter: string | null): Observable<TankModemOption[]> {
    filter = filter ? filter
      .replace(/  +/g, ' ')
      .trim() : '';

    const queryParams = new HttpParams()
      .append('filter', filter);

    return this.httpClient
      .get<MarketProfileOption[]>(
        this.apiUrl('admin/get-tank-modem-options'),
        { params: queryParams },
      )
  }

  public getOrganizationTankOptions(organizationId: string): Observable<OrganizationTankOption[]> {
    const queryParams = new HttpParams()
      .append('organization_id', organizationId)

    return this.httpClient
      .get<AccountManagerOptions[]>(
        this.apiUrl('admin/get-organization-tank-options'),
        { params: queryParams },
      );
  }

  public getSegmentOptions(filter: string | null = null): Observable<SegmentOption[]> {
    filter = filter ? filter
      .replace(/  +/g, ' ')
      .trim() : '';

    const queryParams = new HttpParams()
      .append('filter', filter)

    return this.httpClient
      .get<SegmentOption[]>(
        this.apiUrl('admin/get-segment-options'),
        { params: queryParams },
      );
  }

  public create(payload: CreateOrganizationData) {
    return this.httpClient
      .post<EmptyResponse>(this.restApiUrl, payload)
  }

  public updateGeneral(
    organizationId: string,
    payload: UpdateOrganizationGeneralData,
  ): Observable<EmptyResponse> {
    return this.httpClient
      .patch<EmptyResponse>(this.restApiUrl + '/' + organizationId + '/general', payload);
  }

  public updateSettings(
    organizationId: string,
    payload: UpdateOrganizationSettingsData,
  ): Observable<EmptyResponse> {
    return this.httpClient
      .patch<EmptyResponse>(this.restApiUrl + '/' + organizationId + '/settings', payload);
  }

  public updateAccess(
    organizationId: string,
    payload: UpdateOrganizationAccessData,
  ): Observable<EmptyResponse> {
    return this.httpClient
      .patch<EmptyResponse>(this.restApiUrl + '/' + organizationId + '/access', payload)
  }

  public updateSalesCondition(
    organizationId: string,
    payload: UpdateOrganizationSalesConditionsData,
  ): Observable<EmptyResponse> {
    return this.httpClient
      .patch<EmptyResponse>(this.restApiUrl + '/' + organizationId + '/sales-conditions', payload)
  }

  public updateAddress(
    organizationId: string,
    addressId: string,
    payload: UpdateOrganizationAddressData,
  ): Observable<EmptyResponse> {
    return this.httpClient
      .put<EmptyResponse>(this.restApiUrl + '/' + organizationId + '/address/' + addressId, payload)
  }

  public createAddress(
    organizationId: string,
    payload: CreateOrganizationAddressData,
  ): Observable<EmptyResponse> {
    return this.httpClient
      .post<EmptyResponse>(this.restApiUrl + '/' + organizationId + '/address', payload)
  }

  public deleteAddress(
    organizationId: string,
    addressId: string,
  ): Observable<EmptyResponse> {
    return this.httpClient
      .delete<EmptyResponse>(this.restApiUrl + '/' + organizationId + '/address/' + addressId)
  }

  public setLogo(organizationId: string, file: File | null) {
    const payload: FormData = new FormData();
    payload.append('logo', file ?? '');

    return this.httpClient
      .put<EmptyResponse>(this.restApiUrl + '/' + organizationId + '/logo', payload);
  }

  public delete(organizationId: string) {
    return this.httpClient.delete<any>(this.restApiUrl + '/' + organizationId)
  }
}

@Injectable({
  providedIn: 'root',
})
export class TankSettingsService extends ApiService {

  private restApiUrl = this.apiUrl('admin/telemetry');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public find(settingId: string): Observable<TankSettings> {
    return this.httpClient.get<TankSettings>(this.restApiUrl + '/tank-settings/' + settingId)
  }

  public findProductArticle(productCode: string) {
    const queryParams = new HttpParams()
      .append('code', productCode)

    return this.httpClient
      .get<TelemetryArticleData | Record<string, never>>(this.apiUrl('admin/get-telemetry-article'), { params: queryParams });
  }

  public create(payload: CreateTankSettingsData) {
    return this.httpClient
      .post<EmptyResponse>(this.restApiUrl + '/tank-settings', payload)
  }

  public update(settingId: string, payload: UpdateTankSettingsData) {
    return this.httpClient
      .put<TankSettings>(this.restApiUrl + '/tank-settings/' + settingId, payload)
  }

  public delete(settingId: string) {
    return this.httpClient.delete<any>(this.restApiUrl + '/tank-settings/' + settingId)
  }
}


