import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipGrid } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { SegmentOption } from '../../../organization/organization.model';
import { OrganizationService } from '../../../organization/organization.service';
import { FormHelper } from '../../../shared/mixin/form-helper';
import { NotificationService } from '../../../shared/service/notification.service';
import { dateUtils } from '../../../shared/utils/date-time';
import { CarouselData, DataSavedEvent, UpdateCarouselData } from '../../carousel.model';
import { CarouselService } from '../../carousel.service';

@Component({
  selector: 'app-update-item-form',
  templateUrl: './update-item-form.component.html',
  styleUrls: ['./update-item-form.component.scss'],
})
export class UpdateItemFormComponent extends FormHelper() implements OnInit {
  @Input() itemData!: CarouselData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public segmentOptions: Observable<SegmentOption[]>;
  public segmentOptionsLoading: boolean = false;
  public segmentsSelected: Map<string, string>;

  @ViewChild('segmentsInput') segmentsInput!: ElementRef<HTMLInputElement>;
  @ViewChild('segmentsChipList') chipList!: MatChipGrid;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private carouselService: CarouselService,
    private organizationService: OrganizationService,
    private notificationService: NotificationService,
  ) {
    super();
    this.segmentOptions = new Observable();
    this.segmentsSelected = new Map();
  }

  ngOnInit(): void {
    this.form = this.createForm();

    this.itemData.segments.forEach((value) => {
      this.segmentsSelected.set(value.id, value.name);
    });

    this.setupSegmentInputListener();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    const isValid = this.checkSegments() && this.form.valid;

    if (!isValid) {
      return;
    }

    this.isSaving = true;

    const payload: UpdateCarouselData = {
      title: this.form.value.title,
      description: this.form.value.description,
      link: this.form.value.link,
      segments: [...this.segmentsSelected.keys()],
      dateFrom:
        this.form.value.dateFrom !== null
          ? dateUtils.toCalendar.fromDate(this.form.value.dateFrom)
          : null,
      dateTo:
        this.form.value.dateTo !== null
          ? dateUtils.toCalendar.fromDate(this.form.value.dateTo)
          : null,
      published: this.form.value.published,
    };

    this.carouselService
      .update(this.itemData.id, payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        }),
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('carousel_item.updated');
          this.dataSaved.emit({ itemId: this.itemData.id });
        },
      });
  }

  public onSegmentInputLeave() {
    this.checkSegments();
  }

  public checkSegments(): boolean {
    this.chipList.errorState = this.segmentsSelected.size === 0;

    return this.segmentsSelected.size > 0;
  }

  public onSelectSegment(event: MatAutocompleteSelectedEvent): void {
    this.addSegment(event.option.value);
    this.segmentsInput.nativeElement.value = '';
    this.checkSegments();
  }

  public onRemoveSegment(key: string): void {
    if (!this.segmentsSelected.get(key)) {
      return;
    }

    this.segmentsSelected.delete(key);
    this.checkSegments();
  }

  private addSegment(option: SegmentOption): void {
    if (this.segmentsSelected.get(option.id)) {
      return;
    }

    this.segmentsSelected.set(option.id, option.name);
  }

  private setupSegmentInputListener() {
    const ctrl = this.form.get('segments');
    this.segmentOptions = ctrl!.valueChanges.pipe(
      filter(() => this.segmentsInput.nativeElement.value !== ''),
      switchMap((value: string | SegmentOption) => {
        // Because we use [value] for the mat-autocomplete option, it will set the input
        // value to the mat-option [value] on selection which is not desirable in our case
        // hence the type check below (if we don't receive a string, reset the options).
        const filter = typeof value === 'string' ? value : null;
        this.segmentOptionsLoading = filter !== null;

        return this.organizationService
          .getSegmentOptions(filter)
          .pipe(finalize(() => (this.segmentOptionsLoading = false)));
      }),
    );
  }

  private createForm() {
    return this.fb.group({
      title: this.fb.control(this.itemData.title, [
        Validators.required,
        Validators.minLength(2),
      ]),
      description: this.fb.control(
        this.itemData.description,
        Validators.required,
      ),
      link: this.fb.control(this.itemData.link),
      dateFrom: this.fb.control(this.itemData.dateFrom),
      dateTo: this.fb.control(this.itemData.dateTo),
      segments: this.fb.control(this.itemData.segments), // actual value not used
      published: this.fb.control(this.itemData.published),
    });
  }
}
