<a routerLink="/">Home</a> / Carousel items
<br/><br/>
<div class="row page-header">
  <div class="col title">
    <h1>Carousel items</h1>
  </div>
</div>
<div class="grid-controls">
  <div class="search">
    <mat-form-field class="w-100 pr-5 no-subscript" appearance="outline">
      <mat-label>Filter by name</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="searchText"
        (keyup)="onSearch()"
      />
      <button
        *ngIf="searchText"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="onSearchClear()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="buttons">
    <button
      mat-raised-button
      color="primary"
      routerLink="/carousel/create"
    >
      New item
    </button>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="table-wrapper mat-elevation-z3">
      <div class="table-progress-overlay" *ngIf="dataSource.loading | async">
        <mat-progress-spinner mode="indeterminate" diameter="50">
        </mat-progress-spinner>
      </div>
      <table
        [dataSource]="dataSource"
        mat-table
        matSort
        [matSortActive]="sortActiveDefault"
        [matSortDirection]="sortDirectionDefault"
        matSortDisableClear
      >
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/carousel/', element.id]"
              >{{ element.title }}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created at</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date : "dd MMM yyyy hh:mm" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="dateFrom">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Visible from
          </th>
          <td mat-cell *matCellDef="let element">
            {{ (element.dateFrom | date : "dd MMM Y") || "-" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="dateTo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Visible until
          </th>
          <td mat-cell *matCellDef="let element">
            {{ (element.dateTo | date : "dd MMM Y") || "-" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="published">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Published</th>
          <td mat-cell *matCellDef="let element">
            {{ element.published ? "Yes" : "No" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon (click)="onRemoveAction(element.id, element.title)" class="text-danger--hover"
              >delete</mat-icon
            >
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-data" [attr.colspan]="tableColumns.length">
            No data
          </td>
        </tr>
      </table>
      <mat-paginator
        [length]="totalItems"
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>
