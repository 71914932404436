import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataSheetType } from '../../../product.model';

@Component({
  selector: 'app-remove-dialog',
  templateUrl: './remove-dialog.component.html',
  styleUrls: ['./remove-dialog.component.scss']
})
export class RemoveDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<RemoveDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: { id: string, type: string },
  ) {}

  get type() {
    return this.data.type === DataSheetType.TDS ? 'Technical Data Sheet' : 'Safety Data Sheet'
  }

  onConfirm(id: string) {
    this.dialogRef.close({ id: id });
  }
}
