<div [formGroup]="form">
  <mat-form-field appearance="fill">
    <mat-label>Address line 1</mat-label>
    <input type="text" matInput formControlName="addressLine1">
    <mat-error *ngIf="hasError('addressLine1', 'required')">Address line 1 is required</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Address line 2</mat-label>
    <input type="text" matInput formControlName="addressLine2" appEmptyToNull>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Address line 3</mat-label>
    <input type="text" matInput formControlName="addressLine3" appEmptyToNull>
  </mat-form-field>
  <mat-form-field appearance="fill" class="zip-code">
    <mat-label>Zip code</mat-label>
    <input type="text" matInput formControlName="zip" maxlength="10">
    <mat-error *ngIf="hasError('zip', 'required')">Zip code is required</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>City</mat-label>
    <input type="text" matInput formControlName="city">
    <mat-error *ngIf="hasError('city', 'required')">City is required</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Country</mat-label>
    <mat-select formControlName="country">
      <mat-option *ngFor="let option of addressCountryOptions" [value]="option.id">
        {{ option.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="hasError('country', 'required')">Country is required</mat-error>
  </mat-form-field>
</div>
<mat-divider class="pb-2"></mat-divider>
<button
  mat-flat-button color="primary"
  class="button-save"
  (click)="onSubmit()"
>
  <div class="d-flex">
    Save
    <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
  </div>
</button>
<button
  mat-button
  (click)="onCancel()"
>
  Cancel
</button>
<mat-icon
  *ngIf="addressType !== 'business'"
  mat-list-icon
  class="button-delete float-end"
  (click)="onRemove()"
>
  delete
</mat-icon>
