import { Component, Input } from '@angular/core';
import { CarouselData } from '../../carousel.model';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent {

  @Input() itemData!: CarouselData;

  constructor() { }

  get image() {
    return this.itemData._links?.image;
  }
}
