import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataSavedEvent, OrganizationData, SegmentOption } from '../../organization.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../shared/service/notification.service';
import { AccountManagerOptions } from '../../../account-manager/account-manager.model';
import { OrganizationService } from '../../organization.service';
import { finalize } from 'rxjs/operators';
import { FormHelper } from '../../../shared/mixin/form-helper';
import { CUSTOMER_CODE } from '../../../shared/misc/regex';

@Component({
  selector: 'app-organization-general',
  templateUrl: './organization-general.component.html',
  styleUrls: ['./organization-general.component.scss']
})
export class OrganizationGeneralComponent extends FormHelper() implements OnInit {
  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public accountManagerOptions: AccountManagerOptions[] = [];
  public segmentOptions: SegmentOption[] = [];

  @Input() organizationData!: OrganizationData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private organizationService: OrganizationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.organizationService
      .getAccountManagerOptions()
      .subscribe((options) => {
        this.accountManagerOptions = options;
    })

    this.organizationService
      .getSegmentOptions()
      .subscribe((options) => {
        this.segmentOptions = options;
      });
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;

    this.organizationService
      .updateGeneral(this.organizationData.id, this.form.value)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('organization.general.updated');
          this.dataSaved.emit({ organizationId: this.organizationData.id });
        }
      });
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({
      name: this.fb.control(this.organizationData.name, Validators.required),
      customerCode: this.fb.control(this.organizationData.customerCode, [
        Validators.required,
        Validators.pattern(CUSTOMER_CODE)
      ]),
      phoneNumber: this.fb.control(this.organizationData.phoneNumber),
      segmentId: this.fb.control(this.organizationData.segment?.id),
      accountManagerId: this.fb.control(this.organizationData.accountManager),
      active: this.fb.control(this.organizationData.active),
    });
  }
}
