<a routerLink="/">Home</a> / Blocked delivery dates
<br/><br/>
<div class="row page-header">
  <div class="col title">
    <h1>Blocked Delivery Dates</h1>
  </div>
</div>
<div class="row">
  <div class="col card-wrapper">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class=""></mat-progress-bar>
    <mat-card>
      <mat-card-header>
      </mat-card-header>
      <mat-card-content>
        <div>
          <form [formGroup]="form" class="d-flex align-items-center">
            <mat-form-field appearance="outline" class="no-subscript">
              <mat-label>Date</mat-label>
              <input
                matInput
                [matDatepicker]="datePicker"
                [readonly]="true"
                formControlName="date"
              >
              <mat-datepicker-toggle
                matSuffix
                [for]="datePicker"
              >
              </mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
              <mat-error *ngIf="hasError('date', 'required')">Date is required</mat-error>
            </mat-form-field>
            <button
              mat-flat-button color="primary"
              class="button-add"
              (click)="onSubmit()"
            >
              <div class="d-flex">
                Add
                <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
              </div>
            </button>
          </form>
        </div>
        <mat-divider class="my-2"></mat-divider>
        <div *ngIf="blockedDates.length > 0; else noBlockedDatesFound">
          <table>
            <tr>
              <th>Date</th>
              <th>Action</th>
            </tr>
            <tr *ngFor="let data of blockedDates">
              <td>{{ data.date }}</td>
              <td>
                <mat-icon (click)="onRemove(data.id, data.date)" class="text-danger--hover button-delete">delete</mat-icon>
              </td>
            </tr>
          </table>
        </div>
        <ng-template #noBlockedDatesFound>
          <span class="no-data">No dates found.</span>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
</div>
