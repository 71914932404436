import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../shared/base/api-service';
import { CreateVideoData, UpdateVideoData, VideoCollectionResponse, VideoData } from './video.model';
import EmptyResponse from '../shared/model/misc';

@Injectable({
  providedIn: 'root'
})
export class VideoService extends ApiService {

  private restApiUrl = this.apiUrl('admin/videos');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public find(id: string): Observable<VideoData> {
    return this.httpClient.get<VideoData>(this.restApiUrl + '/' + id)
  }

  public findPaginated(
    page: number = 1,
    pageSize: number = 25,
    sortBy: string = 'name',
    sortDirection: string = 'desc',
    filter: string = '',
  ): Observable<VideoCollectionResponse> {
    const queryParams = new HttpParams()
      .append('page', page.toString())
      .append('page_size', pageSize.toString())
      .append('sort_by', sortBy)
      .append('sort_direction', sortDirection)
      .append('filter', filter.trim());

    return this.httpClient
      .get<VideoCollectionResponse>(this.restApiUrl, {params: queryParams})
  }

  public create(payload: CreateVideoData): Observable<EmptyResponse> {
    return this.httpClient
      .post<EmptyResponse>(this.restApiUrl, payload)
  }

  public update(videoId: string, payload: UpdateVideoData): Observable<EmptyResponse> {
    return this.httpClient
      .put<EmptyResponse>(this.restApiUrl + '/' + videoId, payload)
  }

  public delete(videoId: string) {
    return this.httpClient.delete<EmptyResponse>(this.restApiUrl + '/' + videoId)
  }
}
