<div class="tab-content-wrapper">
  <div class="row">
    <div class="col form-wrapper">
      <span class="form-section-title">Technical Data Sheet</span>
      <br/>
      <div *ngIf="!tds; else uploadedTds">
        <mat-form-field class="w-100">
          <mat-toolbar>
            <!-- Display filename -->
            <input matInput [(ngModel)]="fileAttrTds" readonly name="name" />
            <!-- Browse Button -->
            <button mat-flat-button color="primary">Browse File</button>
          </mat-toolbar>
          <input
            type="file"
            #fileInput
            id="uploadFile1"
            (change)="onSelect($event, 'tds')"
            name="uploadFile1"
            multiple="multiple"
            accept="application/pdf"
          />
        </mat-form-field>
        <br/>
        <span class="file-type-info">Supported file types: pdf</span>
      </div>
      <ng-template #uploadedTds>
        <div class="datasheet">
          <div class="url"><a href="{{ tds?.href }}" target="_blank">Download</a></div>
          <div class="remove"><mat-icon (click)="onRemove('tds')" mat-card-avatar class="text-danger--hover">delete</mat-icon></div>
        </div>
      </ng-template>
      <br/><br/>
      <span class="form-section-title">Safety Data Sheet</span>
      <br/>
      <div *ngIf="!sds; else uploadedSds">
        <mat-form-field class="w-100">
          <mat-toolbar>
            <!-- Display filename -->
            <input matInput [(ngModel)]="fileAttrSds" readonly name="name" />
            <!-- Browse Button -->
            <button mat-flat-button color="primary">Browse File</button>
          </mat-toolbar>
          <input
            type="file"
            #fileInput
            id="uploadFile2"
            (change)="onSelect($event, 'sds')"
            name="uploadFile2"
            multiple="multiple"
            accept="application/pdf"
          />
        </mat-form-field>
        <br/>
        <span class="file-type-info">Supported file types: pdf</span>
      </div>
      <ng-template #uploadedSds>
        <div class="datasheet">
          <div class="url"><a href="{{ sds?.href }}" target="_blank">Download</a></div>
          <div class="remove"><mat-icon (click)="onRemove('sds')" mat-card-avatar class="text-danger--hover">delete</mat-icon></div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
