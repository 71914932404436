import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ArticleRoutingModule } from './article-routing.module';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { ArticleOverviewComponent } from './article-overview/article-overview.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RemoveArticleDialogComponent } from './remove-article-dialog/remove-article-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CreateArticleFormComponent } from './create-article-form/create-article-form.component';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '../shared/shared.module';
import { ManageArticleComponent } from './manage-article/manage-article.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ArticleDetailsComponent } from './manage-article/article-details/article-details.component';
import { UpdateArticleFormComponent } from './manage-article/update-article-form/update-article-form.component';
import { ArticlePackshotComponent } from './manage-article/article-packshot/article-packshot.component';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
  imports: [
    ArticleRoutingModule,
    MatTableModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatListModule,
    MatDialogModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatTabsModule,
    MatToolbarModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    SharedModule
  ],
  declarations: [
    ArticleOverviewComponent,
    RemoveArticleDialogComponent,
    CreateArticleFormComponent,
    ManageArticleComponent,
    ArticleDetailsComponent,
    UpdateArticleFormComponent,
    ArticlePackshotComponent
  ],
})
export class ArticleModule { }
