import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrochureOverviewComponent } from './brochure-overview/brochure-overview.component';
import { CreateBrochureFormComponent } from './create-brochure-form/create-brochure-form.component';
import { BrochureDetailsComponent } from './brochure-details/brochure-details.component';
import { UpdateBrochureFormComponent } from './update-brochure-form/update-brochure-form.component';

const routes: Routes = [
  { path: 'brochures', component: BrochureOverviewComponent },
  { path: 'brochures/create', component: CreateBrochureFormComponent },
  { path: 'brochures/:id', component: BrochureDetailsComponent },
  { path: 'brochures/:id/edit', component: UpdateBrochureFormComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BrochureRoutingModule { }
