import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../shared/base/api-service';
import { HistoryData, ImportHistoryCollectionResponse } from './import-history-model';

@Injectable({
  providedIn: 'root'
})

export class ImportHistoryService extends ApiService {

  private restApiUrl = this.apiUrl('admin/import-history');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public find(id: string): Observable<HistoryData> {
    return this.httpClient.get<HistoryData>(this.restApiUrl + '/' + id)
  }

  public findPaginated(
    page: number = 1,
    pageSize: number = 25,
    sortBy: string = 'name',
    sortDirection: string = 'desc',
    filter: string = '',
  ): Observable<ImportHistoryCollectionResponse> {
    const queryParams = new HttpParams()
      .append('page', page.toString())
      .append('page_size', pageSize.toString())
      .append('sort_by', sortBy)
      .append('sort_direction', sortDirection)
      .append('filter', filter.trim());

    return this.httpClient
      .get<ImportHistoryCollectionResponse>(this.restApiUrl, {params: queryParams})
  }
}
