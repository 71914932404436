import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MagazineOverviewComponent } from './magazine-overview/magazine-overview.component';
import { CreateMagazineFormComponent } from './create-magazine-form/create-magazine-form.component';
import { MagazineDetailsComponent } from './magazine-details/magazine-details.component';
import { UpdateMagazineFormComponent } from './update-magazine-form/update-magazine-form.component';

const routes: Routes = [
  { path: 'magazines', component: MagazineOverviewComponent },
  { path: 'magazines/create', component: CreateMagazineFormComponent },
  { path: 'magazines/:id', component: MagazineDetailsComponent },
  { path: 'magazines/:id/edit', component: UpdateMagazineFormComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MagazineRoutingModule { }
