import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountOverviewComponent } from './account-overview/account-overview.component';
import { ManageAccountComponent } from './manage-account/manage-account.component';

const routes: Routes = [
  {
    path: 'accounts',
    component: AccountOverviewComponent,
  },
  {
    path: 'accounts/:id',
    component: ManageAccountComponent,
  },
  // { // Todo done from within tabs component ? likely can be removed
  //   path: 'accounts/:id/edit',
  //   component: UpdateAccountFormComponent
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
