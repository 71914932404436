import { MatTabChangeEvent } from '@angular/material/tabs';

type Constructor<T> = new(...args: any[]) => T;

export function CardWithTabs<TBase extends Constructor<any>>(Base: TBase = (class {} as any)) {
  return class extends Base {
    public onBeforeTabChange(index: number): void {
      // We must 'freeze' the card content to a fixed height until the
      // content has been changed and the new component has been rendered.
      // If we do not do this the tab content body will have 1px height for
      // a split second which makes the card shrink and expand after the
      // new component has been loaded resulting in ugly flickering.
      this.cardContent.nativeElement.style.height = this.calculateCardContentHeightForCurrentTab(index) + 'px';
    }

    public onTabChange(event: MatTabChangeEvent): void {
      this.currentTab = event.tab.textLabel
        .toLowerCase()
        .replace(/ /g,'_');

      // 'Unfreeze' card content height, so it can adjust to
      // the content height of the newly selected tab content.
      this.cardContent.nativeElement.style.height = 'auto';  // Todo this trick doesn't seem to work anymore for some reason
    }

    private getTabBodyContentElement(index: number): HTMLElement {
      return document.getElementsByClassName('mat-mdc-tab-body-content')[index] as HTMLElement;
    }

    private getTabHeaderElement(): HTMLElement {
      return document.getElementsByClassName('mat-mdc-tab-header')[0] as HTMLElement;
    }

    private calculateCardContentHeightForCurrentTab(currTabIndex: number): number {
      const tabBodyContentEl = this.getTabBodyContentElement(currTabIndex);
      if (!tabBodyContentEl) {
        return 0;
      }
      const tabHeaderEl = this.getTabHeaderElement();
      if (!tabHeaderEl) {
        return 0;
      }

      const paddingTop = window
        .getComputedStyle(this.cardContent.nativeElement)
        .getPropertyValue('padding-top');

      return tabBodyContentEl.clientHeight + tabHeaderEl.clientHeight + parseInt(paddingTop, 10);
    }
  }
}
