import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { OrganizationOption } from '../../order-import/order-import.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/service/notification.service';
import { finalize } from 'rxjs/operators';
import { FormHelper } from '../../shared/mixin/form-helper';
import { ProductService } from '../product.service';
import { PRODUCT_CODE } from '../../shared/misc/regex';
import { MatSelectChange } from '@angular/material/select';
import { BrandOption, CategoryOption, CreateProductData, SecondaryCategory } from '../product.model';

@Component({
  selector: 'app-create-product-form',
  templateUrl: './create-product-form.component.html',
  styleUrls: ['./create-product-form.component.scss']
})
export class CreateProductFormComponent extends FormHelper() implements OnInit {

  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public productOptions: Observable<OrganizationOption[]>;
  public productOptionsMap: { [key: string]: string };
  public brandOptions: BrandOption[] = [];
  public categoryOptions: CategoryOption[] = [];
  public secondaryCategoryOptions: SecondaryCategory[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private productService: ProductService,
    private notificationService: NotificationService
  ) {
    super();
    this.productOptions = new Observable();
    this.productOptionsMap = {};
  }

  ngOnInit(): void {
    this.form = this.createForm();

    this.productService
      .getBrandOptions()
      .subscribe((options) => {
        this.brandOptions = options;
    })
    this.productService
      .getCategoryOptions()
      .subscribe((options) => {
        this.categoryOptions = options;
      });
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;

    const payload: CreateProductData = {
      title: this.form.value.title,
      description: this.form.value.description,
      productCode: this.form.value.productCode,
      ripCode: this.form.value.ripCode,
      brandId: this.form.value.brandId,
      categoryId: this.form.value.secondaryCategoryId,
      v40Enabled: this.form.value.v40Enabled,
      v100Enabled: this.form.value.v100Enabled,
      hthsEnabled: this.form.value.hthsEnabled,
      ccsEnabled: this.form.value.ccsEnabled,
    }

    this.productService
      .create(payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('product.created');
          this.router.navigateByUrl('/products');
        }
      });
  }

  public onChangeCategory(event: MatSelectChange) {
    const result = this.categoryOptions.filter((cat) => {
      return cat.id === event.value;
    }) ;

    // Should never happen.
    if (result.length === 0) {
      return;
    }

    this.secondaryCategoryOptions = result[0].secondaryCategories;
    this.form.get('secondaryCategoryId')?.setValue(null);
  }

  private createForm() {
    return this.fb.group({
      title: this.fb.control('', [
        Validators.required,
        Validators.minLength(2)
      ]),
      description: this.fb.control(null),
      productCode: this.fb.control('',[
        Validators.required,
        Validators.pattern(PRODUCT_CODE)
      ]),
      ripCode: this.fb.control(null),
      brandId: this.fb.control('', Validators.required),
      primaryCategoryId: this.fb.control('', Validators.required),
      secondaryCategoryId: this.fb.control('', Validators.required),
      v40Enabled: this.fb.control(false),
      v100Enabled: this.fb.control(false),
      hthsEnabled: this.fb.control(false),
      ccsEnabled: this.fb.control(false),
    });
  }
}
