import { Directive, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

// Can be attached to a text input like: <input type="text" appEmptyToNull>
// to convert an empty string "" to null (which is sometimes desirable).

@Directive({
  selector: '[appEmptyToNull]'
})
export class EmptyToNullDirective {
  constructor(@Self() private ngControl: NgControl) {}

  @HostListener('keyup', ['$event']) onKeyUp() {
    if (this.ngControl.value === null) {
      return;
    }

    if (this.ngControl.value?.toString().trim() === '') {
      this.ngControl.reset(null);
    }
  }
}
