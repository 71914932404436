import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataSavedEvent, NewsData } from '../news.model';
import { NewsService } from '../news.service';
import { CardWithTabs } from '../../shared/mixin/card-with-tabs';

@Component({
  selector: 'app-manage-item',
  templateUrl: './manage-item.component.html',
  styleUrls: ['./manage-item.component.scss']
})
export class ManageItemComponent extends CardWithTabs() implements OnInit {

  public itemId!: string;
  public itemData!: NewsData;
  public currentTab: string = 'details';
  public isLoading: boolean = true;

  @ViewChild('cardContent') cardContent!: ElementRef<HTMLInputElement>;

  constructor(
    public newsService: NewsService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.itemId = this.route.snapshot.paramMap.get('id')!;

    // Used for reloading organization data when changing the
    // ID url segment. Also loads initial organization data.
    this.route.params.subscribe(params => {
      this.loadNewsData(params.id);
    });
  }

  public onDataSaved(event: DataSavedEvent) {
    this.loadNewsData(event.itemId);
  }

  private loadNewsData(itemId: string) {
    this.isLoading = true;

    this.newsService
      .find(itemId)
      .pipe(
        finalize(() => this.isLoading = false)
      ).
    subscribe(data => {
      this.itemData = data;
    });
  }
}
