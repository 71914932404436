<a routerLink="/">Home</a> / <a routerLink="/videos">Videos</a> / Edit
<br /><br />
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/videos/{{ videoId }}"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>Edit Magazine</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class=""></mat-progress-bar>
    <mat-card>
      <div *ngIf="videoData | async; let data">
        <mat-card-header>
          <mat-icon mat-card-avatar>movie</mat-icon>
          <mat-card-title>{{ data.title }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col form-wrapper">
              <form [formGroup]="form">
                <mat-form-field appearance="fill">
                  <mat-label>Title</mat-label>
                  <input formControlName="title" name="title" matInput />
                  <mat-error *ngIf="hasError('title', 'required')"
                    >Title is required</mat-error
                  >
                  <mat-error *ngIf="hasError('title', 'minlength')"
                    >Title must contain a minimum of 2 characters</mat-error
                  >
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Short description</mat-label>
                  <textarea
                    name="shortDescription"
                    placeholder="Short description here.."
                    formControlName="shortDescription"
                    rows="3"
                    matInput
                  >
                  </textarea>
                  <mat-error *ngIf="hasError('shortDescription', 'required')"
                    >Short description is required</mat-error
                  >
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Full description</mat-label>
                  <textarea
                    name="description"
                    placeholder="Description here.."
                    formControlName="description"
                    rows="10"
                    matInput
                    appEmptyToNull
                  >
                  </textarea>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Url</mat-label>
                  <input formControlName="url" name="url" matInput />
                  <mat-error *ngIf="hasError('url', 'required')"
                    >Url is required</mat-error
                  >
                </mat-form-field>
                <mat-label>Segments</mat-label>
                <mat-form-field appearance="fill">
                  <mat-chip-grid #segmentsChipList>
                    <mat-chip
                      *ngFor="let item of segmentsSelected | keyvalue"
                      (removed)="onRemoveSegment(item.key)"
                    >
                      {{ item.value }}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input
                      #segmentsInput
                      placeholder="Type to search a segment"
                      matInput
                      formControlName="segments"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="segmentsChipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (focusout)="onSegmentInputLeave()"
                    />
                  </mat-chip-grid>
                  <mat-error>At least 1 segment is required.</mat-error>
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="onSelectSegment($event)"
                  >
                    <mat-option
                      *ngFor="let option of segmentOptions | async"
                      [value]="option"
                    >
                      {{ option.name }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-icon
                    *ngIf="segmentOptionsLoading"
                    class="input-loading-spinner"
                  >
                    <mat-spinner diameter="20"> </mat-spinner>
                  </mat-icon>
                </mat-form-field>
              </form>
            </div>
          </div>
          <br />
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions>
          <button
            mat-flat-button
            color="primary"
            class="button-save"
            (click)="onSubmit()"
          >
            <div class="d-flex">
              Save
              <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
            </div>
          </button>
          <button mat-flat-button color="secondary" routerLink="/videos">
            Cancel
          </button>
        </mat-card-actions>
      </div>
    </mat-card>
  </div>
</div>
