import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../shared/base/api-service';
import { ArticleCollectionResponse, ArticleData, CreateArticleData, UpdateArticleData } from './article.model';
import EmptyResponse from '../shared/model/misc';
import { OrganizationOption } from '../order-import/order-import.model';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ArticleService extends ApiService {
  private restApiUrl = this.apiUrl('admin/articles');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public find(id: string): Observable<ArticleData> {
    return this.httpClient.get<ArticleData>(this.restApiUrl + '/' + id)
      .pipe(
        map((articleData: ArticleData): ArticleData => {
          // TODO this should be done in the back-end
          if (articleData?._links?.packShot) {
            articleData._links.packShot.href = articleData._links.packShot.href.replace(
              environment.apiUrl,
              environment.apiPublicUrl,
            );
          }
          return articleData;
        }),
      );
  }

  public findPaginated(
    page: number = 1,
    pageSize: number = 25,
    sortBy: string = 'title',
    sortDirection: string = 'asc',
    filter: string = '',
    filterType: string = 'title',
  ): Observable<ArticleCollectionResponse> {
    const queryParams = new HttpParams()
      .append('page', page.toString())
      .append('page_size', pageSize.toString())
      .append('sort_by', sortBy)
      .append('sort_direction', sortDirection)
      .append('filter', filter.trim())
      .append('filter_type', filterType);

    return this.httpClient
      .get<ArticleCollectionResponse>(this.restApiUrl, { params: queryParams });
  }

  public create(payload: CreateArticleData): Observable<EmptyResponse> {
    return this.httpClient
      .post<EmptyResponse>(this.restApiUrl, payload)
  }

  public update(articleId: string, payload: UpdateArticleData): Observable<EmptyResponse> {
    return this.httpClient
      .put<EmptyResponse>(this.restApiUrl + '/' + articleId, payload)
  }

  public delete(accountId: string): Observable<EmptyResponse> {
    return this.httpClient.delete<EmptyResponse>(this.restApiUrl + '/' + accountId)
  }

  public getProductOptions(filter: string | null): Observable<OrganizationOption[]> {
    filter = filter ? filter
      .replace(/  +/g, ' ')
      .trim() : '';

    const queryParams = new HttpParams()
      .append('filter', filter);

    return this.httpClient
      .get<OrganizationOption[]>(
        this.apiUrl('admin/get-product-options'),
        { params: queryParams },
      );
  }

  public uploadPackShot(articleId: string, file: File | null) {
    const payload: FormData = new FormData();
    payload.append('image', file ?? '');

    return this.httpClient
      .put<EmptyResponse>(this.restApiUrl + '/' + articleId + '/image', payload);
  }
}
