import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { AccountManagerData } from './account-manager.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AccountManagerService } from './account-manager.service';

export class AccountManagerDataSource implements DataSource<AccountManagerData> {

  private dataSubject = new BehaviorSubject<AccountManagerData[]>([]);
  private dataLengthSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public data = this.dataSubject.asObservable();
  public dataLength = this.dataLengthSubject.asObservable();
  public loading = this.loadingSubject.asObservable();

  constructor(private accountManagerService: AccountManagerService) {}

  connect(collectionViewer: CollectionViewer): Observable<AccountManagerData[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadAccountManagers(
    pageIndex: number,
    pageSize: number,
    sortBy: string = 'email',
    sortDirection: string = 'asc',
    filter: string = '',
  ) {

    this.loadingSubject.next(true);

    this.accountManagerService
      .findPaginated(
        pageIndex,
        pageSize,
        sortBy,
        sortDirection,
        filter
      )
      .pipe(
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(data => {
        this.dataSubject.next(data._embedded.account_manager);
        this.dataLengthSubject.next(data.total_items);
      });
  }
}
