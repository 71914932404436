import { UntypedFormGroup } from '@angular/forms';

type Constructor<T> = new(...args: any[]) => T;

export function FormHelper<TBase extends Constructor<any>>(Base: TBase = (class {} as any)) {
  return class extends Base {
    form!: UntypedFormGroup;

    public hasError(name: string, type: string) {
      return this.form?.controls[name].errors?.[type] && this.form.controls[name].touched;
    }
  }
}
