<a routerLink="/">Home</a> / <a routerLink="/news">News</a> / New Item
<br /><br />
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/news"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>New Item</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar>newspaper</mat-icon>
        <mat-card-title>New</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col form-wrapper">
            <form [formGroup]="form">
              <mat-form-field appearance="fill">
                <mat-label>Title</mat-label>
                <input formControlName="title" name="title" matInput />
                <mat-error *ngIf="hasError('title', 'required')"
                  >Title is required</mat-error
                >
                <mat-error *ngIf="hasError('title', 'minlength')"
                  >Title must contain a minimum of 2 characters</mat-error
                >
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Short Description</mat-label>
                <textarea
                  name="shortDescription"
                  placeholder="Short description here.."
                  formControlName="shortDescription"
                  rows="3"
                  matInput
                >
                </textarea>
                <mat-error *ngIf="hasError('shortDescription', 'required')">Short description is required</mat-error>
              </mat-form-field>
              <editor
                class="pt-3"
                [init]="tinyMceSettings"
                formControlName="content"
              ></editor>
              <mat-error *ngIf="hasError('content', 'required')">Content is required</mat-error>
              <br/>
              <mat-form-field appearance="fill">
                <mat-label><i>From</i> date</mat-label>
                <input
                  matInput
                  [matDatepicker]="datePicker1"
                  [readonly]="true"
                  formControlName="dateFrom"
                  appEmptyToNull
                />
                <mat-datepicker-toggle matSuffix [for]="datePicker1">
                </mat-datepicker-toggle>
                <mat-datepicker #datePicker1></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label><i>To</i> date</mat-label>
                <input
                  matInput
                  [matDatepicker]="datePicker2"
                  [readonly]="true"
                  formControlName="dateTo"
                  appEmptyToNull
                />
                <mat-datepicker-toggle matSuffix [for]="datePicker2">
                </mat-datepicker-toggle>
                <mat-datepicker #datePicker2></mat-datepicker>
              </mat-form-field>
              <mat-label>Segments</mat-label>
              <mat-form-field appearance="fill">
                <mat-chip-grid #segmentsChipList>
                  <mat-chip
                    *ngFor="let item of segmentsSelected | keyvalue"
                    (removed)="onRemoveSegment(item.key)"
                  >
                    {{ item.value }}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    #segmentsInput
                    placeholder="Type to search a segment"
                    matInput
                    formControlName="segments"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="segmentsChipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (focusout)="onSegmentInputLeave()"
                  />
                </mat-chip-grid>
                <mat-error>At least 1 segment is required.</mat-error>
                <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="onSelectSegment($event)"
                >
                  <mat-option
                    *ngFor="let option of segmentOptions | async"
                    [value]="option"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon
                  *ngIf="segmentOptionsLoading"
                  class="input-loading-spinner"
                >
                  <mat-spinner diameter="20"> </mat-spinner>
                </mat-icon>
              </mat-form-field>
              <br /><br />
              <mat-slide-toggle
                formControlName="sendNotification"
                name="sendNotification"
              >
                Enable notification <sup>*</sup>
              </mat-slide-toggle>
              <br /><br />
              <mat-slide-toggle formControlName="published" name="published">
                Published
              </mat-slide-toggle>
              <br/><br/>
              <span class="note"><sup>*</sup> Mail notifications will not be send directly, but on a scheduled interval</span>
            </form>
          </div>
        </div>
        <br/>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button
          mat-flat-button
          color="primary"
          class="button-save"
          (click)="onSubmit()"
        >
          <div class="d-flex">
            Save
            <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
          </div>
        </button>
        <button mat-flat-button color="secondary" routerLink="/news">
          Cancel
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
