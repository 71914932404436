<a routerLink="/">Home</a> / <a routerLink="/brochures">Brochures</a> / Details
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/brochures"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>Brochure details</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class=""></mat-progress-bar>
    <mat-card>
      <div *ngIf="brochureData | async; let data">
        <mat-card-header>
          <mat-icon mat-card-avatar>library_books</mat-icon>
          <mat-card-title>{{ data.title }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-md-6 form-label">Title</div>
            <div class="col-md-6 form-text">{{ data.title }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Short description</div>
            <div class="col-md-6 form-text">{{ data.shortDescription }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Created</div>
            <div class="col-md-6 form-text">{{ data.createdAt | date : "dd MMM yyyy hh:mm" }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">File</div>
            <div class="col-md-6 form-text">
              <div class="url"><a href="{{ data._links.file.href }}" target="_blank">Download</a></div>
            </div>
          </div>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions>
          <button
            mat-flat-button color="primary"
            routerLink="/brochures/{{brochureId}}/edit"
          >
            Edit
          </button>
        </mat-card-actions>
      </div>
    </mat-card>
  </div>
</div>
