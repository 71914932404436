import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, merge, Subject } from 'rxjs';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../shared/service/notification.service';
import { EquivalentService } from '../equivalent.service';
import { EquivalentDataSource } from '../equivalent.data-source';
import { RemoveEquivalentDialogComponent } from '../remove-equivalent-dialog/remove-equivalent-dialog.component';

@Component({
  selector: 'app-overview',
  templateUrl: './equivalent-overview.component.html',
  styleUrls: ['./equivalent-overview.component.scss']
})
export class EquivalentOverviewComponent implements AfterViewInit, OnDestroy, OnInit {

  private searchInputSubject = new Subject<string>();
  public searchInput = this.searchInputSubject.asObservable();
  public searchText = '';
  private searchEmpty = true;
  public tableColumns: string[] = [
    'createdAt',
    'type',
    'oldCode',
    'newCode',
    'nameOld',
    'nameNew',
    'packagingOld',
    'packagingNew',
    'showNotification',
    'replaceOrderWithEquivalent',
    'removeFromSearchResults',
    'actions',
  ];

  public sortActiveDefault = 'createdAt';
  public sortDirectionDefault: SortDirection = 'asc';
  public pageSizeOptions: number[] = [15, 30, 50];
  public totalItems = 0;
  public dataSource: EquivalentDataSource;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private equivalentService: EquivalentService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
    this.dataSource = new EquivalentDataSource(this.equivalentService);

    this.dataSource.dataLength.subscribe((total) => {
      this.totalItems = total
    });
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    // Reset pagination after sort change.
    this.sort.sortChange
      .subscribe(() => this.paginator.pageIndex = 0);

    // Load data when a sort or paginator event is emitted.
    merge(this.sort.sortChange, this.paginator.page)
      .subscribe(() => this.loadData());

    // Load data when search input value changes.
    this.searchInput
      .pipe(
        debounceTime(150)
      )
      .subscribe((value) => {
        if (!this.searchEmpty || value.length) {
          this.paginator.pageIndex = 0;
          this.loadData()
        }

        this.searchEmpty = value.length === 0;
      });
  }

  ngOnDestroy() {
    this.searchInputSubject.unsubscribe();
  }

  public onSearch(): void {
    this.searchInputSubject.next(this.searchText);
  }

  public onSearchClear(): void {
    this.searchText = '';
    this.onSearch();
  }

  public onToggleOption(id: string, type: string, enabled: boolean): void {
    const payload = {
      option: type,
      enabled: enabled
    }
    this.equivalentService
      .updateOption(id, payload)
      .subscribe(() => {
        this.notificationService.success_ts('equivalent.option.saved')
    });
  }

  public onRemoveAction(id: string, articleOld: string, articleNew: string): void {
    if (this.dialog.openDialogs.length) {
      return;
    }

    this.dialog
      .open(RemoveEquivalentDialogComponent, {
        data: {
          id: id,
          articleOld: articleOld,
          articleNew: articleNew,
        },
      })
      .afterClosed()
      .subscribe(
        data => {
          if (data?.id) {
            this.removeEquivalent(data.id);
          }
        }
      );
  }

  private removeEquivalent(equivalentId: string): void {
    this.equivalentService
      .delete(equivalentId)
      .subscribe({
        next: () => {
          this.notificationService.success_ts('equivalent.deleted');
          this.loadData();
        }
      })
  }

  private loadData() {
    this.dataSource.loadEquivalents(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || this.pageSizeOptions[0],
      this.sort?.active || this.sortActiveDefault,
      this.sort?.direction || this.sortDirectionDefault,
      this.searchText
    );
  }
}
