import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from '../../../shared/service/notification.service';
import { DataSavedEvent, DataSheetType, ProductData } from '../../product.model';
import { ProductService } from '../../product.service';
import { RemoveDialogComponent } from './remove-dialog/remove-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-product-data-sheets',
  templateUrl: './product-data-sheets.component.html',
  styleUrls: ['./product-data-sheets.component.scss']
})
export class ProductDataSheetsComponent {
  public fileAttrTds = 'Select file';
  public fileAttrSds = 'Select file';

  @Input() productData!: ProductData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  constructor(
    private productService: ProductService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
  }

  get tds() {
    return this.productData._links?.tds
  }

  get sds() {
    return this.productData._links?.sds
  }

  onSelect(event: Event, type: string): void {
    const files = (event.target as HTMLInputElement).files;

    if (!files) {
      return;
    }

    if (type === DataSheetType.TDS) {
      this.fileAttrTds = files[0].name;
    } else {
      this.fileAttrSds = files[0].name;
    }

    this.productService
      .uploadDataSheet(this.productData.id, files[0], type)
      .subscribe((next) => {
        this.dataSaved.emit({ productId: this.productData.id });
        this.notificationService.success_ts('product.datasheet.uploaded');
      })
  }

  onRemove(type: string): void {
    if (this.dialog.openDialogs.length) {
      return;
    }

    this.dialog
      .open(RemoveDialogComponent, {
        data: {
          id: this.productData?.id,
          type: type
        },
      })
      .afterClosed()
      .subscribe(
        data => {
          if (data?.id) {
            this.removeFile(data.id, type);
          }
        }
      );
  }

  private removeFile(id: string, type: string): void {
    this.productService
      .uploadDataSheet(id, null, type)
      .subscribe((next) => {
        this.dataSaved.emit({ productId: this.productData.id });
        this.notificationService.success_ts('product.datasheet.removed');

        if (type === DataSheetType.TDS) {
          this.fileAttrTds = 'Select file';
        } else {
          this.fileAttrSds = 'Select file';
        }
      })
  }
}
