import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AccountManagerService } from '../account-manager.service';
import { AccountManagerData } from '../account-manager.model';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-account-manager-details',
  templateUrl: './account-manager-details.component.html',
  styleUrls: ['./account-manager-details.component.scss']
})
export class AccountManagerDetailsComponent implements OnInit {

  public accountManagerId!: string;
  public accountManagerData: Observable<AccountManagerData>;
  public isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    public accountManagerService: AccountManagerService,
  ) {
    this.accountManagerData = new Observable<AccountManagerData>();
  }

  ngOnInit(): void {
    this.accountManagerId = this.route.snapshot.paramMap.get('id')!;
    this.accountManagerData = this.accountManagerService
      .find(this.accountManagerId)
      .pipe(
        finalize(() => this.isLoading = false)
      );
  }
}
