<div class="tab-content-wrapper">
  <div class="row">
    <div class="col grid-controls">
      <mat-button-toggle-group
        (change)="onAddressChange($event)"
        [(ngModel)]="addressSelected"
      >
        <mat-button-toggle
          [class]="{'toggle-active': addressSelected === 'business'}"
          value="business"
        >
          Business address
        </mat-button-toggle>
        <mat-button-toggle
          [class]="{'toggle-active': addressSelected === 'invoicing'}"
          value="invoicing"
        >
          Invoicing address
        </mat-button-toggle>
        <mat-button-toggle
          [class]="{'toggle-active': addressSelected === 'delivery'}"
          value="delivery"
        >
          Delivery address
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="row">
    <div class="col form-wrapper">
      <app-address-details
        *ngIf="!showForm && addressData"
        [addressData]="addressData"
      >
      </app-address-details>
      <app-address-form
        *ngIf="showForm"
        (addressSaved)="onAddressSaved()"
        (addressUpdateCancelled)="onAddressUpdateCancelled()"
        [addressData]="addressData"
        [addressType]="addressSelected"
        [organizationId]="organizationData.id"
      >
      </app-address-form>
      <div *ngIf="!showForm">
        <div *ngIf="addressData; else noAddressData">
          <br/>
          <mat-icon class="edit" (click)="onEditClick()">
            create
          </mat-icon>

        </div>
        <ng-template #noAddressData>
          <br/><br>
          <span class="not-found">
            No {{ addressSelected }} address found, click <a (click)="onAddClick()" class="add">here</a> to add
          </span>
        </ng-template>
      </div>
    </div>
  </div>
</div>
