import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../shared/base/api-service';
import { CarouselCollectionResponse, CarouselData, CreateCarouselData, UpdateCarouselData } from './carousel.model';
import EmptyResponse from '../shared/model/misc';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CarouselService extends ApiService {

  private restApiUrl = this.apiUrl('admin/carousel');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public find(id: string): Observable<CarouselData> {
    return this.httpClient.get<CarouselData>(this.restApiUrl + '/' + id).pipe(
      map((carouselData: CarouselData): CarouselData => {
        // TODO this should be done in the back-end
        if (carouselData?._links?.image) {
          carouselData._links.image.href = carouselData._links.image.href.replace(
            environment.apiUrl,
            environment.apiPublicUrl,
          );
        }
        return carouselData;
      }),
    );
  }

  public findPaginated(
    page: number = 1,
    pageSize: number = 25,
    sortBy: string = 'name',
    sortDirection: string = 'desc',
    filter: string = '',
  ): Observable<CarouselCollectionResponse> {
    const queryParams = new HttpParams()
      .append('page', page.toString())
      .append('page_size', pageSize.toString())
      .append('sort_by', sortBy)
      .append('sort_direction', sortDirection)
      .append('filter', filter.trim());

    return this.httpClient
      .get<CarouselCollectionResponse>(this.restApiUrl, { params: queryParams })
  }

  public create(payload: CreateCarouselData): Observable<EmptyResponse> {
    return this.httpClient
      .post<EmptyResponse>(this.restApiUrl, payload)
  }

  public update(itemId: string, payload: UpdateCarouselData): Observable<EmptyResponse> {
    return this.httpClient
      .put<EmptyResponse>(this.restApiUrl + '/' + itemId, payload)
  }

  public delete(itemId: string) {
    return this.httpClient.delete<EmptyResponse>(this.restApiUrl + '/' + itemId)
  }

  public uploadImage(itemId: string, file: File | null) {
    const payload: FormData = new FormData();
    payload.append('image', file ?? '');

    return this.httpClient
      .put<EmptyResponse>(this.restApiUrl + '/' + itemId + '/image', payload);
  }
}
