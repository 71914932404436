import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataSavedEvent, OrganizationData } from '../../organization.model';
import { OrganizationService } from '../../organization.service';
import { NotificationService } from '../../../shared/service/notification.service';

@Component({
  selector: 'app-organization-logo',
  templateUrl: './organization-logo.component.html',
  styleUrls: ['./organization-logo.component.scss']
})
export class OrganizationLogoComponent {

  public fileAttr = 'Select file';
  public showDeleteIcon = false;

  @Input() organizationData!: OrganizationData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  constructor(
    private organizationService: OrganizationService,
    private notificationService: NotificationService
  ) {
  }

  get logo() {
    return this.organizationData._links?.logo
  }

  onSelect(event: Event): void {
    const files = (event.target as HTMLInputElement).files;

    if (!files) {
      return;
    }

    this.fileAttr = files[0].name;

    this.organizationService
      .setLogo(this.organizationData.id, files[0])
      .subscribe((next) => {
        this.dataSaved.emit({ organizationId: this.organizationData.id });
        this.notificationService.success_ts('organization.logo.uploaded');
    })
  }

  onRemove(): void {
    this.organizationService
      .setLogo(this.organizationData.id, null)
      .subscribe((next) => {
        this.dataSaved.emit({ organizationId: this.organizationData.id });
        this.notificationService.success_ts('organization.logo.removed');
      })
  }
}
