<div class="tab-content-wrapper">
  <div class="row mb-2">
    <div class="col-6 form-wrapper">
      <div
        class="image-preview d-flex justify-content-center align-items-center"
        (mouseenter)="showDeleteIconImage = true"
        (mouseleave)="showDeleteIconImage = false"
      >
        <img [src]="image.href" *ngIf="image"/>
        <mat-icon
          *ngIf="image && showDeleteIconImage"
          mat-list-icon
          class="button-delete float-end"
          (click)="onRemove()"
        >
          delete
        </mat-icon>
        <span *ngIf="!image" class="no-image">No image uploaded</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col form-wrapper">
      <mat-form-field class="image-input">
        <div>
          <mat-toolbar>
            <!-- Display filename -->
            <input matInput [(ngModel)]="fileAttr" readonly/>
            <!-- Browse Button -->
            <button mat-flat-button color="primary">Browse File</button>
          </mat-toolbar>
          <input
            type="file"
            id="uploadFile"
            (change)="onSelect($event)"
            name="uploadFile"
            multiple="multiple"
            accept="image/*"
          />
        </div>
      </mat-form-field>
      <span class="file-type-info">Supported file types: jpeg, png</span>
    </div>
  </div>
</div>
