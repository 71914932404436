import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FromNowPipe } from './pipe/from-now';
import { EmptyToNullDirective } from './directive/empty-to-null';
import { FormatDecimalDirective } from './directive/format-decimal';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    FromNowPipe,
    EmptyToNullDirective,
    FormatDecimalDirective,
  ],
  exports: [
    CommonModule,
    FromNowPipe,
    EmptyToNullDirective,
    FormatDecimalDirective,
  ]
})

export class SharedModule {
}
