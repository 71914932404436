<div class="tab-content-wrapper">
  <div class="row">
    <div class="col form-wrapper">
      <form [formGroup]="form">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input type="text" matInput formControlName="name" >
          <mat-error *ngIf="hasError('name', 'required')">Organization name is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Code</mat-label>
          <input type="text" matInput formControlName="customerCode" maxlength="10">
          <mat-error *ngIf="hasError('customerCode', 'required')">Code is required</mat-error>
          <mat-error *ngIf="hasError('customerCode', 'pattern')">Code has an invalid format (expected 10 digits)</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Phone</mat-label>
          <input type="text" matInput formControlName="phoneNumber" appEmptyToNull>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Segment</mat-label>
          <mat-select formControlName="segmentId">
            <mat-option *ngFor="let option of segmentOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>AccountManager</mat-label>
          <mat-select formControlName="accountManagerId">
            <mat-option [value]=null>< None ></mat-option>
            <mat-option *ngFor="let option of accountManagerOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="form-checkbox">
          <mat-checkbox class="example-margin" formControlName="active">Active</mat-checkbox>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-divider class="pb-2"></mat-divider>
      <button
        mat-flat-button color="primary"
        class="button-save"
        (click)="onSubmit()"
      >
        <div class="d-flex">
          Save
          <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
        </div>
      </button>
    </div>
  </div>
</div>
