<a routerLink="/">Home</a> / <a routerLink="/articles">Articles</a> / Manage
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/articles"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>Manage Article</h1>
  </div>
</div>
<div class="row">
  <div class="col card-wrapper">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class=""></mat-progress-bar>
    <mat-card>
      <div *ngIf="articleData">
        <mat-card-header>
          <mat-icon mat-card-avatar>shopping_basket</mat-icon>
          <mat-card-title>{{ articleData.title }}</mat-card-title>
        </mat-card-header>
        <mat-card-content id="card1" #cardContent>
          <mat-tab-group
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            animationDuration="0"
            (selectedIndexChange)="onBeforeTabChange($event)"
            (selectedTabChange)="onTabChange($event)"
            #matTabGroup
          >
            <mat-tab label="Details">
              <app-article-details
                *ngIf="currentTab === 'details'"
                [articleData]="articleData"
              >
              </app-article-details>
            </mat-tab>
            <mat-tab label="Attributes">
              <app-update-article-form
                *ngIf="currentTab === 'attributes'"
                (dataSaved)="onDataSaved($event)"
                [articleData]="articleData"
              >
              </app-update-article-form>
            </mat-tab>
            <mat-tab label="Pack shot">
              <app-article-packshot
                *ngIf="currentTab === 'pack_shot'"
                (dataSaved)="onDataSaved($event)"
                [articleData]="articleData"
              >
              </app-article-packshot>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>
