import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../service/authentication.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
  ) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === HttpStatusCode.Unauthorized) {
          this.authenticationService.revokeAuthorization();
          // Prevents error from being propagated resulting in
          // a brief error message before redirecting to the SSO.
          // (something we don't want to see).
          return EMPTY;
        }

        if (response.status === HttpStatusCode.Forbidden) {
        }

        return throwError(() => response);
      })
    );
  }
}
