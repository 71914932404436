<a routerLink="/">Home</a> / <a routerLink="/accounts">Accounts</a> / Manage
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/accounts"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>Manage Account</h1>
  </div>
</div>
<div class="row">
  <div class="col card-wrapper">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class=""></mat-progress-bar>
    <mat-card>
      <div *ngIf="accountData">
        <mat-card-header>
          <mat-icon mat-card-avatar>person</mat-icon>
          <mat-card-title>{{ accountData.email }}</mat-card-title>
        </mat-card-header>
        <mat-card-content id="card1" #cardContent>
          <mat-tab-group
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            animationDuration="0"
            (selectedIndexChange)="onBeforeTabChange($event)"
            (selectedTabChange)="onTabChange($event)"
          >
            <mat-tab label="Details">
              <app-account-details
                *ngIf="currentTab === 'details'"
                [accountData]="accountData"
              >
              </app-account-details>
            </mat-tab>

            <mat-tab label="Settings">
              <app-update-account-form
                *ngIf="currentTab === 'settings'"
                (dataSaved)="onDataSaved($event)"
                [accountData]="accountData"
              >
              </app-update-account-form>
            </mat-tab>
            <mat-tab label="Extra">
              <app-extra-options
                *ngIf="currentTab === 'extra'"
                [accountData]="accountData"
              >
              </app-extra-options>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>
