import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../shared/base/api-service';
import EmptyResponse from '../shared/model/misc';

@Injectable({
  providedIn: 'root'
})
export class OrderImportService extends ApiService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public uploadFile(organizationId: string, file: File | null) {
    const payload: FormData = new FormData();
    payload.append('organizationId', organizationId);
    payload.append('file', file ?? '');

    return this.httpClient
      .post<EmptyResponse>(this.apiUrl('admin/import-orders'), payload);
  }
}
