import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AccountManagerCollectionResponse,
  AccountManagerData,
  CreateAccountManagerData,
  UpdateManagerAccountData,
} from './account-manager.model';
import { Observable } from 'rxjs';
import { ApiService } from '../shared/base/api-service';

@Injectable({
  providedIn: 'root'
})
export class AccountManagerService extends ApiService {

  private restApiUrl = this.apiUrl('admin/account-manager');

  constructor(private httpClient: HttpClient) {
    super();
  }

  public find(id: string): Observable<AccountManagerData> {
    return this.httpClient.get<AccountManagerData>(this.restApiUrl + '/' + id)
  }

  public findPaginated(
    page: number = 1,
    pageSize: number = 25,
    sortBy: string = 'name',
    sortDirection: string = 'desc',
    filter: string = '',
  ): Observable<AccountManagerCollectionResponse> {
    const queryParams = new HttpParams()
      .append('page', page.toString())
      .append('page_size', pageSize.toString())
      .append('sort_by', sortBy)
      .append('sort_direction', sortDirection)
      .append('filter', filter.trim());

    return this.httpClient
      .get<AccountManagerCollectionResponse>(this.restApiUrl, {params: queryParams})
  }

  public create(createAccount: any): Observable<CreateAccountManagerData> {
    return this.httpClient
      .post<CreateAccountManagerData>(this.restApiUrl, createAccount)
  }

  public update(accountManagerId: string, payload: UpdateManagerAccountData): Observable<AccountManagerData> {
    return this.httpClient
      .patch<AccountManagerData>(this.restApiUrl + '/' + accountManagerId, payload)
  }

  public delete(accountId: string) {
    return this.httpClient.delete<any>(this.restApiUrl + '/' + accountId)
  }
}
