import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, switchMap } from 'rxjs';
import { SegmentOption } from '../../organization/organization.model';
import { MatChipGrid } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from '../../organization/organization.service';
import { NotificationService } from '../../shared/service/notification.service';
import { filter, finalize } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormHelper } from '../../shared/mixin/form-helper';
import { CreateBrochureData } from '../brochure.model';
import { BrochureService } from '../brochure.service';

@Component({
  selector: 'app-create-brochure-form',
  templateUrl: './create-brochure-form.component.html',
  styleUrls: ['./create-brochure-form.component.scss'],
})
export class CreateBrochureFormComponent extends FormHelper() implements OnInit {

  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public segmentOptions: Observable<SegmentOption[]>;
  public segmentOptionsLoading: boolean = false;
  public segmentsSelected: Map<string, string>;
  public fileAttr = 'Select file';
  public fileSelected: File | null;

  @ViewChild('segmentsInput') segmentsInput!: ElementRef<HTMLInputElement>;
  @ViewChild('segmentsChipList') chipList!: MatChipGrid;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private brochureService: BrochureService,
    private organizationService: OrganizationService,
    private notificationService: NotificationService,
  ) {
    super();
    this.segmentOptions = new Observable();
    this.segmentsSelected = new Map();
    this.fileSelected = null;
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.setupSegmentInputListener();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    const isValid = this.checkSegments() && this.form.valid && this.fileSelected !== null;

    if (!isValid) {
      return;
    }

    this.isSaving = true;

    const payload: CreateBrochureData = {
      title: this.form.value.title,
      shortDescription: this.form.value.shortDescription,
      description: this.form.value.description,
      segments: [...this.segmentsSelected.keys()],
      // @ts-ignore
      file: this.fileSelected,
    };

    this.brochureService
      .create(payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        }),
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('brochure.created');
          this.router.navigateByUrl('/brochures');
        },
      });
  }

  onSelectFile(event: Event): void {
    const files = (event.target as HTMLInputElement).files;

    if (!files) {
      return;
    }

    this.form.get('file')?.setValue(files[0].name);
    this.fileSelected = files[0];
  }

  public onSegmentInputLeave() {
    this.checkSegments();
  }

  public checkSegments(): boolean {
    this.chipList.errorState = this.segmentsSelected.size === 0;

    return this.segmentsSelected.size > 0;
  }

  public onSelectSegment(event: MatAutocompleteSelectedEvent): void {
    this.addSegment(event.option.value);
    this.segmentsInput.nativeElement.value = '';
    this.checkSegments();
  }

  public onRemoveSegment(key: string): void {
    if (!this.segmentsSelected.get(key)) {
      return;
    }

    this.segmentsSelected.delete(key);
    this.checkSegments();
  }

  private addSegment(option: SegmentOption): void {
    if (this.segmentsSelected.get(option.id)) {
      return;
    }

    this.segmentsSelected.set(option.id, option.name);
  }

  private setupSegmentInputListener() {
    const ctrl = this.form.get('segments');
    this.segmentOptions = ctrl!.valueChanges.pipe(
      filter(() => this.segmentsInput.nativeElement.value !== ''),
      switchMap((value: string | SegmentOption) => {
        // Because we use [value] for the mat-autocomplete option, it will set the input
        // value to the mat-option [value] on selection which is not desirable in our case
        // hence the type check below (if we don't receive a string, reset the options).
        const filter = typeof value === 'string' ? value : null;
        this.segmentOptionsLoading = filter !== null;

        return this.organizationService
          .getSegmentOptions(filter)
          .pipe(finalize(() => (this.segmentOptionsLoading = false)));
      })
    );
  }

  private createForm() {
    return this.fb.group({
      title: this.fb.control('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      shortDescription: this.fb.control(null, Validators.required),
      description: this.fb.control(null),
      segments: this.fb.control(''), // actual value not used
      file: this.fb.control(this.fileAttr),
    });
  }
}
