import moment from 'moment';

export class DateUtils {
  toCalendar = {
    fromDate: (date: Date): string => {
      return moment(date).format('YYYY-MM-DD');
    },
  }
}

export const dateUtils = new DateUtils();
