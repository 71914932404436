import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ProductService } from '../product.service';
import { DataSavedEvent, ProductData } from '../product.model';
import { CardWithTabs } from '../../shared/mixin/card-with-tabs';

@Component({
  selector: 'app-manage-product',
  templateUrl: './manage-product.component.html',
  styleUrls: ['./manage-product.component.scss']
})
export class ManageProductComponent extends CardWithTabs() implements OnInit {

  public productId!: string;
  public productData!: ProductData;
  public currentTab: string = 'details';
  public isLoading: boolean = true;

  @ViewChild('cardContent') cardContent!: ElementRef<HTMLInputElement>;

  constructor(
    public productService: ProductService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.productId = this.route.snapshot.paramMap.get('id')!;

    // Used for reloading organization data when changing the
    // ID url segment. Also loads initial organization data.
    this.route.params.subscribe(params => {
      this.loadproductData(params.id);
    });
  }

  public onDataSaved(event: DataSavedEvent) {
    this.loadproductData(event.productId);
  }

  private loadproductData(productId: string) {
    this.isLoading = true;

    this.productService
      .find(productId)
      .pipe(
        finalize(() => this.isLoading = false)
      ).
    subscribe(data => {
      this.productData = data;
    });
  }
}
