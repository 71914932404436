import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../../shared/base/api-service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public fetchRoles(): Observable<string[]> {
    return this.httpClient.get<string[]>(this.apiUrl('account/roles'))
  }
}
