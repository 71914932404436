import { Component, Input } from '@angular/core';
import { NewsData } from '../../news.model';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent {

  @Input() itemData!: NewsData;

  constructor() { }

  get headerImage() {
    return this.itemData._links?.headerImg;
  }

  get summaryImage() {
    return this.itemData._links?.summaryImg;
  }
}
