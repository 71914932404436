import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AccountManagerRoutingModule } from './account-manager-routing.module';
import { AccountManagerOverviewComponent } from './account-manager-overview/account-manager-overview.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SharedModule } from '../shared/shared.module';
import { AccountManagerDetailsComponent } from './account-manager-details/account-manager-details.component';
import { UpdateAccountManagerFormComponent } from './update-account-manager-form/update-account-manager-form.component';
import { CreateAccountManagerFormComponent } from './create-account-manager-form/create-account-manager-form.component';
import {
  RemoveAccountManagerDialogComponent,
} from './remove-account-manager-dialog/remove-account-manager-dialog.component';

@NgModule({
  imports: [
    AccountManagerRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatListModule,
    MatDialogModule,
    MatDividerModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
  ],
  declarations: [
    AccountManagerOverviewComponent,
    AccountManagerDetailsComponent,
    UpdateAccountManagerFormComponent,
    CreateAccountManagerFormComponent,
    RemoveAccountManagerDialogComponent
  ],
})
export class AccountManagerModule { }
