import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { debounceTime, merge, Subject } from 'rxjs';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../shared/service/notification.service';
import { RemoveMagazineDialogComponent } from '../../magazine/remove-magazine-dialog/remove-magazine-dialog.component';
import { VideoDataSource } from '../video.data-source';
import { VideoService } from '../video.service';

@Component({
  selector: 'app-video-overview',
  templateUrl: './video-overview.component.html',
  styleUrls: ['./video-overview.component.scss']
})
export class VideoOverviewComponent implements AfterViewInit, OnInit {

  private searchInputSubject = new Subject<string>();
  public searchInput = this.searchInputSubject.asObservable();
  public searchText = '';
  private searchEmpty = true;
  public tableColumns: string[] = [
    'title',
    'createdAt',
    'actions'
  ];

  public sortActiveDefault = 'createdAt';
  public sortDirectionDefault: SortDirection = 'desc';
  public pageSizeOptions: number[] = [15, 30, 50];
  public totalItems = 0;
  public dataSource: VideoDataSource;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private videoService: VideoService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
    this.dataSource = new VideoDataSource(videoService);

    this.dataSource.dataLength.subscribe((total) => {
      this.totalItems = total
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit() {
    // Reset pagination after sort change.
    this.sort.sortChange
      .subscribe(() => this.paginator.pageIndex = 0);

    // Load data when a sort or paginator event is emitted.
    merge(this.sort.sortChange, this.paginator.page)
      .subscribe(() => this.loadData());

    // Load data when search input value changes.
    this.searchInput
      .pipe(
        debounceTime(150)
      )
      .subscribe((value) => {
        if (!this.searchEmpty || value.length) {
          this.paginator.pageIndex = 0;
          this.loadData()
        }

        this.searchEmpty = value.length === 0;
      });
  }

  public onSearch(): void {
    this.searchInputSubject.next(this.searchText);
  }

  public onSearchClear(): void {
    this.searchText = '';
    this.onSearch();
  }

  public onRemoveAction(id: string, name: string): void {
    if (this.dialog.openDialogs.length) {
      return;
    }

    this.dialog
      .open(RemoveMagazineDialogComponent, {
        data: {
          id: id,
          name: name,
        },
      })
      .afterClosed()
      .subscribe(
        data => {
          if (data?.id) {
            this.deleteItem(data.id);
          }
        }
      );
  }

  private deleteItem(itemId: string): void {
    this.videoService
      .delete(itemId)
      .subscribe({
        next: () => {
          this.notificationService.success_ts('video.deleted');
          this.loadData();
        }
      })
  }

  private loadData() {
    this.dataSource.loadVideos(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || this.pageSizeOptions[0],
      this.sort?.active || this.sortActiveDefault,
      this.sort?.direction || this.sortDirectionDefault,
      this.searchText
    );
  }
}
