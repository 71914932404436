import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BrandOption,
  CategoryOption,
  DataSavedEvent,
  ProductData,
  SecondaryCategory,
  UpdateProductData,
} from '../../product.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { OrganizationOption } from '../../../order-import/order-import.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../shared/service/notification.service';
import { finalize } from 'rxjs/operators';
import { ProductService } from '../../product.service';
import { MatSelectChange } from '@angular/material/select';
import { PRODUCT_CODE } from '../../../shared/misc/regex';
import { FormHelper } from '../../../shared/mixin/form-helper';

@Component({
  selector: 'app-update-product-form',
  templateUrl: './update-product-form.component.html',
  styleUrls: ['./update-product-form.component.scss']
})
export class UpdateProductFormComponent extends FormHelper() implements OnInit {

  @Input() productData!: ProductData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public productOptions: Observable<OrganizationOption[]>;
  public productOptionsMap: { [key: string]: string };
  public brandOptions: BrandOption[] = [];
  public categoryOptions: CategoryOption[] = [];
  public secondaryCategoryOptions: SecondaryCategory[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private productService: ProductService,
    private notificationService: NotificationService
  ) {
    super();
    this.productOptions = new Observable();
    this.productOptionsMap = {};
  }

  ngOnInit(): void {
    this.form = this.createForm();

    this.productService
      .getBrandOptions()
      .subscribe((options) => {
        this.brandOptions = options;
      })
    this.productService
      .getCategoryOptions()
      .subscribe((options) => {
        this.categoryOptions = options;
        this.initSecondaryCategoryOptions(this.productData.primaryCategoryId);
      });
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;

    const payload: UpdateProductData = {
      title: this.form.value.title,
      description: this.form.value.description,
      productCode: this.form.value.productCode,
      ripCode: this.form.value.ripCode,
      brandId: this.form.value.brandId,
      categoryId: this.form.value.secondaryCategoryId,
      v40Enabled: this.form.value.v40Enabled,
      v100Enabled: this.form.value.v100Enabled,
      hthsEnabled: this.form.value.hthsEnabled,
      ccsEnabled: this.form.value.ccsEnabled,
    }

    this.productService
      .update(this.productData.id, payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('product.updated');
          this.dataSaved.emit({ productId: this.productData.id });
        }
      });
  }

  public onChangeCategory(event: MatSelectChange) {
    this.initSecondaryCategoryOptions(event.value);
    this.form.get('secondaryCategoryId')?.setValue(null);
  }

  private initSecondaryCategoryOptions(primaryCategoryId: string) {
    const result = this.categoryOptions.filter((cat) => {
      return cat.id === primaryCategoryId;
    }) ;

    // Should never happen.
    if (result.length === 0) {
      return;
    }

    this.secondaryCategoryOptions = result[0].secondaryCategories;
  }

  private createForm() {
    return this.fb.group({
      title: this.fb.control(this.productData.title, [
        Validators.required,
        Validators.minLength(2)
      ]),
      description: this.fb.control(this.productData.description),
      productCode: this.fb.control(this.productData.productCode,[
        Validators.required,
        Validators.pattern(PRODUCT_CODE)
      ]),
      ripCode: this.fb.control(this.productData.ripCode),
      brandId: this.fb.control(this.productData.brandId, Validators.required),
      primaryCategoryId: this.fb.control(this.productData.primaryCategoryId, Validators.required),
      secondaryCategoryId: this.fb.control(this.productData.secondaryCategoryId, Validators.required),
      v40Enabled: this.fb.control(this.productData.v40Enabled),
      v100Enabled: this.fb.control(this.productData.v100Enabled),
      hthsEnabled: this.fb.control(this.productData.hthsEnabled),
      ccsEnabled: this.fb.control(this.productData.ccsEnabled),
    });
  }
}
