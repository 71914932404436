<a routerLink="/">Home</a> / Delivery notification
<br/><br/>
<div class="row page-header">
  <div class="col title">
    <h1>Delivery Notification</h1>
  </div>
</div>
<div class="row" >
  <div class="col card-wrapper">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class=""></mat-progress-bar>
    <mat-card>
      <mat-card-header>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col form-wrapper">
            <form [formGroup]="form">
              <mat-form-field appearance="fill">
                <mat-label>Title</mat-label>
                <input type="text" matInput formControlName="title" appEmptyToNull>
                <mat-error *ngIf="hasError('title', 'required')">Title is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Description</mat-label>
                <input type="text" matInput formControlName="description" appEmptyToNull>
                <mat-error *ngIf="hasError('description', 'required')">Title is required</mat-error>
              </mat-form-field>
              <div class="form-checkbox">
                <mat-checkbox formControlName="enabled">
                  Enabled
                </mat-checkbox>
              </div>
            </form>
          </div>
        </div>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button
          mat-flat-button color="primary"
          class="button-save"
          (click)="onSubmit()"
        >
          <div class="d-flex">
            Save
            <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
          </div>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
