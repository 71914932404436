import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({name: 'fromNow'})
export class FromNowPipe implements PipeTransform {

  transform(dateISO: string | null): string | null {
    if (dateISO === null) {
      return null;
    }

    return moment(dateISO).fromNow();
  }
}
