import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataSavedEvent } from '../../news/news.model';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CardWithTabs } from '../../shared/mixin/card-with-tabs';
import { CarouselService } from '../carousel.service';
import { CarouselData } from '../carousel.model';

@Component({
  selector: 'app-manage-item',
  templateUrl: './manage-item.component.html',
  styleUrls: ['./manage-item.component.scss'],
})
export class ManageItemComponent extends CardWithTabs() implements OnInit {

  public itemId!: string;
  public itemData!: CarouselData;
  public currentTab: string = 'details';
  public isLoading: boolean = true;

  @ViewChild('cardContent') cardContent!: ElementRef<HTMLInputElement>;

  constructor(
    public carouselService: CarouselService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.itemId = this.route.snapshot.paramMap.get('id')!;

    // Used for reloading organization data when changing the
    // ID url segment. Also loads initial organization data.
    this.route.params.subscribe(params => {
      this.loadCarouselData(params.id);
    });
  }

  public onDataSaved(event: DataSavedEvent) {
    this.loadCarouselData(event.itemId);
  }

  private loadCarouselData(itemId: string) {
    this.isLoading = true;

    this.carouselService
      .find(itemId)
      .pipe(
        finalize(() => this.isLoading = false),
      ).subscribe(data => {
      this.itemData = data;
    });
  }
}
