import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-date-dialog',
  templateUrl: './remove-date-dialog.component.html',
  styleUrls: ['./remove-date-dialog.component.scss']
})
export class RemoveDateDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<RemoveDateDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: {
      id: string,
      date: string
    },
  ) {}

  onConfirm(id: string) {
    this.dialogRef.close({ id: id });
  }
}
