import { ImportStatus } from './import-history-model';

export const statusColor = (status: string) => {
  switch (status) {
    case ImportStatus.FAILED:
      return 'red';
    case ImportStatus.QUEUED:
      return 'purple';
    case ImportStatus.PROCESSING:
      return 'orange';
    case ImportStatus.FINISHED:
      return 'green';
    default:
      return 'default';
  }
}
