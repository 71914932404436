import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ArticleService } from './article.services';
import { ArticleData } from './article.model';

export class ArticleDataSource implements DataSource<ArticleData> {

  private dataSubject = new BehaviorSubject<ArticleData[]>([]);
  private dataLengthSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public data = this.dataSubject.asObservable();
  public dataLength = this.dataLengthSubject.asObservable();
  public loading = this.loadingSubject.asObservable();

  constructor(private articleService: ArticleService) {}

  connect(collectionViewer: CollectionViewer): Observable<ArticleData[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadArticles(
    pageIndex: number,
    pageSize: number,
    sortBy: string = 'name',
    sortDirection: string = 'asc',
    filter: string = '',
    filterType: string
  ) {
    this.loadingSubject.next(true);

    this.articleService
      .findPaginated(
        pageIndex,
        pageSize,
        sortBy,
        sortDirection,
        filter,
        filterType
      )
      .pipe(
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(data => {
        this.dataSubject.next(data._embedded.articles);
        this.dataLengthSubject.next(data.total_items);
      });
  }
}
