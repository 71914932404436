import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../shared/service/notification.service';
import { OrganizationService, TankSettingsService } from '../../organization.service';
import { filter, finalize, tap } from 'rxjs/operators';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { FormHelper } from '../../../shared/mixin/form-helper';
import { Observable, Subscription, switchMap } from 'rxjs';
import { dateUtils } from '../../../shared/utils/date-time';
import { POSITIVE_NUMBER, PRODUCT_CODE } from '../../../shared/misc/regex';
import { UUID_NIL } from '../../../shared/misc/constants';
import { getKeyByValue } from '../../../shared/utils/object';
import { MatDialog } from '@angular/material/dialog';
import { RemoveSettingDialogComponent } from './remove-setting-dialog/remove-setting-dialog.component';
import { environment } from '../../../../environments/environment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  CreateTankSettingsData,
  OilTypeOption,
  OrganizationData,
  OrganizationTankOption,
  TankModemOption,
  TankSettings,
  TankTypeOption,
  UpdateTankSettingsData,
} from '../../organization.model';

@Component({
  selector: 'app-organization-telemetry',
  templateUrl: './organization-telemetry.component.html',
  styleUrls: ['./organization-telemetry.component.scss']
})

export class OrganizationTelemetryComponent extends FormHelper() implements OnInit {

  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public formTitle: string | null = null;
  public showForm: boolean = false;
  public showAutoOrderSettings: boolean = false;
  public showAutoOrderEmailSettings: boolean = false;
  public tankSettingsName!: string;
  public tankSettingSelected: string | null = null;
  public tankSettingsSubscription!: Subscription;
  public tankModemOptions: Observable<TankModemOption[]>;
  public tankModemOptionsMap: { [key: string]: string };
  public tankModemOptionsLoading: boolean = false;
  public tankOptions!: OrganizationTankOption[];
  public tankOptionsLoading: boolean = false;
  public tankSettingsLoading: boolean = false;
  public tankTypeOptions: TankTypeOption[] = environment.tls.telemetry.tankTypes;
  public oilTypeOptions: OilTypeOption[] = environment.tls.telemetry.oilTypes;
  public noBulkArticleFoundForProduct: boolean = false;

  @ViewChild(MatSelectionList) sensorSelect!: MatSelectionList;
  @ViewChild('tankModemInput') tankModemInput!: ElementRef<HTMLInputElement>;

  @Input() organizationData!: OrganizationData;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private organizationService: OrganizationService,
    private tankSettingsService: TankSettingsService,
  ) {
    super();

    this.tankOptions = [];
    this.tankModemOptions = new Observable();
    this.tankModemOptionsMap = {};
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.loadTankOptions();

    this.form.get('enableAutoOrder')?.valueChanges.subscribe((enabled) => {
      this.showAutoOrderSettings = enabled;

      if (!enabled) {
        this.form.get('enableAutoOrderEmail')?.patchValue(false);
      }

      this.makeRequired([
        'autoOrderSapCode',
        'autoOrderThreshold',
        'autoOrderVolume'
      ], enabled);
    });

    this.form.get('enableAutoOrderEmail')?.valueChanges.subscribe((enabled) => {
      this.form.patchValue({
        autoOrderEmail: null,
        autoOrderEmailAdditional: null
      })
      this.showAutoOrderEmailSettings = enabled;
      this.makeRequired('autoOrderEmail', enabled);
    });

    this.setupTankModemInputListener();
  }

  public onSelectSetting(event: MatSelectionListChange): void {
    if (!event.options[0]) {
      return;
    }

    this.showForm = false;
    this.tankSettingsLoading = true;
    this.noBulkArticleFoundForProduct = false;

    // If HTML tags <mat-list-option> are on different
    // lines it will add spaces (hence the trim)
    const settingName = event.options[0].getLabel().trim();
    const settingId = event.options[0].value;

    // Cancels pending requests (prevents pileup)
    if (this.tankSettingsSubscription) {
      this.tankSettingsSubscription.unsubscribe();
    }

    this.tankSettingsSubscription = this.tankSettingsService
      .find(settingId)
      .subscribe((response) => {
        this.updateForm(response);
        this.showForm = true;
        this.tankSettingsName = response.name;
        this.tankSettingsLoading = false;
        this.tankSettingSelected = settingId;
        this.formTitle = settingName[0].toUpperCase() + settingName.substr(1);
      });
  }

  public onAddSetting(): void {
    this.sensorSelect.deselectAll();
    this.resetForm();
    this.tankSettingSelected = null;
    this.formTitle = 'New Setting';
    this.showForm = true;
  }

  public onRemoveSetting(name: string | null): void {
    // The null value has to with a template condition TS doesn't grasp.
    if (this.dialog.openDialogs.length || name === null) {
      return;
    }

    this.dialog
      .open(RemoveSettingDialogComponent, {
        data: {
          id: this.tankSettingSelected,
          name: name
        },
      })
      .afterClosed()
      .subscribe(
        data => {
          if (data?.id) {
            this.deleteTankSettings(data.id);
          }
        }
      );
  }

  public onSelectTankModemDisplayValue(modemId: string | null): string {
     return modemId && this.tankModemOptionsMap[modemId] || '';
  }

  public onToggleAutoOrder(event: MatCheckboxChange): void {
    if (event.checked) {
      this.updateSapArticleForProduct(this.form.get('productCode')?.value);
    }
  }

  public onProductCodeChange(event: KeyboardEvent): void {
    const productCode = (event.target as HTMLInputElement).value;
    this.updateSapArticleForProduct(productCode);
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;

    if (this.tankSettingSelected) {
      this.updateTankSettings(
        this.createPayload() as UpdateTankSettingsData
      );
    } else {
      this.createTankSettings(
        this.createPayload() as CreateTankSettingsData
      );
    }
  }

  private updateSapArticleForProduct(productCode: string): void {
    const sapCodeCtrl = this.form.get('autoOrderSapCode');

    this.tankSettingsService
      .findProductArticle(productCode)
      .subscribe((response) => {
        if (Object.keys(response).length === 0) {
          sapCodeCtrl?.setValue(null, { emitEvent: false });
          this.noBulkArticleFoundForProduct = true;

          return;
        }
        const displayText = `${response.title} (${response.sapCode})`
        sapCodeCtrl?.setValue(displayText, { emitEvent: false });
        this.noBulkArticleFoundForProduct = false;
      });
  }

  private createTankSettings(payload: CreateTankSettingsData): void {
    this.tankSettingsService
      .create(payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('organization.tank_settings.saved');
          this.loadTankOptions();
          this.showForm = false;
        }
      });
  }

  private updateTankSettings(payload: UpdateTankSettingsData): void {
    if (!this.tankSettingSelected) {
      return;
    }

    this.tankSettingsService
      .update(this.tankSettingSelected, payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.notificationService.success_ts('organization.tank_settings.saved');

          if (this.tankSettingsName !== payload.name) {
            this.loadTankOptions();
          }

          this.tankSettingsName = response.name;
          this.updateForm(response);
        }
      });
  }

  private deleteTankSettings(id: string): void {
    this.tankSettingsService
      .delete(id)
      .subscribe(() => {
        this.notificationService.success_ts('organization.tank_settings.deleted');
        this.loadTankOptions();
        this.showForm = false;
    });
  }

  private setupTankModemInputListener() {
    const ctrl = this.form.get('tankSensorId');

    this.tankModemOptions = ctrl!.valueChanges
      .pipe(
        // Because we use [value] for the mat-autocomplete option, it will set the
        // input value to the mat-option [value] on selection which fires a value change.
        // This is a change we don't want to trigger a lookup for, hence the workaround
        // below which checks if the input value is found in the lookup map (which means
        // an ID was set instead of capturing user input), if yes, we cancel the operation.
        filter((value) => this.tankModemOptionsMap[value] === undefined),
        switchMap((value: string) => {
          this.tankModemOptionsLoading = true;

          return this.organizationService
            .getTankModemOptions(value)
            .pipe(
              finalize(() => this.tankModemOptionsLoading = false)
            )}),
        // Create lookup map for tank modem options (to make [displayWith] work properly)
        tap((values) => {
          this.tankModemOptionsMap = {};

          values.forEach((option) => {
            this.tankModemOptionsMap[option.id] = option.name;
          });

          // When the user types a new code we check if the code exists in the lookup map
          // if yes, automatically set the sensor ID value instead of having to manually
          // select it from the dropdown (prevents a correct display value with empty value).
          const currentValueInMap = Object
            .values(this.tankModemOptionsMap)
            .includes(ctrl?.value);

          if (currentValueInMap) {
            ctrl?.setValue(getKeyByValue(this.tankModemOptionsMap, ctrl?.value), {emitEvent: false});
          }
        })
      );
  }

  private loadTankOptions(): void {
    this.tankOptionsLoading = true;

    this.organizationService
      .getOrganizationTankOptions(this.organizationData.id)
      .pipe(
        finalize(() => {
          this.tankOptionsLoading = false;
        })
      )
      .subscribe((options) => {
        this.tankOptions = options;
      });
  }

  private createPayload(): Record<string, any> {
    let payload = {
      tankType: this.form.value.tankType,
      tankSensorId: this.tankModemOptionsMap[this.form.value.tankSensorId]
        ? this.form.value.tankSensorId
        : UUID_NIL,
      name: this.form.value.name,
      description: this.form.value.description,
      productCode: this.form.value.productCode,
      oilType: this.form.value.oilType,
      rentalEndDate: this.form.value.rentalEndDate !== null
        ? dateUtils.toCalendar.fromDate(this.form.value.rentalEndDate)
        : null,
      supplierWasteOil: this.form.value.supplierWasteOil,
      alarmLevel: this.form.value.alarmLevel,
      alarmNotificationEmailAddress: this.form.value.alarmLevelNotificationEmail,
      poNumber: this.form.value.poNumber,
      enableAutoOrder: this.form.value.enableAutoOrder,
      enableAutoOrderEmail: this.form.value.enableAutoOrderEmail,
      enableAutoOrderPush: this.form.value.enableAutoOrderPush,
      autoOrderThreshold: this.form.value.autoOrderThreshold,
      autoOrderVolume: this.form.value.autoOrderVolume,
      autoOrderEmailAddress: this.form.value.autoOrderEmail,
      autoOrderEmailAddressAdditional: this.form.value.autoOrderEmailAdditional
    };

    if (!this.tankSettingSelected) {
      Object.assign(payload, {organizationId: this.organizationData.id});
    }

    return payload;
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({
      tankType: this.fb.control(null, Validators.required),
      tankSensorId: this.fb.control(null, Validators.required),
      name: this.fb.control('', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      description: this.fb.control(null),
      productCode: this.fb.control('', [
        Validators.required,
        Validators.pattern(PRODUCT_CODE)
      ]),
      oilType: this.fb.control(null, Validators.required),
      rentalEndDate: this.fb.control(null),
      supplierWasteOil: this.fb.control(''),
      alarmLevel: this.fb.control(null, Validators.pattern(POSITIVE_NUMBER)),
      alarmLevelNotificationEmail: this.fb.control(null, Validators.email),
      poNumber: this.fb.control(null),
      enableAutoOrder: this.fb.control(false),
      enableAutoOrderEmail: this.fb.control(false),
      enableAutoOrderPush: this.fb.control(false),
      // Only used for display value and notifying the user (being set or not)
      autoOrderSapCode: this.fb.control(null),
      autoOrderThreshold: this.fb.control(null, Validators.pattern(POSITIVE_NUMBER)),
      autoOrderVolume: this.fb.control(null, Validators.pattern(POSITIVE_NUMBER)),
      autoOrderEmail: this.fb.control(null, Validators.email),
      autoOrderEmailAdditional: this.fb.control(null, Validators.email)
    });
  }

  private updateForm(data: TankSettings): void {
    const tankSensor = data._embedded.tankSensor;

    // Necessary for the display value of the tank modem input.
    this.tankModemOptionsMap[tankSensor.id] = tankSensor.sensorModem;

    this.form.patchValue({
      tankType: data.tankType,
      tankSensorId: tankSensor.id,
      name: data.name,
      description: data.description,
      productCode: data.productCode,
      oilType: data.oilType,
      rentalEndDate: data.rentalEndDate,
      supplierWasteOil: data.supplierWasteOil,
      alarmLevel: data.alarmLevel,
      alarmLevelNotificationEmail: data.alarmNotificationEmailAddress,
      poNumber: data.poNumber,
      enableAutoOrder: data.autoOrderEnabled,
      enableAutoOrderEmail: data.autoOrderEmailEnabled,
      enableAutoOrderPush: data.autoOrderPushEnabled,
      autoOrderSapCode: data.autoOrderSapArticle, // Only used as a display value
      autoOrderThreshold: data.autoOrderThreshold,
      autoOrderVolume: data.autoOrderVolume,
      autoOrderEmail: data.autoOrderEmailAddress,
      autoOrderEmailAdditional: data.autoOrderEmailAddressAdditional
    });
  }

  private resetForm(): void {
    this.form.reset({}, {emitEvent: false});

    // These values mut be boolean by default.
    this.form.patchValue({
      enableAutoOrder: false,
      enableAutoOrderEmail: false,
      enableAutoOrderPush: false,
    })
  }

  private makeRequired(fields: string | string[], isRequired: boolean): void {
    if (typeof(fields) === 'string') {
      fields = [fields];
    }

    fields.forEach((key) => {
      const ctrl = this.form.get(key);

      if (isRequired) {
        ctrl!.addValidators(Validators.required);
      } else {
        ctrl!.removeValidators(Validators.required);
      }

      ctrl!.updateValueAndValidity({ emitEvent: false });
    });
  }
}
