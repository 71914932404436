<a routerLink="/">Home</a> / <a routerLink="/import-history">Import History</a> / Details
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/import-history"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>Import Details</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class=""></mat-progress-bar>
    <mat-card>
      <div *ngIf="historyData | async; let data">
        <mat-card-header>
          <mat-icon mat-card-avatar>list_alt</mat-icon>
          <mat-card-title>{{ data.id }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-md-6 form-label">Created at</div>
            <div class="col-md-6 form-text">{{ data.createdAt | date : "dd MMM yyyy hh:mm" }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Created by</div>
            <div class="col-md-6 form-text">{{ data.createdBy }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Context</div>
            <div class="col-md-6 form-text">{{ data.context }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Filename</div>
            <div class="col-md-6 form-text">{{ data.filename }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Status</div>
            <div class="col-md-6 form-text" [class]="statusClass(data.status)">{{ data.status }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Rows rejected</div>
            <div class="col-md-6 form-text" [ngClass]="{'red' : data.numErrors > 0}">{{ data.numErrors }}</div>
          </div>
          <div class="row" *ngIf="data.extraInfo"> <!-- Todo maybe use getter for this scenario -->
            <div class="col-md-6 form-label">Extra info</div>
            <div class="col-md-6 form-text">
              <textarea
                readonly
                [ngModel]="formatExtraInfo(data.extraInfo)"
                class="extra-info"
                rows="10"
              >
              </textarea>
            </div>
          </div>
          <div class="row" *ngIf="data.errorLog">
            <div class="validation-error-box">
              <div class="error-list">
                <div class="rows" *ngFor="let rowErrors of data.errorLog">
                  Row <span class="row-nr">{{ rowErrors.row_nr }}</span>
                  <br/>
                  <div class="cols" *ngFor="let colError of rowErrors.errors">
                    <span class="col-error">Column <span class="col-name">{{ colError.col }}:</span> {{ colError.msg }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>
