import { Injectable, isDevMode } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

interface ErrorParamList {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorFormatterService {
  private static readonly DEFAULT_MESSAGE = 'Something went wrong';
  private static readonly NO_CONNECTIVITY = 'No internet connection';
  private static readonly FORBIDDEN = 'Not authorized';
  private static readonly SERVER_MESSAGE_NAMESPACE = '_server';

  constructor(private translateService: TranslateService) {
  }

  public formatServerError(response: HttpErrorResponse): string {
    if (!navigator.onLine) {
      return ErrorFormatterService.NO_CONNECTIVITY;
    }

    if (response.status === HttpStatusCode.Forbidden) {
      return ErrorFormatterService.FORBIDDEN;
    }

    if (response.error?.code) {
      return this.translateErrorCode(response.error.code, response.error?.params || []);
    }

    if (isDevMode()) {
      console.error(response);
    }

    return ErrorFormatterService.DEFAULT_MESSAGE;
  }

  public formatClientError(error: Error): string {
    return error.toString();
  }

  private translateErrorCode(code: string, params: ErrorParamList) {
    const key = ErrorFormatterService.SERVER_MESSAGE_NAMESPACE + '.' + code;
    const translated = this.translateService.instant(key, params);

    // If the key couldn't be translated, strip off the
    // namespace part, so we can return the original code.
    if (translated === key) {
      return translated.substring(translated.indexOf('.') + 1)
    }

    return translated;
  }
}
