<div class="tab-content-wrapper">
  <div class="row mb-2">
    <span class="form-section-title">Header Image</span>
    <br/><br/>
    <div class="col-6 form-wrapper">
      <div
        class="image-preview d-flex justify-content-center align-items-center"
        (mouseenter)="showDeleteIconHeaderImage = true"
        (mouseleave)="showDeleteIconHeaderImage = false"
      >
        <img [src]="headerImage.href" *ngIf="headerImage"/>
        <mat-icon
          *ngIf="headerImage && showDeleteIconHeaderImage"
          mat-list-icon
          class="button-delete float-end"
          (click)="onRemove('header')"
        >
          delete
        </mat-icon>
        <span *ngIf="!headerImage" class="no-image">No header image uploaded</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col form-wrapper">
      <mat-form-field class="image-input">
        <div>
          <mat-toolbar>
            <!-- Display filename -->
            <input matInput [(ngModel)]="fileAttrHeader" readonly/>
            <!-- Browse Button -->
            <button mat-flat-button color="primary">Browse File</button>
          </mat-toolbar>
          <input
            type="file"
            id="uploadFile1"
            (change)="onSelect($event, 'header')"
            name="uploadFile1"
            multiple="multiple"
            accept="image/*"
          />
        </div>
      </mat-form-field>
      <br/>
      <span class="file-type-info">Supported file types: jpeg, png</span>
    </div>
  </div>
  <br/><br/>
  <div class="row mb-2">
    <span class="form-section-title">Summary Image</span>
    <br/><br/>
    <div class="col-6 form-wrapper">
      <div
        class="image-preview d-flex justify-content-center align-items-center"
        (mouseenter)="showDeleteIconSummaryImage = true"
        (mouseleave)="showDeleteIconSummaryImage = false"
      >
        <img [src]="summaryImage.href" *ngIf="summaryImage"/>
        <mat-icon
          *ngIf="summaryImage && showDeleteIconSummaryImage"
          mat-list-icon
          class="button-delete float-end"
          (click)="onRemove('summary')"
        >
          delete
        </mat-icon>
        <span *ngIf="!summaryImage" class="no-image">No summary image uploaded</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col form-wrapper">
      <mat-form-field class="image-input">
        <div>
          <mat-toolbar>
            <!-- Display filename -->
            <input matInput [(ngModel)]="fileAttrSummary" readonly/>
            <!-- Browse Button -->
            <button mat-flat-button color="primary">Browse File</button>
          </mat-toolbar>
          <input
            type="file"
            id="uploadFile2"
            (change)="onSelect($event, 'summary')"
            name="uploadFile2"
            multiple="multiple"
            accept="image/*"
          />
        </div>
      </mat-form-field>
      <span class="file-type-info">Supported file types: jpeg, png</span>
    </div>
  </div>
</div>
