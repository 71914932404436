<a routerLink="/">Home</a> / <a routerLink="/organizations">Organizations</a> / Create
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/organizations"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>New Organization</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar>business</mat-icon>
        <mat-card-title>New</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col form-wrapper">
            <form [formGroup]="form">
              <mat-form-field appearance="fill">
                <mat-label>Name</mat-label>
                <input type="text" matInput formControlName="name">
                <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Customer code</mat-label>
                <input type="text" matInput formControlName="customerCode" maxlength="10">
                <mat-error *ngIf="hasError('customerCode', 'required')">Customer code is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Parent organization (optional)</mat-label>
                <input
                  type="text"
                  placeholder="Type name or customer code .."
                  aria-label="Number"
                  matInput
                  formControlName="parentOrganizationId"
                  [matAutocomplete]="auto">
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="onSelectOrganizationDisplayValue.bind(this)"
                >
                  <mat-option *ngFor="let option of organizationOptions | async" [value]="option.id">
                    {{ option.name }} ({{ option.code }})
                  </mat-option>
                </mat-autocomplete>
                <mat-icon *ngIf="organizationOptionsLoading" class="input-loading-spinner">
                  <mat-spinner diameter="20">
                  </mat-spinner>
                </mat-icon>
              </mat-form-field>
              <div class="form-checkbox">
                <mat-checkbox formControlName="active" >
                  Active
                </mat-checkbox>
              </div>
            </form>
          </div>
        </div>
        <br/>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button
          mat-flat-button color="primary"
          class="button-save"
          (click)="onSubmit()"
        >
          <div class="d-flex">
            Save
            <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
          </div>
        </button>
        <button
          mat-flat-button color="secondary"
          routerLink="/organizations"
        >
          Cancel
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
