import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormHelper } from '../../shared/mixin/form-helper';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SegmentOption } from '../../organization/organization.model';
import { NotificationService } from '../../shared/service/notification.service';
import { filter, finalize } from 'rxjs/operators';
import { CreateNewsData } from '../news.model';
import { NewsService } from '../news.service';
import { OrganizationService } from '../../organization/organization.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipGrid } from '@angular/material/chips';
import { dateUtils } from '../../shared/utils/date-time';
import { TinyMceUtil } from '../../core/utils/tiny-mce.util';

// Todo do images/files get removed after removing article/product/any-cms-item ?

@Component({
  selector: 'app-create-item-form',
  templateUrl: './create-item-form.component.html',
  styleUrls: ['./create-item-form.component.scss'],
})
export class CreateItemFormComponent extends FormHelper() implements OnInit {
  @ViewChild('segmentsInput') segmentsInput!: ElementRef<HTMLInputElement>;
  @ViewChild('segmentsChipList') chipList!: MatChipGrid;

  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public segmentOptions: Observable<SegmentOption[]>;
  public segmentOptionsLoading: boolean = false;
  public segmentsSelected: Map<string, string>;
  public readonly tinyMceSettings = TinyMceUtil.getEditorSettings('News article text');

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private newsService: NewsService,
    private organizationService: OrganizationService,
    private notificationService: NotificationService,
  ) {
    super();
    this.segmentOptions = new Observable();
    this.segmentsSelected = new Map();
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.setupSegmentInputListener();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    const isValid = this.checkSegments() && this.form.valid;

    if (!isValid) {
      return;
    }

    this.isSaving = true;

    const payload: CreateNewsData = {
      title: this.form.value.title,
      description: this.form.value.shortDescription,
      content: this.form.value.content,
      segments: [...this.segmentsSelected.keys()],
      dateFrom:
        this.form.value.dateFrom !== null
          ? dateUtils.toCalendar.fromDate(this.form.value.dateFrom)
          : null,
      dateTo:
        this.form.value.dateTo !== null
          ? dateUtils.toCalendar.fromDate(this.form.value.dateTo)
          : null,
      sendNotification: this.form.value.sendNotification,
      published: this.form.value.published,
    };

    this.newsService
      .create(payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        }),
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('news_item.created');
          this.router.navigateByUrl('/news');
        },
      });
  }

  public onSegmentInputLeave() {
    this.checkSegments();
  }

  public checkSegments(): boolean {
    this.chipList.errorState = this.segmentsSelected.size === 0;

    return this.segmentsSelected.size > 0;
  }

  public onSelectSegment(event: MatAutocompleteSelectedEvent): void {
    this.addSegment(event.option.value);
    this.segmentsInput.nativeElement.value = '';
    this.checkSegments();
  }

  public onRemoveSegment(key: string): void {
    if (!this.segmentsSelected.get(key)) {
      return;
    }

    this.segmentsSelected.delete(key);
    this.checkSegments();
  }

  private addSegment(option: SegmentOption): void {
    if (this.segmentsSelected.get(option.id)) {
      return;
    }

    this.segmentsSelected.set(option.id, option.name);
  }

  private setupSegmentInputListener() {
    const ctrl = this.form.get('segments');
    this.segmentOptions = ctrl!.valueChanges.pipe(
      filter(() => this.segmentsInput.nativeElement.value !== ''),
      switchMap((value: string | SegmentOption) => {
        // Because we use [value] for the mat-autocomplete option, it will set the input
        // value to the mat-option [value] on selection which is not desirable in our case
        // hence the type check below (if we don't receive a string, reset the options).
        const filter = typeof value === 'string' ? value : null;
        this.segmentOptionsLoading = filter !== null;

        return this.organizationService
          .getSegmentOptions(filter)
          .pipe(finalize(() => (this.segmentOptionsLoading = false)));
      }),
    );
  }

  private createForm() {
    return this.fb.group({
      title: this.fb.control('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      shortDescription: this.fb.control('', Validators.required),
      content: this.fb.control('', Validators.required),
      dateFrom: this.fb.control(null),
      dateTo: this.fb.control(null),
      segments: this.fb.control(''), // actual value not used
      sendNotification: this.fb.control(false),
      published: this.fb.control(false),
    });
  }
}
