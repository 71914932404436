<div class="tab-content-wrapper">
  <div class="row">
    <div class="col" *ngIf="!organizationData.activateTelemetry; else telemetrySettings">
      <div class="not-activated">Telemetry is not activated for this organization</div>
    </div>
    <ng-template #telemetrySettings>
      <div class="col left-col-fixed">
        <mat-selection-list
          [multiple]="false"
          (selectionChange)="onSelectSetting($event)"
        >
          <div
            *ngIf="tankOptionsLoading"
            class="h-100 d-flex justify-content-center align-items-center">
            <mat-icon>
              <mat-spinner diameter="30">
              </mat-spinner>
            </mat-icon>
          </div>
          <mat-list-option *ngFor="let sensor of tankOptions" [value]="sensor.id">
            {{ sensor.name }}
          </mat-list-option>
          <div
            *ngIf="!tankOptionsLoading && tankOptions.length === 0"
            class="h-100 d-flex justify-content-center align-items-center no-tank-settings"
          >
            <span>No tank settings</span>
          </div>
        </mat-selection-list>
        <div class="add-sensor-wrapper">
          <button
            mat-stroked-button
            matTooltip="Add new setting"
            class="button-add"
            (click)="onAddSetting()"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div class="col right-col-fluid">
        <div
          *ngIf="tankSettingsLoading"
          class="h-100 d-flex justify-content-center align-items-center"
        >
          <mat-icon>
            <mat-spinner diameter="30">
            </mat-spinner>
          </mat-icon>
        </div>
        <div
          *ngIf="!showForm && !tankOptionsLoading"
          class="h-100 d-flex justify-content-center align-items-center no-tank-settings"
        >
          <div *ngIf="tankOptions.length === 0; else selectMessage">
            Click on the <mat-icon class="align-middle">add</mat-icon> button below the selection list to add new tank settings
          </div>
          <ng-template #selectMessage>
            Choose tank setting
          </ng-template>
        </div>
        <div class="form-wrapper" *ngIf="showForm">
          <div class="settings-title">
            <strong>{{ formTitle }}</strong>
            <mat-icon
              *ngIf="tankSettingSelected"
              class="align-middle"
              (click)="onRemoveSetting(formTitle)"
            >
              delete
            </mat-icon>
          </div>
          <form [formGroup]="form">
            <mat-form-field appearance="fill">
              <mat-label>Tank type</mat-label>
              <mat-select formControlName="tankType">
                <mat-option *ngFor="let option of tankTypeOptions" [value]="option.id">
                  <!-- Todo use translation service to translate the code to a name for the current locale/language -->
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('tankType', 'required')">Tank type is required</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Sensor modem</mat-label>
              <input
                #tankModemInput
                type="text"
                placeholder="Type to search .."
                aria-label="Number"
                matInput
                formControlName="tankSensorId"

                [matAutocomplete]="auto">
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="onSelectTankModemDisplayValue.bind(this)"
              >
                <mat-option *ngFor="let option of tankModemOptions | async" [value]="option.id">
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="hasError('tankSensorId', 'required')">Tank sensor is required</mat-error>
              <mat-icon *ngIf="tankModemOptionsLoading" class="input-loading-spinner">
                <mat-spinner diameter="20">
                </mat-spinner>
              </mat-icon>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Display name</mat-label>
              <input
                type="text"
                formControlName="name"
                placeholder="Provide a short name"
                matInput
                appEmptyToNull
              >
              <mat-error *ngIf="hasError('name', 'required')">Display name is required</mat-error>
              <mat-error *ngIf="hasError('name', 'maxlength')">Display name can have a maximum length of 30 characters</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Description</mat-label>
              <input
                type="text"
                formControlName="description"
                placeholder="Provide a broader description about the tank or sensor"
                matInput
                appEmptyToNull
              >
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Product code</mat-label>
              <input type="text" matInput formControlName="productCode" (keyup)="onProductCodeChange($event)" appEmptyToNull>
              <mat-error *ngIf="hasError('productCode', 'required')">Product code is required</mat-error>
              <mat-error *ngIf="hasError('productCode', 'pattern')">Product code can only contain digits (without leading zeroes)</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Oil type</mat-label>
              <mat-select formControlName="oilType">
                <mat-option *ngFor="let option of oilTypeOptions" [value]="option.id">
                  <!-- Todo use translation service to translate the code to a name for the current locale/language -->
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('oilType', 'required')">Oil type is required</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Rental end date</mat-label>
              <input
                matInput
                [matDatepicker]="datePicker"
                [readonly]="true"
                formControlName="rentalEndDate"
              >
              <mat-datepicker-toggle
                matSuffix
                [for]="datePicker"
              >
              </mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Supplier waste oil</mat-label>
              <input type="text" matInput formControlName="supplierWasteOil" appEmptyToNull>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Alarm level</mat-label>
              <input type="number" matInput formControlName="alarmLevel">
              <mat-error *ngIf="hasError('alarmLevel', 'pattern')">Alarm level must be a positive number</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Alarm notification email</mat-label>
              <input type="text" matInput formControlName="alarmLevelNotificationEmail" appEmptyToNull>
              <mat-error *ngIf="hasError('alarmLevelNotificationEmail', 'email')">Invalid email address</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>PO number</mat-label>
              <input type="text" matInput formControlName="poNumber" appEmptyToNull>
            </mat-form-field>
            <div class="form-checkbox">
              <mat-checkbox formControlName="enableAutoOrder" (change)="onToggleAutoOrder($event)">
                Enable auto-order
              </mat-checkbox>
            </div>
            <div *ngIf="showAutoOrderSettings">
              <mat-error *ngIf="noBulkArticleFoundForProduct">
                <mat-icon class="nav-list-icon">warning</mat-icon> No bulk article found for product code {{ this.form.get('productCode')?.value ?? '[none]' }}
              </mat-error>
              <mat-form-field appearance="fill" class="auto-order-sap-article">
                <mat-label>Auto-order SAP article</mat-label>
                <input type="text" matInput formControlName="autoOrderSapCode" readonly appEmptyToNull>
                <mat-error *ngIf="hasError('autoOrderSapCode', 'required')">Sap article is required (please enter a product code that has an associated BULK article)</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Auto-order Threshold</mat-label>
                <input type="number" matInput formControlName="autoOrderThreshold">
                <mat-error *ngIf="hasError('autoOrderThreshold', 'required')">Threshold is required</mat-error>
                <mat-error *ngIf="hasError('autoOrderThreshold', 'pattern')">Threshold must be a positive number</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Auto-order volume</mat-label>
                <input type="number" matInput formControlName="autoOrderVolume">
                <mat-error *ngIf="hasError('autoOrderVolume', 'required')">Volume is required</mat-error>
                <mat-error *ngIf="hasError('autoOrderVolume', 'pattern')">Volume must be a positive number</mat-error>
              </mat-form-field>
              <div class="form-checkbox">
                <mat-checkbox formControlName="enableAutoOrderPush" >
                  Enable auto-order push
                </mat-checkbox>
              </div>
              <div class="form-checkbox">
                <mat-checkbox formControlName="enableAutoOrderEmail" >
                  Enable auto-order email
                </mat-checkbox>
              </div>
              <div *ngIf="showAutoOrderEmailSettings">
                <mat-form-field appearance="fill">
                  <mat-label>Auto-order email address</mat-label>
                  <input type="text" matInput formControlName="autoOrderEmail" appEmptyToNull>
                  <mat-error *ngIf="hasError('autoOrderEmail', 'email')">Invalid email address</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Auto-order additional email address</mat-label>
                  <input type="text" matInput formControlName="autoOrderEmailAdditional" appEmptyToNull>
                  <mat-error *ngIf="hasError('autoOrderEmailAdditional', 'email')">Invalid email address</mat-error>
                </mat-form-field>
              </div>
            </div>
            <br/>
            <button
              mat-flat-button color="primary"
              class="button-save"
              [disabled]="tankModemOptionsLoading"
              (click)="onSubmit()"
            >
              <div class="d-flex">
                Save
                <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
              </div>
            </button>
          </form>
        </div>
      </div>
    </ng-template>
  </div>
</div>

