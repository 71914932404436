import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ProductData } from './product.model';
import { ProductService } from './product.service';

export class ProductDataSource implements DataSource<ProductData> {

  private dataSubject = new BehaviorSubject<ProductData[]>([]);
  private dataLengthSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public data = this.dataSubject.asObservable();
  public dataLength = this.dataLengthSubject.asObservable();
  public loading = this.loadingSubject.asObservable();

  constructor(private productService: ProductService) {}

  connect(collectionViewer: CollectionViewer): Observable<ProductData[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadProducts(
    pageIndex: number,
    pageSize: number,
    sortBy: string = 'title',
    sortDirection: string = 'asc',
    filter: string = '',
    filterType: string
  ) {
    this.loadingSubject.next(true);

    this.productService
      .findPaginated(
        pageIndex,
        pageSize,
        sortBy,
        sortDirection,
        filter,
        filterType
      )
      .pipe(
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(data => {
        this.dataSubject.next(data._embedded.products);
        this.dataLengthSubject.next(data.total_items);
      });
  }
}
