import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { OrderData } from '../order-history.model';
import { OrderHistoryService } from '../order-history.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  public orderId!: string;
  public orderData: Observable<OrderData>;
  public isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    public orderHistoryService: OrderHistoryService,
  ) {
    this.orderData = new Observable<OrderData>();
  }

  ngOnInit(): void {
    this.orderId = this.route.snapshot.paramMap.get('id')!;
    this.orderData = this.orderHistoryService
      .find(this.orderId)
      .pipe(
        finalize( () => this.isLoading = false),
      );
  }
}
