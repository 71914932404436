<a routerLink="/">Home</a> / Sap Communication <br /><br />
<div class="row page-header">
  <div class="col title">
    <h1>Sap Communication</h1>
  </div>
</div>
<div class="grid-controls">
  <div class="search">
    <mat-form-field class="w-100 no-subscript" appearance="outline">
      <mat-label>Search by order nr</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="searchText"
        (keyup)="onSearch()"
      />
      <button
        *ngIf="searchText"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="onSearchClear()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="table-wrapper mat-elevation-z3">
      <div class="table-progress-overlay" *ngIf="dataSource.loading | async">
        <mat-progress-spinner mode="indeterminate" diameter="50">
        </mat-progress-spinner>
      </div>
      <table
        [dataSource]="dataSource"
        mat-table
        matSort
        [matSortActive]="sortActiveDefault"
        [matSortDirection]="sortDirectionDefault"
        matSortDisableClear
      >
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date : "dd MMM yyyy hh:mm" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
          <td mat-cell *matCellDef="let element">{{ element.createdBy }}</td>
        </ng-container>
        <ng-container matColumnDef="orderNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Order nr</th>
          <td mat-cell *matCellDef="let element">{{ element.orderNumber }}</td>
        </ng-container>
        <ng-container matColumnDef="orderType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Order type</th>
          <td mat-cell *matCellDef="let element">{{ element.orderType }}</td>
        </ng-container>
        <ng-container matColumnDef="sentToSap">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sent to SAP</th>
          <td
            mat-cell
            [class]="statusColor(element.sentToSap)"
            *matCellDef="let element"
          >
            {{ element.sentToSap ? "Yes" : "No" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="fullyAccepted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Fully accepted
          </th>
          <td
            mat-cell
            [class]="statusColor(element.fullyAccepted)"
            *matCellDef="let element"
          >
            {{ element.fullyAccepted ? "Yes" : "No" }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-data" [attr.colspan]="tableColumns.length">
            No data
          </td>
        </tr>
      </table>
      <mat-paginator
        [length]="totalItems"
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>
