import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountManagerOverviewComponent } from './account-manager-overview/account-manager-overview.component';
import { AccountManagerDetailsComponent } from './account-manager-details/account-manager-details.component';
import { UpdateAccountManagerFormComponent } from './update-account-manager-form/update-account-manager-form.component';
import { CreateAccountManagerFormComponent } from './create-account-manager-form/create-account-manager-form.component';

const routes: Routes = [
  {
    path: 'account-managers',
    component: AccountManagerOverviewComponent
  },
  {
    path: 'account-managers/create',
    component: CreateAccountManagerFormComponent
  },
  {
    path: 'account-managers/:id',
    component: AccountManagerDetailsComponent
  },
  {
    path: 'account-managers/:id/edit',
    component: UpdateAccountManagerFormComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountManagerRoutingModule { }
