import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AccountData, DataSavedEvent } from '../account.model';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CardWithTabs } from '../../shared/mixin/card-with-tabs';
import { AccountService } from '../account.service';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss'],
})
export class ManageAccountComponent extends CardWithTabs() implements OnInit {

  public itemId!: string;
  public accountData!: AccountData;
  public currentTab: string = 'details';
  public isLoading: boolean = true;

  @ViewChild('cardContent') cardContent!: ElementRef<HTMLInputElement>;

  constructor(
    public accountService: AccountService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.itemId = this.route.snapshot.paramMap.get('id')!;

    // Used for reloading organization data when changing the
    // ID url segment. Also loads initial organization data.
    this.route.params.subscribe(params => {
      this.loadAccountData(params.id);
    });
  }

  public onDataSaved(event: DataSavedEvent) {
    this.loadAccountData(event.id);
  }

  private loadAccountData(itemId: string) {
    this.isLoading = true;

    this.accountService
      .find(itemId)
      .pipe(
        finalize(() => this.isLoading = false),
      ).subscribe(data => {
      this.accountData = data;
    });
  }
}
