import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../../../shared/mixin/form-helper';
import {
  AddressCountryOption,
  AddressSavedEvent,
  AddressUpdateCancelledEvent,
  CreateOrganizationAddressData,
  OrganizationAddress,
  UpdateOrganizationAddressData,
} from '../../../organization.model';
import { environment } from '../../../../../environments/environment';
import { OrganizationService } from '../../../organization.service';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../../../shared/service/notification.service';
import { RemoveDialogComponent } from './remove-dialog/remove-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent extends FormHelper() implements OnInit {
  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public addressCountryOptions: AddressCountryOption[] = environment.tls.organization.countryOptions;

  @Input() organizationId!: string;
  @Input() addressData!: OrganizationAddress | undefined;
  @Input() addressType!: string;
  @Output() addressSaved: EventEmitter<AddressSavedEvent> = new EventEmitter();
  @Output() addressUpdateCancelled: EventEmitter<AddressUpdateCancelledEvent> = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private organizationService: OrganizationService,
    private notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.createForm();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    if (this.addressData) {
      this.updateAddress(this.addressData.id, this.form.value);
    } else {
      this.createAddress({ type: this.addressType, ...this.form.value });
    }
  }

  public onCancel(): void {
    this.addressUpdateCancelled.emit({});
  }

  public onRemove(): void {
    if (this.dialog.openDialogs.length) {
      return;
    }

    this.dialog
      .open(RemoveDialogComponent, {
        data: {
          id: this.addressData?.id,
        },
      })
      .afterClosed()
      .subscribe(
        data => {
          if (data?.id) {
            this.removeAddress(data.id);
          }
        }
      );
  }

  private createAddress(payload: CreateOrganizationAddressData): void {
    this.isSaving = true;

    this.organizationService
      .createAddress(this.organizationId, payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('organization.address.created');
          this.addressSaved.emit({});
        }
      });
  }

  private updateAddress(addressId: string, payload: UpdateOrganizationAddressData): void {
    this.isSaving = true;

    this.organizationService
      .updateAddress(this.organizationId, addressId, payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('organization.address.updated');
          this.addressSaved.emit({ addressId: addressId });
        }
      });
  }

  private removeAddress(addressId: string): void {
    this.organizationService
      .deleteAddress(this.organizationId, addressId)
      .subscribe((next) => {
        this.notificationService.success_ts('organization.address.deleted');
        this.addressSaved.emit({addressId: addressId});
      })
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({
      addressLine1: this.fb.control(this.addressData?.addressLine1, Validators.required),
      addressLine2: this.fb.control(this.addressData?.addressLine2),
      addressLine3: this.fb.control(this.addressData?.addressLine3),
      postalCode: this.fb.control(this.addressData?.postalCode),
      zip: this.fb.control(this.addressData?.postalCode, Validators.required),
      city: this.fb.control(this.addressData?.city, Validators.required),
      country: this.fb.control(this.addressData?.country, Validators.required),
    });
  }
}
