<a routerLink="/">Home</a> / <a routerLink="/products">Products</a> / New Product
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/products"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>New Product</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar>shopping_basket</mat-icon>
        <mat-card-title>New</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col form-wrapper">
            <form [formGroup]="form">
              <mat-form-field appearance="fill">
                <mat-label>Title</mat-label>
                <input formControlName="title" name="title" matInput>
                <mat-error *ngIf="hasError('title', 'required')">Title is required</mat-error>
                <mat-error *ngIf="hasError('title', 'minlength')">Title must contain a minimum of 2 characters</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Description</mat-label>
                <input formControlName="description" name="description" matInput appEmptyToNull>
                <mat-error *ngIf="hasError('description', 'required')">Description is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Product code</mat-label>
                <input
                  formControlName="productCode"
                  name="productCode"
                  maxlength="10"
                  matInput
                >
                <mat-error *ngIf="hasError('productCode', 'required')">Product code is required</mat-error>
                <mat-error *ngIf="hasError('productCode', 'pattern')">Invalid format, expected digits (not starting with zero)</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>RIP code</mat-label>
                <input formControlName="ripCode" name="ripCode" matInput appEmptyToNull>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Brand</mat-label>
                <mat-select formControlName="brandId">
                  <mat-option *ngFor="let option of brandOptions" [value]="option.id">
                    {{ option.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('brandId', 'required')">Brand is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Primary category</mat-label>
                <mat-select
                  formControlName="primaryCategoryId"
                  (selectionChange)="onChangeCategory($event)"
                >
                  <mat-option *ngFor="let option of categoryOptions" [value]="option.id">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('primaryCategoryId', 'required')">Primary category is required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Secondary category</mat-label>
                <mat-select formControlName="secondaryCategoryId">
                  <mat-option *ngFor="let option of secondaryCategoryOptions" [value]="option.id">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('secondaryCategoryId', 'required')">Secondary category is required</mat-error>
              </mat-form-field>
              <br/><br/>
              <mat-slide-toggle formControlName="v40Enabled" name="v40Enabled">
                V40 enabled
              </mat-slide-toggle>
              <br/><br/>
              <mat-slide-toggle formControlName="v100Enabled" name="v100Enabled">
                V100 enabled
              </mat-slide-toggle>
              <br/><br/>
              <mat-slide-toggle formControlName="hthsEnabled" name="hthsEnabled">
                HTHS enabled
              </mat-slide-toggle>
              <br/><br/>
              <mat-slide-toggle formControlName="ccsEnabled" name="ccsEnabled">
                CCS enabled
              </mat-slide-toggle>
            </form>
          </div>
        </div>
        <br/>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button
          mat-flat-button color="primary"
          class="button-save"
          (click)="onSubmit()"
        >
          <div class="d-flex">
            Save
            <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
          </div>
        </button>
        <button
          mat-flat-button color="secondary"
          routerLink="/products"
        >
          Cancel
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
