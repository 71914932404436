<div class="tab-content-wrapper">
  <div class="row">
    <div class="col-md-6 form-label">Name</div>
    <div class="col-md-6 form-text">{{ organizationData.name }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Code</div>
    <div class="col-md-6 form-text">{{ organizationData.customerCode }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Address</div>
    <div class="col-md-6 form-text">
      <!--
      Currently an organization can have a related address with empty fields
      Which has to do with some synchronization feature in the back-end.
      -->
      <span *ngIf="businessAddress?.addressLine1">
        {{ businessAddress?.addressLine1 }}
      </span>
      <span *ngIf="businessAddress?.addressLine2">
        <br/>
        {{ businessAddress?.addressLine2 }}
      </span>
      <span *ngIf="businessAddress?.addressLine3">
        <br/>
        {{ businessAddress?.addressLine3 }}
      </span>
      <span *ngIf="!businessAddress">
        -
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Zip</div>
    <div class="col-md-6 form-text">{{ businessAddress?.postalCode || '-' }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">City</div>
    <div class="col-md-6 form-text">{{ businessAddress?.city || '-' }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Country</div>
    <div class="col-md-6 form-text">{{ businessAddress?.country || '-' }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Phone number</div>
    <div class="col-md-6 form-text">{{ organizationData.phoneNumber || '-' }}</div>
  </div>
  <div *ngIf="organizationData.parentId">
    <div class="row">
      <div class="col-md-6 form-label">Part of</div>
      <div class="col-md-6 form-text">{{ organizationData.parentName }}</div>
    </div>
    <div class="row">
      <div class="col-md-6 form-label">Parent code</div>
      <div class="col-md-6 form-text"><a routerLink="/organizations/{{ organizationData.parentId}}">{{ organizationData.parentCode }}</a></div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Created</div>
    <div class="col-md-6 form-text">{{ organizationData.createdAt | date : "dd MMM yyyy hh:mm" }}</div>
  </div>
  <div class="row">
  <div class="col-md-6 form-label">Last synced (SAP)</div>
  <div class="col-md-6 form-text">{{ (organizationData.sapLastSync | date : "dd MMM yyyy hh:mm") || '-' }}</div>
    <div class="row">
      <div class="col-md-6 form-label">Active</div>
      <div class="col-md-6 form-text">{{ organizationData.active ? 'Yes' : 'No' }}</div>
    </div>
  </div>
</div>
