<h1 mat-dialog-title>Remove organization</h1>
<div mat-dialog-content>
  Are you sure you want to remove the following organization ?
  <br/><br/>
  <strong>{{data.name}}</strong>
</div>
<div mat-dialog-actions >
  <button (click)="onConfirm(data.id)" mat-button cdkFocusInitial>Ok</button>
  <button mat-button mat-dialog-close>Cancel</button>
</div>

