import ApigilityResult from '../shared/model/apigility-result.model';
import { ArticleData } from '../article/article.model';
import NameIdOption from '../shared/model/misc';

export interface ProductCollectionResponse extends ApigilityResult {
  _embedded: {
    products: ProductData[],
  };
}

export interface ProductData {
  id: string;
  title: string
  createdAt: string;
  updatedAt: string;
  description: string | null;
  productCode: string;
  ripCode: string | null;
  brandId: string;
  brandName: string;
  primaryCategoryId: string;
  primaryCategoryLabel: string;
  secondaryCategoryId: string;
  secondaryCategoryLabel: string;
  personalProduct: boolean;
  showNotification: boolean;
  replaceOrderWithEquivalent: boolean;
  v40Enabled: boolean;
  v100Enabled: boolean;
  hthsEnabled: boolean;
  ccsEnabled: boolean;
  uploadTDS?: File;
  uploadSDS?: File;
  article?: ArticleData;
  _links?: {
    self: {
      href: string;
    },
    tds: {
      id: string;
      href: string;
    },
    sds: {
      id: string;
      href: string;
    }
  }
}

export interface CreateProductData {
  title: string;
  description: string | null;
  productCode: string;
  ripCode: string | null;
  brandId: string;
  categoryId: string;
  v40Enabled: boolean;
  v100Enabled: boolean;
  hthsEnabled: boolean;
  ccsEnabled: boolean;
}

export interface UpdateProductData extends CreateProductData {}

export interface BrandOption extends NameIdOption {}

export interface CategoryOption {
  id: string;
  label: string;
  secondaryCategories: SecondaryCategory[]
}

export interface SecondaryCategory {
  id: string;
  label: string;
}

export interface File {
  name: string,
  mimeType: string,
  size: number,
  url: string
}

export interface DataSavedEvent {
  productId: string
}

export class DataSheetType {
  public static readonly TDS = 'tds'
  public static readonly SDS = 'sds'
}
