<a routerLink="/">Home</a> / <a routerLink="/products">Products</a> / Manage
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/products"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>Manage Product</h1>
  </div>
</div>
<div class="row">
  <div class="col card-wrapper">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class=""></mat-progress-bar>
    <mat-card>
      <div *ngIf="productData">
        <mat-card-header>
          <mat-icon mat-card-avatar>shopping_basket</mat-icon>
          <mat-card-title>{{ productData.title }}</mat-card-title>
        </mat-card-header>
        <mat-card-content id="card1" #cardContent>
          <mat-tab-group
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            animationDuration="0"
            (selectedIndexChange)="onBeforeTabChange($event)"
            (selectedTabChange)="onTabChange($event)"
            #matTabGroup
          >
            <mat-tab label="Details">
              <app-product-details
                *ngIf="currentTab === 'details'"
                [productData]="productData"
              >
              </app-product-details>
            </mat-tab>
            <mat-tab label="Attributes">
              <app-update-product-form
                *ngIf="currentTab === 'attributes'"
                (dataSaved)="onDataSaved($event)"
                [productData]="productData"
              >
              </app-update-product-form>
            </mat-tab>
            <mat-tab label="Datasheets">
              <app-product-data-sheets
                *ngIf="currentTab === 'datasheets'"
                (dataSaved)="onDataSaved($event)"
                [productData]="productData"
              >
              </app-product-data-sheets>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>
