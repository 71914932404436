import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { VideoData } from '../video.model';
import { VideoService } from '../video.service';

@Component({
  selector: 'app-video-details',
  templateUrl: './video-details.component.html',
  styleUrls: ['./video-details.component.scss']
})
export class VideoDetailsComponent implements OnInit {

  public videoId!: string;
  public videoData: Observable<VideoData>;
  public isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    public videoService: VideoService,
  ) {
    this.videoData = new Observable<VideoData>();
  }

  ngOnInit(): void {
    this.videoId = this.route.snapshot.paramMap.get('id')!;
    this.videoData = this.videoService
      .find(this.videoId)
      .pipe(
        finalize( () => this.isLoading = false),
      );
  }
}
