import { Directive, HostListener, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { formatNumber } from '@angular/common';

// Can be attached to a text input like:
// <input type="number" [minFractionDigits]="2" [maxFractionDigits]="3" appFormatDecimal>

@Directive({
  selector: '[appFormatDecimal]'
})
export class FormatDecimalDirective {
  // desired locale for this use case (it's also
  // the default for the number formatter though).
  readonly formatLocale = 'en_US';
  @Input() minFractionDigits!: number;
  @Input() maxFractionDigits!: number;

  constructor(@Self() private ngControl: NgControl) {}

  @HostListener('blur', ['$event']) onBlur() {

    if (!this.ngControl.value) {
      this.ngControl.reset(0);
    }

    let maxDigits;
    const minDigits = this.minFractionDigits !== undefined ? this.minFractionDigits : 0;

    if (this.maxFractionDigits) {
      maxDigits = this.maxFractionDigits;
    } else {
      maxDigits = minDigits > 0 ? minDigits: 0;
    }

    const formattedValue = formatNumber(
      parseFloat(this.ngControl.control?.value),
      this.formatLocale,
      '1.' + minDigits + '-' + maxDigits
    );

    this.ngControl.control?.setValue(
      formattedValue
      .toString()
      .replace(/,/g, "")
    );
  }
}
