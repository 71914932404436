import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ArticleService } from '../article.services';
import { ArticleData, DataSavedEvent } from '../article.model';
import { CardWithTabs } from '../../shared/mixin/card-with-tabs';

@Component({
  selector: 'app-manage-article',
  templateUrl: './manage-article.component.html',
  styleUrls: ['./manage-article.component.scss']
})
export class ManageArticleComponent extends CardWithTabs() implements OnInit {

  public articleId!: string;
  public articleData!: ArticleData;
  public currentTab: string = 'details';
  public isLoading: boolean = true;

  @ViewChild('cardContent') cardContent!: ElementRef<HTMLInputElement>;

  constructor(
    public articleService: ArticleService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.articleId = this.route.snapshot.paramMap.get('id')!;

    // Used for reloading organization data when changing the
    // ID url segment. Also loads initial organization data.
    this.route.params.subscribe(params => {
      this.loadArticleData(params.id);
    });
  }

  public onDataSaved(event: DataSavedEvent) {
    this.loadArticleData(event.articleId);
  }

  private loadArticleData(articleId: string) {
    this.isLoading = true;

    this.articleService
      .find(articleId)
      .pipe(
        finalize(() => this.isLoading = false)
      ).
      subscribe(data => {
        this.articleData = data;
      });
  }
}
